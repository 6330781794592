import { CardMedia, Grid, IconButton, Stack, Theme, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackIcon_Mobile } from '../../assets/back-icon.svg';
import { ReactComponent as BackIcon } from '../../assets/back-icon-large.svg';
import AdditionalPurchase from '../../assets/transactions-assets/additional-purchase.svg';
import eNach from '../../assets/transactions-assets/e-nach.svg';
import NewPurchase from '../../assets/transactions-assets/new-purchase.svg';
import NCT from '../../assets/transactions-assets/non-commercial-transaction.svg';
import pause_Cancellation from '../../assets/transactions-assets/pause-cancellation.svg';
import Redemption from '../../assets/transactions-assets/redemption.svg';
import STP from '../../assets/transactions-assets/stp.svg';
import Switch from '../../assets/transactions-assets/switch.svg';
import SWP from '../../assets/transactions-assets/swp.svg';
import { Investorcard } from '../auth/login/styles/styledComponents';
import PanDetailsBox from '../common/PanDetails';
import { TransactionResponse } from '../features/transactions/transactionSlice';

interface CardData {
  name: string;
  image: string;
  routerPath: string;
  showBasedOnFolioCount?: boolean;
}

const cardNameMappings: Record<string, string> = {
  SWP: 'SWP (Systematic Withdrawal Plan)',
  STP: 'STP (Systematic Transfer Plan)',
};

const cardData: CardData[] = [
  {
    name: 'New Purchase',
    image: NewPurchase,
    routerPath: 'new-purchase',
    showBasedOnFolioCount: true,
  },
  {
    name: 'Additional Purchase',
    image: AdditionalPurchase,
    routerPath: 'additional-purchase',
  },
  { name: 'Switch', image: Switch, routerPath: 'switch' },
  { name: 'SWP', image: SWP, routerPath: 'swp' },
  { name: 'STP', image: STP, routerPath: 'stp' },
  { name: 'Redemption', image: Redemption, routerPath: 'redemption' },
  { name: 'eNach', image: eNach, routerPath: 'enach', showBasedOnFolioCount: true },
  { name: 'Pause/Cancellation', image: pause_Cancellation, routerPath: 'pause-cancel' },
  {
    name: 'Non-Commercial Transaction',
    image: NCT,
    routerPath: 'non-commercial-transaction',
  },
];

export const Transact = () => {
  const { MFS, NPS, pan, name, foliosCount } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  const navigate = useNavigate();

  const showCts = foliosCount > 0;

  // Update card visibility with default value
  const updatedCardData = cardData.map(card => ({
    ...card,
    showBasedOnFolioCount: card.showBasedOnFolioCount ?? showCts,
  }));

  const getMappedName = (originalName: string): string =>
    cardNameMappings[originalName] || originalName;

  const handleBackButton = () => {
    navigate(MFS && NPS ? '../asset-selection' : '../');
  };

  const renderCard = ({ name, image, routerPath }: CardData, index: number) => (
    <Grid
      key={index}
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
    >
      <Investorcard
        sx={theme => ({
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          columnGap: '20px',
          padding: '18px 20px',
          maxWidth: '280px',
          [theme.breakpoints.up(1920)]: {
            minWidth: '-webkit-fill-available',
          },
          [theme.breakpoints.down('md')]: {
            padding: '15px 20px',
            width: '100%',
            maxWidth: 'unset',
          },
        })}
        onClick={() => navigate(`../${routerPath}`)}
      >
        <CardMedia
          sx={{
            width: { xs: '40px', sm: '70px' },
            height: { xs: '40px', sm: '70px' },
          }}
          component='img'
          image={image}
          alt={name}
        />
        <Typography sx={{ fontSize: { xs: '14px', xl: '16px' }, fontWeight: 500 }}>
          {getMappedName(name)}
        </Typography>
      </Investorcard>
    </Grid>
  );

  return (
    <>
      <PanDetailsBox
        name={name}
        value={pan}
      />
      <Stack
        sx={(theme: Theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          margin: { xs: '30px 0px 10px 0px', sm: '30px 0px 8px 0px' },
          [theme.breakpoints.up(900)]: {
            margin: '0px 0px 20px 0px',
          },
        })}
      >
        <IconButton
          sx={{
            display: { xs: 'none', sm: 'inline-flex' },
          }}
        >
          <BackIcon onClick={handleBackButton} />
        </IconButton>
        <IconButton
          sx={{
            display: { xs: 'inline-flex', sm: 'none' },
          }}
        >
          <BackIcon_Mobile onClick={handleBackButton} />
        </IconButton>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px', lg: '24px', xl: '26px' },
            fontWeight: 500,
            ml: { xs: '10px', md: '0' },
          }}
        >
          Transact
        </Typography>
      </Stack>
      <Grid
        container
        spacing={3}
      >
        {updatedCardData
          .filter(({ showBasedOnFolioCount }) => showBasedOnFolioCount)
          .map(renderCard)}
      </Grid>
    </>
  );
};
