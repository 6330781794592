interface ListItem {
  [key: string]: any;
}

interface Option {
  value: any;
  label: string;
}

export const _getOptions = (
  list: ListItem[],
  valueKey: string,
  label: string = 'Name',
): Option[] => {
  try {
    return list.map(item => ({
      value: item[valueKey],
      label: item[label],
    }));
  } catch (error) {
    return [];
  }
};

export const removeBlankValueKey = (obj: { [x: string]: any }) =>
  Object.keys(obj).reduce((pre: { [x: string]: any }, k) => {
    if (obj[k]) {
      pre[k] = obj[k];
    }
    return pre;
  }, {});

export const dateToDDMMYYY = (date: Date | null | undefined): string => {
  // Use today's date if null or undefined
  const validDate = date || new Date();
  const day = String(validDate.getDate()).padStart(2, '0');
  const month = String(validDate.getMonth() + 1).padStart(2, '0');
  const year = validDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const dateToDDMMYYYByDash = (date: Date | null | undefined): string => {
  // Use today's date if null or undefined
  const validDate = date || new Date();
  const day = String(validDate.getDate()).padStart(2, '0');
  const month = String(validDate.getMonth() + 1).padStart(2, '0');
  const year = validDate.getFullYear();
  return `${day}-${month}-${year}`;
};

export const replaceDashToSlash = (str: string, search: string, replacement: string) => {
  return str.split(search).join(replacement);
};

export function formatDate(dobb: string) {
  var dateParts = dobb.split('/');
  return dateParts[1] + '/' + dateParts[0] + '/' + dateParts[2];
}

export function base64SizeInBytes(base64: string) {
  const decodedData = atob(base64);
  return Math.floor(decodedData.length / 1024);
}

const CONFIRMATION_URL =
  'https://clientwebsitesuat2.kfintech.com/dit-nps-transaction-confirmation/summary';

export const generateUrl = (obj: any) => {
  const queryParams = new URLSearchParams();
  Object.keys(obj).forEach(key => {
    queryParams.set(key, encodeURIComponent(btoa(obj[key])));
  });
  return `${CONFIRMATION_URL}?${queryParams.toString()}`;
};
