import { Box, Button, Dialog, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import InfoIcon from '../../../../../assets/info-icon.svg';
import {
  StepperCancelButton,
  StepperNextButton,
  TransactionHeader,
} from '../../../../auth/login/styles/styledComponents';
import { useRootContext } from '../../../../data/root.context';
import StyledRadioButtonGroup from '../../../../forms/FormStyledRadioButton';
import { SUCCESS_RESPONSE_CODE } from '..';
import {
  setPOPbankDetails,
  setSkipKyc,
  setStepNumber,
} from '../context/NewSubscriberAction';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';
import { useGetPOPbankNamesQuery, useRedirectToDigilockerQuery } from '../slice';
import { AadhaarContainer } from './AadharContainer';
export const KycDetails = () => {
  const {
    state: { kycDetails, aadhaarDetails, ResidenceType },
    dispatch: dispatchContext,
  } = useNewSubscriberContext();

  const { showToast } = useRootContext();
  const contextData = useNewSubscriberContext();
  const navigate = useNavigate();

  const [kycType, setkycType] = useState(aadhaarDetails ? '150' : '');
  const [skipDialogDisplay, setSkipDialogDisplay] = useState(false);

  const { refetch: reDirectToDigiLocker } = useRedirectToDigilockerQuery('');
  const { refetch: getPOPbankNames } = useGetPOPbankNamesQuery('');
  const showData = !!aadhaarDetails;

  const optedKycType = kycDetails?.data?.IdentifierType;

  const gotoDigiLocker = async () => {
    localStorage.setItem('newSub', JSON.stringify(contextData));
    const res = await reDirectToDigiLocker();
    const { data = {} } = res;
    const { redirectUrl = '' } = data.data;
    !!redirectUrl && (window.location.href = redirectUrl);
  };

  const changeKycType = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(value) === 150) {
      gotoDigiLocker();
      return;
    }
    setkycType(value);
  };

  useEffect(() => {
    if (Boolean(optedKycType) && optedKycType !== '0') {
      dispatchContext(setStepNumber(3));
      return;
    }

    const fetchKycOptions = async () => {
      try {
        const responseBank = await getPOPbankNames().unwrap();
        if (+responseBank.statusCode === SUCCESS_RESPONSE_CODE) {
          dispatchContext(setPOPbankDetails(responseBank));
        }
      } catch (error) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    };
    fetchKycOptions();
    if (Number(ResidenceType) !== 1) {
      setkycType('');
    }
  }, [dispatchContext, ResidenceType]);

  const Skip = () =>
    Number(kycType) !== 150 ? (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Button
          sx={{
            fontSize: '14px',
            color: 'primary.main',
            fontWeight: 500,
          }}
        >
          Register for KYC with
        </Button>
        <Button
          sx={{
            fontSize: '14px',
            color: 'primary.main',
            fontWeight: 500,
            textDecoration: 'underline',
          }}
          onClick={() => setSkipDialogDisplay(true)}
        >
          Skip KYC Verification
        </Button>
      </Box>
    ) : null;

  const Proceed = () => {
    dispatchContext(setSkipKyc(!!skipDialogDisplay));
    const { pathname } = location;
    //removing the part of  url which contains code from digilocker
    const navigateTo = pathname.split('?')[0];
    navigate(navigateTo);
    dispatchContext(setStepNumber(1));
  };

  return (
    <>
      {
        <Dialog
          maxWidth='sm'
          fullWidth
          open={skipDialogDisplay}
          sx={{
            '& .MuiPaper-root': {
              padding: '24px',
              borderRadius: '15px',
            },
          }}
        >
          <TransactionHeader sx={{ mb: 1 }}>Skip KYC Verification</TransactionHeader>
          <Typography sx={{ mb: 2 }}>
            KYC details will be requested by the investor.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', my: 1.5 }}>
            <StepperCancelButton
              sx={{ minWidth: '160px' }}
              onClick={() => setSkipDialogDisplay(false)}
            >
              Cancel
            </StepperCancelButton>
            <StepperNextButton
              sx={{ minWidth: '160px' }}
              onClick={Proceed}
            >
              Proceed
            </StepperNextButton>
          </Box>
        </Dialog>
      }
      <Box sx={{ padding: { xs: '0px 24px', sm: '0px 0px 0px 24px' } }}>
        {Number(ResidenceType) === 1 && Number(kycType) !== 150 && !showData ? (
          <Box
            sx={{
              borderRadius: '7px',
              backgroundColor: 'text.whiteLight',
              display: 'flex',
              alignItems: 'center',
              padding: '16px',
              gap: '10px',
              mb: 3,
            }}
          >
            <img
              src={InfoIcon}
              alt='info-icon'
            />
            <Typography
              sx={{ fontWeight: 400, fontSize: { xs: '14px', sm: '16px', xl: '18px' } }}
            >
              We couldn&apos;t fetch the existing KYC details from CKYC. Please proceed
              with the following options to continue further
            </Typography>
          </Box>
        ) : null}

        {Number(kycType) !== 150 && !showData ? <Skip /> : ''}

        {Number(kycType) !== 150 && !showData ? (
          <>
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px' },
                fontWeight: 500,
                mb: 2,
              }}
            >
              Account Type*
            </Typography>
            <StyledRadioButtonGroup
              options={[
                { label: 'Aadhar linked with mobile number', value: '150' },
                { label: 'Bank Verification', value: '119' },
              ]}
              value={kycType}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                changeKycType(event);
              }}
            />
          </>
        ) : null}
        {!!aadhaarDetails && <AadhaarContainer />}
      </Box>
      {/* Bank Account Verification */}
    </>
  );
};
