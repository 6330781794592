/* eslint-disable no-console */
import {
  Box,
  Collapse,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { StepperNextButton } from '../onboarding/styles/styledComponents';
import { RowData } from '../transactions/types';
import SearchInputBox from './SearchBox';

type CollapsibleContainerProps = {
  rows: any[];
  columnsToShow: string[];
  continueButtonHandler: (row: RowData) => void;
};

const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({
  rows,
  columnsToShow,
  continueButtonHandler,
}) => {
  const [selectedScheme, setSelectedScheme] = useState<number | undefined>(undefined);
  const [existingSchemeData, setExistingSchemeData] = useState<any[]>(rows);

  useEffect(() => {
    setExistingSchemeData(rows);
  }, [rows]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    setSelectedScheme(value);
    const mappedExistingData = rows.map((item: any) => ({
      ...item,
      isDemat: item.schemeName === value ? !item.isDemat : false,
    }));
    setExistingSchemeData(mappedExistingData);
  };

  return (
    <>
      <SearchInputBox
        data={{ option1: 'Option 1', option2: 'Option 2', option3: 'Option 3' }} // TODO - remove this while implementing logic
      />
      <RadioGroup
        value={selectedScheme}
        onChange={handleChange}
      >
        {existingSchemeData?.map((row, index) => {
          return (
            <>
              <Box
                sx={(theme: Theme) => ({
                  position: 'relative',
                  paddingLeft: row.isDemat ? '0px' : '5px',
                  p: '20px 20px',
                  borderLeft: row.isDemat
                    ? `5px solid ${theme.palette.primary.main}`
                    : 'unset',
                  backgroundColor: row.isDemat ? 'text.boxColor' : 'common.white',
                  display: { xs: 'block', md: 'none' },
                  mx: '-20px',
                  borderBottom: `0.5px solid ${theme.palette.text.borderColorLight}`,
                })}
              >
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    columnGap: '20px',
                  }}
                >
                  <Radio
                    sx={{
                      'position': 'absolute',
                      'justifyContent': 'flex-start',
                      'left': '12px',
                      'top': '22px',
                      'right': '0px',
                      'bottom': '0px',
                      'alignItems': 'baseline',
                      'padding': '0px',
                      'display': 'block',
                      'width': { xs: '55%', sm: '60%', md: '70%', lg: '80%' },
                      '& .MuiSvgIcon-root ': {
                        width: '16px',
                        height: '16px',
                      },
                    }}
                    value={row?.schemeName}
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'A' }}
                    disableFocusRipple
                    disableRipple
                  />
                </Stack>
                <Box sx={{ pl: 2.5 }}>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Stack rowGap='8px'>
                        <Typography
                          sx={{
                            color: 'text.labelColor',
                            fontSize: '12px',
                          }}
                        >
                          {row?.fundName}
                        </Typography>
                        <Typography
                          sx={{
                            color: 'text.valueColor',
                            fontSize: '14px',
                            fontWeight: 500,
                          }}
                        >
                          {row?.schemeName}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <Stack rowGap='8px'>
                        <Typography sx={{ color: 'text.labelColor' }}>
                          Folio Number
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          sx={{ color: 'text.valueColor' }}
                        >
                          {row?.folioNo}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ textAlign: 'right' }}
                    >
                      <Stack rowGap='8px'>
                        <Typography sx={{ color: 'text.labelColor' }}>
                          Available Units
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          sx={{ color: 'text.valueColor' }}
                        >
                          {row?.availableAmount}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Collapse
                    in={row?.isDemat}
                    timeout='auto'
                    unmountOnExit
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <StepperNextButton
                        sx={(theme: Theme) => ({
                          minWidth: { xs: '125px', sm: '200px' },
                          [theme.breakpoints.down('sm')]: {
                            flex: 'unset',
                            padding: '6px 20px',
                            mt: 2,
                          },
                        })}
                        onClick={() => continueButtonHandler(row)}
                      >
                        Continue
                      </StepperNextButton>
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            </>
          );
        })}
      </RadioGroup>
    </>
  );
};
//

export default CollapsibleContainer;
