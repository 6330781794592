import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { CheckBoxHtmlTooltip } from '../../../common/checkbox';
import { startLoading, stopLoading } from '../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../data/root.context';
import { TransactionResponse } from '../../../features/transactions/transactionSlice';
import StyledRadioButtonGroup from '../../../forms/FormStyledRadioButton';
import Layout from '../../Layout';
import {
  useCreateBankDetailsChangeRequestMutation,
  useCreateMultiBankRequestMutation,
} from '../../slice';
import BankForm from '../common/BankForm';
import DetailsDataTable from '../common/DetailsDataTable';
import { BankFormValues, DetailType } from '../common/types';
import UpdateBank from './UpdateBank';

const AddOrDeleteBankMandate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [requestId, setRequestId] = useState('');
  const [selectedFolios, setSelectedFolios] = useState<any>();
  const [createBankDetailsChangeRequest] = useCreateBankDetailsChangeRequestMutation();
  const [createMultiBankRequest] = useCreateMultiBankRequestMutation();
  const [selectedOption, setSelectedOption] = useState<'new' | 'delete' | 'change'>(
    'new',
  );
  const [formData, setFormData] = useState<BankFormValues>({
    accountNo: '',
    confirmAccountNo: '',
    defaultBankAccount: true,
    ifsc: '',
    name: '',
    accountType: '',
    branch: '',
    city: '',
  });

  const { pan, mobile, email } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );

  async function handleTableSubmit(data: any) {
    const bankData = data[0].banks.find((bank: any) => bank.default === 'Y');
    if (bankData) {
      setSelectedFolios(data);
      setFormData({
        accountNo: '',
        confirmAccountNo: '',
        defaultBankAccount: true,
        ifsc: '',
        name: '',
        accountType: bankData?.accountType?.toUpperCase(),
        branch: '',
        city: '',
      });
    } else {
      showToast('Default Account not present', 'error');
    }
  }

  async function handleFormSubmit(data: BankFormValues) {
    try {
      dispatch(startLoading());
      const payload = {
        requestData: {
          pan: pan,
          mobile: mobile,
          email: email,
          operationType: 'addition',
          data: selectedFolios.map((folio: any) => {
            return {
              folio: `${folio.folio}`,
              amc: folio.amcId,
              bankdetails: {
                accountNo: data.accountNo,
                ifsc: data.ifsc,
                name: data.name,
                accountType: data?.accountType?.toUpperCase(),
                branch: data.branch,
                city: data.city,
                default: 'N',
                investorName: folio.investorName,
              },
            };
          }),
        },
      };
      const response: any = await createMultiBankRequest(payload).unwrap();
      setRequestId(response.req_id);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }

  async function handleBankUpdate(selectedBanks: string[]) {
    try {
      const payload = {
        requestData: {
          pan: pan,
          mobile: mobile,
          email: email,
          // fund: 'FUND22',
          operationType: selectedOption === 'delete' ? 'deletion' : 'changeDefaultFlag',
          data: selectedFolios.map((folio: any, index: number) => {
            const selectedBank = folio.banks.find(
              (bank: any) => bank.accountNo === selectedBanks[index],
            );
            return {
              folio: `${folio.folio}`,
              amc: folio.amcId,
              bankdetails: {
                accountNo: selectedBank?.accountNo || '',
                ifsc: selectedBank?.ifsc || '',
                name: selectedBank?.name || '',
                accountType: selectedBank?.accountType?.toUpperCase() || '',
                branch: selectedBank?.branch || '',
                city: selectedBank?.city || '',
                default: selectedOption === 'change' ? 'Y' : 'N',
                investorName: folio.investorName,
              },
            };
          }),
        },
      };
      const response: any = await createMultiBankRequest(payload).unwrap();
      setRequestId(response.req_id);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  }

  function isRowDisabled(currentRow: any, selected?: any): boolean {
    if (selectedOption !== 'new') return !(currentRow.banks?.length > 1);
    if (!selected?.length || currentRow.folio === selected[0].folio) return false;
    const selectedBank = selected[0].banks.filter((bank: any) => bank.default === 'Y')[0];
    const currentBank = currentRow.banks.filter((bank: any) => bank.default === 'Y')[0];
    if (!selectedBank || !currentBank) return true;
    if (
      selectedBank.accountNo === currentBank.accountNo &&
      selectedBank.name === currentBank.name
    )
      return false;
    return true;
  }

  function getTooltip(isSelected: boolean, isDisabled: boolean) {
    if (isSelected || !isDisabled) return undefined;
    return ({ children }: { children: JSX.Element }) => {
      return (
        <CheckBoxHtmlTooltip
          title={
            <>
              <Typography color='inherit'>
                {selectedOption !== 'new'
                  ? 'Account Number and Bank Name should be same'
                  : 'There should be more than one bank account in folio'}
              </Typography>
            </>
          }
        >
          {children}
        </CheckBoxHtmlTooltip>
      );
    };
  }

  function selectOption() {
    return (
      <>
        <Typography
          sx={{
            mb: 1,
            color: 'primary.main',
            fontSize: { xs: '14px', md: '16px' },
            fontWeight: 500,
          }}
        >
          Select Option:
        </Typography>
        <Box mb={4}>
          <StyledRadioButtonGroup
            options={[
              { label: 'Add New Bank Mandate', value: 'new' },
              { label: 'Delete Bank Mandate', value: 'delete' },
              { label: 'Change Default Bank Mandate', value: 'change' },
            ]}
            value={selectedOption}
            handleChange={e =>
              setSelectedOption(e.target.value as 'new' | 'delete' | 'change')
            }
          />
        </Box>
      </>
    );
  }
  return (
    <Layout
      title={'Add or Delete Multiple Bank Mandate'}
      isNctTransaction
    >
      {!selectedFolios?.length ? (
        <DetailsDataTable
          columns={[
            'Folio Number',
            'Fund House',
            'Primary Holder',
            'Default Bank Name',
            'Account No.',
          ]}
          key={selectedOption !== 'new' ? 0 : 1}
          onSubmit={handleTableSubmit}
          detailType={DetailType.Investor}
          showEmailPhoneCombination
          isRowDisabled={isRowDisabled}
          disableSelectAll
          checkboxTooltip={getTooltip}
          extraFilters={selectOption}
        />
      ) : selectedOption !== 'new' ? (
        <UpdateBank
          folios={selectedFolios}
          setFolios={setSelectedFolios}
          type={selectedOption}
          handleUpdate={handleBankUpdate}
        />
      ) : (
        <BankForm
          initialValues={formData}
          onSubmit={handleFormSubmit}
          header='Add New Bank Account Details'
          subHeader='Please Note: Kindly enter the details carefully'
          folios={selectedFolios}
          setFolios={setSelectedFolios}
          bankFormUseFor='Add'
        />
      )}
      {!!requestId && (
        <CustomDialog
          isVisible={true}
          showFirstButton={false}
          firstButtonTitle=''
          firstButtonHandler={() => {}}
          secondButtonHandler={() => navigate('../')}
          secondButtonTitle='OK'
          handleClose={() => {}}
          showSecondButton
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& .MuiTypography-root': {
                'my': 2,
                '& >*': {
                  fontWeight: '600',
                },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography>
              Your request for Change of Bank Account Details is created successfully with
              request id <Typography component={'span'}>{requestId}</Typography>
            </Typography>

            <Typography>
              A Link has been sent to the Investor Email ID. After investor authorises the
              transaction, details will be updated after T+1 day.
            </Typography>
          </Box>
        </CustomDialog>
      )}
    </Layout>
  );
};

export default AddOrDeleteBankMandate;
