import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { generateAgentReferenceNo } from '../../../utils';
import { PageNavigator } from '../../common/BackToHomePage';
import DataTable from '../../common/DataTable';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { useGetAgentDetailsMutation } from '../slice';
import { AgentDetails } from '../types';
import ReportsFilter from './ReportsFilter';
import {
  useNpsCompletedSubscribersMutation,
  useNpsPendingSubscribersMutation,
} from './slice';
const REPORTS_ENDPOINTS = {
  PENDING: '/NPSMFDAgentPendingSubscribers',
  COMPLETED: '/NPSMFDAgentCompletedSubscribers',
  CONTRIBUTIONS: '/NPSMFDAgentContributedSubscribers',
};
type RowType = {
  'Subscriber Name': string;
  'Subscriber PAN': string;
  'Mobile Number': string;
  'EMail ID': string;
  'POP Name': string;
  'Workflownumber': string;
  'Agent ReferenceNumber': string;
  'Pending At': string;
};
interface TableDataState {
  rows: RowType[];
  cols: string[];
}
const MisReports = () => {
  const navigate = useNavigate();

  const {
    state: { type, heading },
  } = useLocation();
  const isPendingType = type === 'PENDING';
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
  );
  // const [getAllReportsData] = useGetAllReportsMutation();
  const [getOnlyPendingSubscribers] = useNpsPendingSubscribersMutation();
  const [getOnlyCompleteSubscribers] = useNpsCompletedSubscribersMutation();
  const [getAgentDetails] = useGetAgentDetailsMutation();
  const [loading, setLoading] = React.useState<boolean>(true);

  const [agentDetails, setAgentDetails] = useState<AgentDetails | null>(null);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [tableData, setTableData] = useState<TableDataState>({ rows: [], cols: [] });
  const initialTableData = useRef([]);
  const { showToast } = useRootContext();
  const [searchText, setSearchText] = useState('');
  const [allPendingAndCompletedData, setAllPendingAndCompletedData] = useState({
    pendingSubscribersData: [],
    completedSubscribersData: [],
    filteredPendingSubscribers: [],
    filteredCompletedSubscribers: [],
  });

  const { arn, distributorPan } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const dispatch = useDispatch();
  const [currentLink, setCurrentLink] = useState('');
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const getRows = () => {
    return (
      (isPendingType
        ? allPendingAndCompletedData?.filteredPendingSubscribers
        : allPendingAndCompletedData?.filteredCompletedSubscribers
      )?.map(({ SubscriberName, PAN, PhoneNumber, EmailID, AckNumber }) => ({
        'Name': SubscriberName,
        'PAN': PAN,
        'Mobile Number': PhoneNumber,
        'Email': EmailID,
        'AckNumber': AckNumber,
        // 'Pending At':
      })) || []
    );
  };
  const fetchAgentDetails = async (agentRefNumber: string) => {
    const response: any = await getAgentDetails({
      agentRefNumber,
    }).unwrap();
    setAgentDetails(response.data);
  };
  const fetchPendingSubscriber = async (agentRefNumber: string) => {
    const payload = {
      AgentReferenceNumber: agentRefNumber,
      POP_Code: agentDetails?.POPCode,
      POP_SP_Code: agentDetails?.POP_SPCode,
    };
    const response = await getOnlyPendingSubscribers(payload).unwrap();
    return response?.data;
  };

  const fetchCompletedSubscriber = async (agentRefNumber: string) => {
    const payload = {
      AgentReferenceNumber: agentRefNumber,
      POP_Code: agentDetails?.POPCode,
      POP_SP_Code: agentDetails?.POP_SPCode,
    };
    const response = await getOnlyCompleteSubscribers(payload).unwrap();
    return response?.data;
  };
  const createCsvLink = (data: any[]) => {
    if (!data || data.length === 0) return;

    const csv = [
      Object.keys(data[0]).join(','), // Header row
      ...data.map((row: RowType) =>
        Object.values(row)
          .map(value => JSON.stringify(value))
          .join(','),
      ),
    ].join('\r\n');

    const blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8' });
    return window.URL.createObjectURL(blob); // Return the URL for the CSV
  };
  const fetchSubscriberData = async (agentRefNumber: string) => {
    const fetchSubscribersList = isPendingType
      ? fetchPendingSubscriber
      : fetchCompletedSubscriber;

    return await fetchSubscribersList(agentRefNumber);
  };
  const fetchData = async () => {
    const agentRefNumber = generateAgentReferenceNo(distributorPan, arn);
    try {
      setLoading(true);

      // Fetch agent details and subscribers data concurrently
      const [agentDetailsResponse, subscriberData] = await Promise.all([
        fetchAgentDetails(agentRefNumber as string),
        fetchSubscriberData(agentRefNumber as string),
      ]);
      const typeKey = isPendingType
        ? 'pendingSubscribersData'
        : 'completedSubscribersData';
      const filterKey = isPendingType
        ? 'filteredPendingSubscribers'
        : 'filteredCompletedSubscribers';
      setAllPendingAndCompletedData({
        ...allPendingAndCompletedData,
        [typeKey]: subscriberData,
        [filterKey]: subscriberData,
      });
      // If there's valid data, create the CSV link
      const csvLink = createCsvLink(subscriberData);
      if (csvLink) {
        setCurrentLink(csvLink); // Set the link once it's created
      }
    } catch (error: any) {
      const message = error?.data?.message || error?.message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const dateChangeHandler = (newDate: Date, type: string) => {
    type === 'startDate' ? setStartDate(newDate) : setEndDate(newDate);
  };
  const handleSearchTextChanged = (text: string) => {
    const lowerCaseText = text.toLowerCase();
    setSearchText(text);

    // Update filtered data based on type
    if (isPendingType) {
      const filteredPending = allPendingAndCompletedData.pendingSubscribersData.filter(
        (rowData: any) =>
          rowData.SubscriberName.toLowerCase().includes(lowerCaseText) ||
          rowData.PAN.toLowerCase().includes(lowerCaseText),
      );
      setAllPendingAndCompletedData(prev => ({
        ...prev,
        filteredPendingSubscribers: filteredPending,
      }));
    } else {
      const filteredCompleted =
        allPendingAndCompletedData.completedSubscribersData.filter(
          (rowData: any) =>
            rowData.SubscriberName.toLowerCase().includes(lowerCaseText) ||
            rowData.PAN.toLowerCase().includes(lowerCaseText),
        );
      setAllPendingAndCompletedData(prev => ({
        ...prev,
        filteredCompletedSubscribers: filteredCompleted,
      }));
    }
  };

  const handleDownloadCSV = () => {
    if (downloadLinkRef.current) {
      downloadLinkRef.current.click();
    }
  };
  return (
    <React.Fragment>
      <PageNavigator title={`${heading} Reports`} />
      <DataTable
        loading={loading}
        setLoading={setLoading}
        columns={['Name', 'PAN', 'Mobile Number', 'Email', 'AckNumber']}
        rows={getRows() ?? []}
        onSubmit={() => {}}
        disableSelectAll={true}
        hideUpdateButton={true}
        hidecheckbox={true}
        filter={
          <ReportsFilter
            startDate={startDate}
            endDate={endDate}
            searchText={searchText}
            onSearchTextChange={handleSearchTextChanged}
            handleDateChange={dateChangeHandler}
            currentLink={currentLink}
            downloadLinkRef={downloadLinkRef}
            handleDownloadCSV={handleDownloadCSV}
            fileName={`${type}_SUBSCRIBERS`}
            isButtonDisabled={
              !(isPendingType
                ? allPendingAndCompletedData?.pendingSubscribersData.length > 0
                : allPendingAndCompletedData?.completedSubscribersData.length > 0)
            }
          />
        }
      />
    </React.Fragment>
  );
};
export default MisReports;
