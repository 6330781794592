import { Grid, Stack, Theme, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Investorcard } from '../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../common/BackToHomePage';
import { StepperNextButton } from '../../onboarding/styles/styledComponents';
import { useLazyCreateNFOQuery } from '../slice';

export interface NFOItem {
  fundName: string;
  scheme: string;
  schemeDesc: string;
  planDesc: string;
  plan: string;
  option: string;
  optionDesc: string;
  openDate: string;
  closeDate: string;
}

interface NFOData {
  [key: string]: NFOItem[];
}

export interface NFOCardProps {
  data: NFOItem[];
  amcCode: string;
}

const NFOCard: FC<NFOCardProps> = ({ amcCode, data }) => {
  const navigate = useNavigate();
  return (
    <Investorcard
      sx={{
        padding: '30px 30px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        sx={{
          mb: 2,
          fontWeight: 500,
          color: 'primary.main',
          fontSize: { xs: '16px', lg: '18px', xl: '20px' },
        }}
      >
        {data[0].fundName}
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ height: '100%', alignItems: 'stretch' }}
      >
        <Grid
          item
          xs={12}
        >
          <Stack>
            <Typography
              sx={{
                color: 'text.labelColor',
                mb: 0.75,
                fontWeight: 500,
              }}
            >
              Scheme
            </Typography>
            <Typography sx={{ fontWeight: 500 }}>{data[0].schemeDesc}</Typography>
          </Stack>
        </Grid>

        <Grid
          item
          xs={6}
        >
          <Stack>
            <Typography
              variant='subtitle2'
              sx={{ color: 'text.labelColor', mb: 0.75 }}
            >
              Opening Date
            </Typography>
            <Typography variant='subtitle2'>
              {format(new Date(data[0].openDate), 'dd/MM/yyyy') || data[0].openDate}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Stack>
            <Typography
              variant='subtitle2'
              sx={{ color: 'text.labelColor', mb: 0.75 }}
            >
              Closing Date
            </Typography>
            <Typography variant='subtitle2'>
              {format(new Date(data[0].closeDate), 'dd/MM/yyyy') || data[0].closeDate}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <StepperNextButton
        fullWidth
        sx={{ mt: 4, minWidth: '100%' }}
        onClick={() =>
          navigate('transact', {
            state: { schemeList: data, amcCode: amcCode, fundName: data[0].fundName },
          })
        }
      >
        Link
      </StepperNextButton>
    </Investorcard>
  );
};

const NFO = () => {
  const navigate = useNavigate();
  const [getNFODetails, NFODetailsResponse] = useLazyCreateNFOQuery();
  const [NFODetails, setNFODetails] = useState<NFOData>({});

  useEffect(() => {
    if (
      NFODetailsResponse &&
      NFODetailsResponse.isSuccess &&
      !NFODetailsResponse.isFetching
    ) {
      const NFODetailsgroupedData =
        NFODetailsResponse.data &&
        NFODetailsResponse.data.reduce((acc: any, item: any) => {
          if (!acc[item.fund]) {
            acc[item.fund] = [];
          }
          acc[item.fund].push(item);
          return acc;
        }, {});
      setNFODetails(NFODetailsgroupedData);
    }
  }, [NFODetailsResponse]);

  useEffect(() => {
    getNFODetails('');
  }, []);

  const handleBackClick = () => {
    navigate('/transaction-dashboard/dashboard');
  };

  return (
    <>
      <PageNavigator
        title='Create NFO'
        backHandler={handleBackClick}
        sx={(theme: Theme) => ({
          [theme.breakpoints.down('md')]: {
            mt: 0,
          },
        })}
      />
      <Grid
        container
        spacing={2}
      >
        {Object.entries(NFODetails).map((NFO, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            key={index}
          >
            <NFOCard
              amcCode={NFO[0]}
              data={NFO[1]}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default NFO;
