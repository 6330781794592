import { amcWithLogos } from '../../assets/mfcentral-logos/amcLogos';
import { calculateDifference, capitalizeFirstLetter } from '../../utils';

export const handleSameAsApplicantAddressChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any) => void,
  index: number,
  personalDetails: any,
) => {
  setFieldValue(`nominees.${index}.isSameAsApplicant`, e.target.checked);
  if (e.target.checked) {
    setFieldValue(
      `nominees.${index}.address`,
      `${personalDetails?.add1} ${personalDetails?.add2}`,
    );
    setFieldValue(`nominees.${index}.city`, personalDetails?.city);
    setFieldValue(`nominees.${index}.state`, personalDetails?.state);
    setFieldValue(`nominees.${index}.pinCode`, personalDetails?.pinCode);
    setFieldValue(
      `nominees.${index}.country`,
      capitalizeFirstLetter(personalDetails?.country),
    );
  } else {
    setFieldValue(`nominees.${index}.address`, '');
    setFieldValue(`nominees.${index}.city`, '');
    setFieldValue(`nominees.${index}.state`, '');
    setFieldValue(`nominees.${index}.pinCode`, '');
    setFieldValue(`nominees.${index}.country`, '');
  }
};

// Function to format the number according to the Indian numbering system
export function formatToINR(amount: number): string {
  // Convert to a string and split into whole and decimal parts
  const [whole, decimal] = amount.toFixed(2).split('.');

  // Format the whole part with commas according to Indian numbering
  const lastThreeDigits = whole.slice(-3);
  const otherDigits = whole.slice(0, -3);
  const formattedWhole =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
    (otherDigits ? ',' : '') +
    lastThreeDigits;

  // Return the combined formatted string
  return `${formattedWhole}.${decimal}`;
}

export const getPerpetualSipEndDate = (mode: string, startDate: Date) => {
  if (mode === 'ISIP') {
    const startDay = new Date(startDate).getDate();
    return new Date(2099, 11, startDay);
  }

  const date = new Date(startDate);

  date.setDate(date.getDate() - 1);
  date.setFullYear(date.getFullYear() + 40);
  return date;
};

export const handlePerpetualSipBoxChnage = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any) => void,
  values: any,
  selectedFrequencyAndSip: any,
) => {
  setFieldValue('perpetual', e.target.checked);

  if (e.target.checked) {
    const end_date = getPerpetualSipEndDate(values.modeOfRegistration, values.startDate);
    const selectedInstallments = calculateDifference(
      values.startDate,
      end_date,
      values.frequency,
    );
    setFieldValue('endDate', end_date);
    setFieldValue('numberOfInstalments', Math.round(selectedInstallments as any));
  } else {
    setFieldValue('frequency', '');
    setFieldValue('sipDay', '');
    setFieldValue('numberOfInstalments', '');
    setFieldValue('startDate', null);
    setFieldValue('endDate', null);
  }
};

export const getEarliestAndLatestSipDates = (cartItems: any[]) => {
  const startDates = cartItems?.map(sip => {
    const payload = JSON.parse(sip.payload);
    return new Date(payload.sipStartDate).getTime();
  });
  const endDates = cartItems?.map(sip => {
    const payload = JSON.parse(sip.payload);
    return new Date(payload.sipEndDate).getTime();
  });
  const earliestDate = startDates && new Date(Math.min(...startDates));
  const latestDate = endDates && new Date(Math.max(...endDates));

  return { earliestDate, latestDate };
};

export const maxInstallmentBasedOnFrequency = (frequency: any, mode: any = 'ISIP') => {
  // Setting ISIP as default when no param given
  const today = new Date();
  const isipLimitDate = new Date('2099-12-31');
  const nonIsipLimitDate = new Date();
  nonIsipLimitDate.setFullYear(today.getFullYear() + 40);

  const limitDate = mode === 'ISIP' ? isipLimitDate : nonIsipLimitDate;

  const calculateDaysDifference = (date1: any, date2: any) =>
    Math.round((date2 - date1) / (24 * 60 * 60 * 1000));

  const calculateMonthsDifference = (date1: any, date2: any) =>
    (date2.getFullYear() - date1.getFullYear()) * 12 +
    date2.getMonth() -
    date1.getMonth();

  const calculateYearsDifference = (date1: any, date2: any) =>
    date2.getFullYear() - date1.getFullYear();

  switch (frequency) {
    case 'Daily':
      return calculateDaysDifference(today, limitDate);
    case 'Monthly':
      return calculateMonthsDifference(today, limitDate);
    case 'Annually':
      return calculateYearsDifference(today, limitDate);
    case 'Halfyearly':
      return calculateMonthsDifference(today, limitDate) / 6;
    case 'Yearly':
      return calculateYearsDifference(today, limitDate);
    case 'Fortnightly':
      return calculateDaysDifference(today, limitDate) / 14;
    case 'Weekly':
      return calculateDaysDifference(today, limitDate) / 7;
    case 'Quarterly':
      return calculateMonthsDifference(today, limitDate) / 3;
    default:
      return 0;
  }
};

export const findLogo = (fundCode: string | number) => {
  return amcWithLogos.find(logo => {
    if (fundCode === 'RMF') {
      return logo.amc_code === 'RMF';
    }
    return Number(logo.amc_code) === Number(fundCode);
  });
};

export const findLogoWithAmcName = (fundName: any) => {
  if (!fundName || typeof fundName !== 'string') {
    return null;
  }
  return amcWithLogos.find(logo => {
    return logo.amc_name?.toLowerCase()?.trim() === fundName?.toLowerCase()?.trim();
  });
};

export const maskEmail = (email: any) => {
  if (!email.includes('@')) return email;

  const [localPart, domain] = email.split('@');
  let maskedLocalPart;
  if (localPart.length <= 2) {
    maskedLocalPart = localPart[0] + (localPart[1] ? '*' : '');
  } else {
    maskedLocalPart =
      localPart[0] + 'x'.repeat(localPart.length - 2) + localPart.slice(-1);
  }
  if (!domain) {
    return maskedLocalPart + '@';
  }

  return `${maskedLocalPart}@${domain}`;
};

export const maskAccountNumber = (acc: string) => {
  if (acc?.length <= 4) {
    return acc;
  }
  const maskedSection = acc?.slice(0, -4).replace(/./g, 'x');
  const lastFourDigits = acc?.slice(-4);
  return maskedSection + lastFourDigits;
};

export const getRelationShip: { [key: string]: string } = {
  SE: 'Self',
  SP: 'Spouse',
  DC: 'Dependent Children',
  DS: 'Dependent Siblings',
  DP: 'Dependent Parents',
  GD: 'Guardian',
  PM: 'PMS',
  CD: 'Custodian',
  PO: 'POA',
};

export function inrCurrencyFormatter(fieldValue: string): string {
  if (!fieldValue) return '';

  const formatter = new Intl.NumberFormat('en-IN', {
    currency: 'INR',
    minimumFractionDigits: 0,
  });

  return formatter.format(parseInt(fieldValue));
}

//No Need to hardcode currency symbol
export function inrCurrencyFormatter2(fieldValue: string): string {
  if (!fieldValue) return '';
  const numValue = parseFloat(fieldValue);
  if (isNaN(numValue)) return '';
  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(numValue);
}
