import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import {
  filterMandatesForLumpsum,
  filterPaymentModesForLumpsum,
  filterRegistrationMethodsSIP,
  removeOtherOTMOptions,
  removeOTMFromPaymentModes,
} from '../additionalPurchase/common';
import {
  AllMandateData,
  CheckoutPaymentFormValues,
  Mandate,
  PaymentOption,
} from '../additionalPurchase/types';
import { FIELD_NAMES } from '../additionalPurchase/types';
import {
  useGetInvestorMandatesMutation,
  useGetPaymentModesByFundMutation,
} from '../slice';
import { AlpModeType, AplTrtType } from '../types';
import { AlpPaymentModesPayload } from './types';
interface PaymentModesFormProps {
  aplPaymentsModePayload: AlpPaymentModesPayload;
  formikRef?: React.RefObject<FormikProps<CheckoutPaymentFormValues>> | null;
  selectedMandate: Mandate;
  setSelectedMandate: React.Dispatch<React.SetStateAction<Mandate>>;
}

const getValidationSchema = (
  currentTrtType: string,
  allMandateData: { umrnOptions: any[] },
) => {
  const currentModeType = AlpModeType[currentTrtType as keyof typeof AlpModeType];

  return Yup.object().shape({
    paymentMode:
      currentModeType === 'SIP'
        ? Yup.string().nullable()
        : Yup.string().required('Mode of Payment is required'),
    modeOfRegistration: Yup.string().when('currentTrtType', {
      is: (currentTrtType: string) => ['ISIP', 'SIN'].includes(currentTrtType),
      then: Yup.string().required('Mode of Registration is required'),
      otherwise: Yup.string().nullable(),
    }),
    umrn: Yup.string().when(['modeOfRegistration'], {
      is: (modeOfRegistration: string) => {
        const isOtm = modeOfRegistration?.includes('OTM');
        if (isOtm && currentModeType === 'SIP') {
          return true;
        }

        if (!isOtm && currentModeType === 'SIP') {
          // for ISIP and other modes
          return false;
        }

        if (isOtm && currentModeType === 'LumpsumAndSIP') {
          return true;
        }

        return false;
      },
      then: Yup.string().test('umrn-required-check', 'UMRN is required', value => {
        return allMandateData.umrnOptions.length === 0 || !!value;
      }),
      otherwise: Yup.string().nullable(),
    }),

    lumpsumUmrn: Yup.string().when('paymentMode', {
      is: (paymentMode: string) => {
        return (
          paymentMode?.includes('OTM') &&
          AlpModeType[currentTrtType as keyof typeof AlpModeType] === 'Lumpsum'
        );
      },
      then: Yup.string().test(
        'lumpsum-umrn-required-check',
        'UMRN is required for Lumpsum',
        value => {
          // Check if `umrnOptions` has values; if so, `lumpsumUmrn` must be provided.
          return allMandateData.umrnOptions.length === 0 || !!value;
        },
      ),
      otherwise: Yup.string().nullable(),
    }),
  });
};
type Option = {
  label: string;
  value: string;
};
// ['paymentMode', 'umrn', 'modeOfRegistration', 'lumpsumUmrn'];
const PaymentModesForm = ({
  aplPaymentsModePayload,
  formikRef = null,
  selectedMandate,
  setSelectedMandate,
}: PaymentModesFormProps) => {
  const [allPaymentOptions, setAllPaymentOptions] = useState<PaymentOption[]>([]);
  const [registrationOptions, setRegistrationOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [getPaymentModesByFund] = useGetPaymentModesByFundMutation(); // Payment modes
  const { showToast } = useRootContext();
  const dispatch = useDispatch();

  const [allMandateData, setAllMandateData] = useState<AllMandateData>({
    kotmData: [],
    umrnOptions: [],
    aotmData: [],
  });
  const [getInvestorMandates] = useGetInvestorMandatesMutation();
  const currentTrtType = aplPaymentsModePayload.trtype;
  const { folio, fund, pan, amount } = aplPaymentsModePayload;
  const isFirstRender = useRef<boolean>(false);
  const initialPaymentOptionsRef = useRef<Option[]>([]);
  const selectedModeOfRegistration = initialPaymentOptionsRef.current?.filter(
    method =>
      aplPaymentsModePayload?.modeOfRegistration?.toLowerCase() ===
      method.value.toLowerCase(),
  );

  const initialValues: CheckoutPaymentFormValues = {
    paymentMode: '', // Selected Payment Option.
    umrn: '', // Selected Mandate.
    modeOfRegistration: selectedModeOfRegistration?.[0]?.value || '',
    selectedMandateObj: {
      label: '',
      value: '',
      mandateLimit: 0,
      startDate: '',
      endDate: '',
      bankAccountNumber: '',
      bankName: '',
    },
    lumpsumUmrn: '',
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(startLoading());
        const { trtype } = aplPaymentsModePayload;
        const paymentModesResponse = await getPaymentModesByFund({
          fund: aplPaymentsModePayload.fund,
        }).unwrap();
        let initialPaymentOptions = Object.entries(paymentModesResponse)
          .filter(([_, value]) => value === 'Y')
          .map(([key, _]) => ({ label: key.toUpperCase(), value: key.toUpperCase() }));
        initialPaymentOptionsRef.current = initialPaymentOptions; //saving the initial response

        let paymentOptions,
          registrationModes = initialPaymentOptions;
        // // Filterout all mandates and payment, registration modes according to trtype.
        if (trtype === 'APL' || trtype === 'SIN') {
          // For lumpsum , and SIP+Lumpsum
          paymentOptions = filterPaymentModesForLumpsum(initialPaymentOptions);
        }
        // only to filter out registration modes.
        if (trtype === 'ISIP' || trtype === 'SIN') {
          registrationModes = filterRegistrationMethodsSIP(
            initialPaymentOptions,
            aplPaymentsModePayload?.isPanBasedAOTM as boolean,
            aplPaymentsModePayload?.modeOfRegistration,
            aplPaymentsModePayload?.isNriInvestor as boolean,
          );
        }

        setRegistrationOptions(registrationModes); // Filtered Registration Modes
        setAllPaymentOptions(paymentOptions); // Fetched payment modes
        isFirstRender.current = true;
      } catch (error: any) {
        showToast(error.data?.message ?? 'Unknown error', 'error');
      } finally {
        dispatch(stopLoading());
      }
    };
    if (aplPaymentsModePayload.fund !== '' && !isFirstRender.current) {
      fetchData();
    }
    if (
      aplPaymentsModePayload?.modeOfRegistration?.includes('OTM') &&
      !isFirstRender.current
    ) {
      handlePaymentModes(aplPaymentsModePayload?.modeOfRegistration);
    }
  }, [aplPaymentsModePayload]);

  const handleMandateChange = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
    paymentMode: string,
  ) => {
    const OTMKey = paymentMode.toUpperCase() === 'KOTM' ? 'kotmData' : 'aotmData';
    const value = e.target.value;
    const selectedValue = allMandateData[OTMKey]?.find(
      (mandate: any) => mandate?.umrn === value || mandate?.umnr === value,
    );
    if (value !== 'createMandate') {
      setSelectedMandate({
        label: value,
        value: value,
        mandateLimit: selectedValue?.amount ?? 0,
        startDate: selectedValue?.fromDate || '',
        endDate: selectedValue?.toDate || '',
        bankAccountNumber: selectedValue?.bnkacno || '',
        bankName: selectedValue?.bank || '',
      });
      setFieldValue('selectedMandateObj', {
        label: value,
        value: value,
        mandateLimit: selectedValue?.amount ?? 0,
        startDate: selectedValue?.fromDate || '',
        endDate: selectedValue?.toDate || '',
        bankAccountNumber: selectedValue?.bnkacno || '',
        bankName: selectedValue?.bank || '',
      });
    } else {
      setSelectedMandate({
        label: '+ Create New Mandate',
        value: 'createMandate',
        startDate: '',
        endDate: '',
        mandateLimit: 0,
        bankAccountNumber: '',
        bankName: '',
      });
      setFieldValue('selectedMandateObj', {
        label: '+ Create New Mandate',
        value: 'createMandate',
        startDate: '',
        endDate: '',
        mandateLimit: 0,
        bankAccountNumber: '',
        bankName: '',
      });
    }
  };

  const handlePaymentModes = async (
    selectedPaymentMode: string,
    setFieldValue?: any,
    isLumpsumPaymentMode?: boolean,
  ) => {
    try {
      if (!selectedPaymentMode.includes('OTM')) {
        return;
      }
      dispatch(startLoading());
      const mandatesResponse = await getInvestorMandates({
        folio: String(folio),
        fund,
        pan,
        trtype: 'APL',
        modeOfRegistration: selectedPaymentMode.toUpperCase(),
      }).unwrap();
      const OTMKey =
        selectedPaymentMode.toUpperCase() === 'KOTM' ? 'kotmData' : 'aotmData';
      let mandatesForPaymentType = mandatesResponse[OTMKey];
      let filteredMandates = mandatesForPaymentType.filter((mandate: Mandate) =>
        filterMandatesForLumpsum(Number(amount), mandate),
      );

      const options = filteredMandates?.map((item: any) => {
        return { label: item.umrn || item.umnr, value: item.umrn || item.umnr };
      });
      if (selectedPaymentMode.toUpperCase() === 'KOTM' && !isLumpsumPaymentMode)
        options.unshift({ label: '+ Create New Mandate', value: 'createMandate' });

      setAllMandateData({
        ...allMandateData,
        [OTMKey]: mandatesResponse[OTMKey],
        umrnOptions: options,
      });
      if (options.length === 0) {
        showToast(
          'There are no mandates for your selected payment mode, please choose another payment mode',
          'error',
          5000,
        );
      }
      if (setFieldValue) {
        setFieldValue('selectedMandateObj', {
          label: '',
          value: '',
          mandateLimit: 0,
          startDate: '',
          endDate: '',
          bankAccountNumber: '',
          bankName: '',
        });
        if (isLumpsumPaymentMode) setFieldValue('lumpsumUmrn', '');
        else setFieldValue('umrn', '');
      }
    } catch (error: any) {
      setAllMandateData({
        ...allMandateData,
        [selectedPaymentMode.toUpperCase() === 'KOTM' ? 'kotmData' : 'aotmData']: [],
        umrnOptions: [],
      });
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };
  const filterPaymentModesForOTM = (values: CheckoutPaymentFormValues) => {
    if (values.modeOfRegistration === 'ISIP')
      return removeOTMFromPaymentModes(allPaymentOptions);
    else if (
      currentTrtType === 'SIN' &&
      !!values?.modeOfRegistration &&
      values?.modeOfRegistration.includes('OTM') &&
      values?.umrn === 'createMandate'
    ) {
      return removeOTMFromPaymentModes(allPaymentOptions);
    } else if (
      currentTrtType === 'SIN' &&
      !!values?.modeOfRegistration &&
      values?.modeOfRegistration.includes('OTM') &&
      values?.umrn !== 'createMandate'
    ) {
      return removeOtherOTMOptions(allPaymentOptions, values?.modeOfRegistration);
    } else return allPaymentOptions;
  };

  const resetLaterFields = (
    values: CheckoutPaymentFormValues,
    key: keyof CheckoutPaymentFormValues,
    fieldValues: Partial<CheckoutPaymentFormValues>,
  ): CheckoutPaymentFormValues => {
    const index = FIELD_NAMES.findIndex(fieldName => fieldName === key);
    const updatedValues = FIELD_NAMES.slice(index).reduce<
      Partial<CheckoutPaymentFormValues>
    >((acc, curr) => {
      return {
        ...acc,
        [curr]:
          curr === 'selectedMandateObj'
            ? {
                label: '',
                value: '',
                mandateLimit: 0,
                startDate: '',
                endDate: '',
                bankAccountNumber: '',
                bankName: '',
              }
            : '',
      };
    }, {});
    return { ...values, ...updatedValues, ...fieldValues };
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(currentTrtType, allMandateData)}
        onSubmit={() => {}}
        innerRef={formikRef}
        enableReinitialize
      >
        {({ values, setFieldValue, setValues, setTouched }) => {
          const clearTouchedFields = () => {
            setTouched({});
          };
          return (
            <Form>
              <Grid
                container
                spacing={2}
              >
                {currentTrtType !== 'APL' && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Typography
                      sx={{
                        mb: 1.5,
                        color: 'text.inputLabelText',
                        fontSize: { xs: '14px', xl: '16px' },
                        fontWeight: 500,
                      }}
                    >
                      SIP Payment Mode *
                    </Typography>
                    <FormSelectInput
                      name='modeOfRegistration'
                      label='Mode of Registration'
                      defaultValue={''}
                      options={registrationOptions}
                      onChange={async (e: SelectChangeEvent<any>) => {
                        // clearValues(setFieldValue);
                        const newValues = resetLaterFields(values, 'modeOfRegistration', {
                          modeOfRegistration: e.target.value,
                        });
                        setValues(newValues);
                        clearTouchedFields();
                        handlePaymentModes(e.target.value, setFieldValue);
                      }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    mb: 2,
                    mt: {
                      sm:
                        AplTrtType[currentTrtType as keyof typeof AplTrtType] !==
                        'Lumpsum'
                          ? 4
                          : 0,
                    },
                  }}
                >
                  {AplTrtType[currentTrtType as keyof typeof AplTrtType] !== 'Lumpsum' &&
                    values?.modeOfRegistration !== 'ISIP' &&
                    allMandateData?.umrnOptions &&
                    allMandateData?.umrnOptions?.length > 0 && (
                      <FormSelectInput
                        name='umrn'
                        label='Select Mandate'
                        required
                        options={allMandateData?.umrnOptions}
                        onChange={e =>
                          handleMandateChange(
                            e,
                            setFieldValue,
                            values?.modeOfRegistration as string,
                          )
                        }
                      />
                    )}
                </Grid>
                {currentTrtType !== 'ISIP' &&
                  Boolean(allPaymentOptions) &&
                  allPaymentOptions?.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      sx={{ mb: 1 }}
                    >
                      <Typography
                        sx={{
                          mb: 1.5,
                          color: 'text.inputLabelText',
                          fontSize: { xs: '14px', xl: '16px' },
                          fontWeight: 500,
                        }}
                      >
                        Lumpsum Payment Mode *
                      </Typography>
                      <FormStyledRadioButton
                        options={filterPaymentModesForOTM(values)}
                        name='paymentMode'
                        handleChange={async (event: SelectChangeEvent<any>) => {
                          const newValues = resetLaterFields(values, 'paymentMode', {
                            paymentMode: event.target.value,
                          });
                          setValues(newValues);
                          if (
                            AplTrtType[currentTrtType as keyof typeof AplTrtType] ==
                            'Lumpsum'
                          ) {
                            clearTouchedFields();

                            handlePaymentModes(event.target.value, setFieldValue, true);
                          }
                        }}
                      />
                    </Grid>
                  )}

                {AplTrtType[currentTrtType as keyof typeof AplTrtType] === 'Lumpsum' &&
                  values?.paymentMode.includes('OTM') && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        mb: 2,
                        mt: {
                          sm:
                            AplTrtType[currentTrtType as keyof typeof AplTrtType] !==
                            'Lumpsum'
                              ? 4
                              : 0,
                        },
                      }}
                    >
                      {allMandateData?.umrnOptions &&
                        allMandateData?.umrnOptions?.length > 0 && (
                          <FormSelectInput
                            name='lumpsumUmrn'
                            label='Select Mandate'
                            required
                            options={allMandateData?.umrnOptions}
                            onChange={e =>
                              handleMandateChange(e, setFieldValue, values?.paymentMode)
                            }
                          />
                        )}
                    </Grid>
                  )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
    // </CustomDialog>
  );
};
export default PaymentModesForm;
