import { Box, Button, Grid, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { PopUpDialog } from '../../common/AllDialogs/popup-dialog';
import { FileUpload } from '../../common/FileUpload';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { base64ToBlob } from '../../onboarding/common';
import {
  useBulkUploaddistributorsMutation,
  useLazyDownloadTemplateBulkUploadQuery,
} from '../slice';

const documentsMap: { [key: number]: string } = {
  0: 'Employee/subBroker',
};
interface BulkUploadProps {
  isBigScreen: boolean;
}
interface RowError {
  errors: string;
  rowNo: number;
}
export function BulkUpload({ isBigScreen }: BulkUploadProps) {
  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<RowError[]>([]);
  // const userId = '78b154f7-7473-4fd9-ae7b-914b1381cac2';
  const [uploaddocs] = useBulkUploaddistributorsMutation();
  const [downloadTemplate] = useLazyDownloadTemplateBulkUploadQuery();

  const { showToast } = useRootContext();
  const dispatch = useDispatch();

  const [templateLink, setTemplateLink] = useState<string | null>(null);

  const showDialog = (errorString: string) => {
    const errorsList = JSON.parse(errorString) as RowError[];
    let groupedList: RowError[] = [];
    let prevRow = 0;
    errorsList.forEach(err => {
      if (!prevRow) {
        groupedList.push(err);
      } else {
        if (err.rowNo === prevRow) {
          let top = groupedList.pop();
          if (!top) return;
          top = { ...top, errors: `${top?.errors}, ${err.errors}` };
          groupedList.push(top);
        } else {
          groupedList.push(err);
        }
      }
      prevRow = err.rowNo;
    });
    setErrors(groupedList);
  };

  const uploadFiles = async () => {
    try {
      const payload = new FormData();
      payload.append('type', documentsMap[0]);
      payload.append('file', files[0]);
      dispatch(startLoading());

      const response = await uploaddocs(payload as any);
      if ('data' in response) {
        setFiles([]);
        showToast((response.data as { message: string }).message, 'success');
      } else {
        // showToast(`Error ocurred while uploading details: ${response.error}`, 'error');
        showDialog((response.error as any).data.message);
      }
    } catch (error: any) {
      // showToast((error.data as { message: string }).message, 'error');
      showDialog((error.data as any).message);
    } finally {
      dispatch(stopLoading());
    }
  };
  useEffect(() => {
    if (files.length > 0) {
      uploadFiles();
    }
  }, [files]);
  useEffect(() => {
    if (templateLink) {
      const downloadLink = document.querySelector(
        '.download-link',
      ) as HTMLAnchorElement | null;
      if (downloadLink) {
        downloadLink.click();
      }
    }
  }, [templateLink]);

  const getTemplate = async () => {
    try {
      dispatch(startLoading());
      const { data: fileData } = await downloadTemplate({});
      if (fileData) {
        const blob = base64ToBlob(
          fileData.split(',')[1],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        );
        const blobUrl = URL.createObjectURL(blob);
        setTemplateLink(blobUrl);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };
  const handleClose = () => {
    setErrors([]);
  };
  return (
    <Box sx={{ padding: !isBigScreen ? '0px' : '0px 25px' }}>
      {errors.length !== 0 && (
        <PopUpDialog
          open={errors.length !== 0}
          message_text={''}
          message_title={'Errors encountered creating user(s) from following rows -'}
          onClose={handleClose}
        >
          <div
            style={{
              width: '500px',
              display: 'flex',
              flexDirection: 'column',
              gap: '1em',
              margin: '2em 0',
              marginBottom: '0',
              maxHeight: '400px',
              border: '1px solid gray',
              borderRadius: '13px',
              overflow: 'auto',
            }}
          >
            <Grid
              container
              direction={'row'}
              alignItems={'center'}
              sx={{ padding: '0 2em', paddingTop: '1em', color: 'gray', fontSize: 13 }}
            >
              <Grid
                lg={3}
                item
              >
                Row No
              </Grid>
              <Grid
                lg={9}
                item
              >
                Error Message
              </Grid>
            </Grid>
            {errors.map((err, i) => (
              <Grid
                container
                alignItems={'center'}
                direction={'row'}
                justifyContent={'flex-end'}
                key={i}
                sx={{
                  color: 'red',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: i % 2 === 0 ? 'whitesmoke' : 'white',
                  padding: '0.6em 2em',
                }}
              >
                <Grid
                  item
                  sx={{ display: 'flex', alignItems: 'center' }}
                  lg={3}
                >
                  {err.rowNo}
                </Grid>
                <Grid
                  item
                  alignItems={'center'}
                  lg={9}
                >
                  {err.errors}
                </Grid>
              </Grid>
            ))}
          </div>
        </PopUpDialog>
      )}
      <Box
        display='flex'
        alignItems='center'
        gap='5px'
      >
        <Typography
          variant='subtitle1'
          sx={{ my: 3 }}
        >
          Upload Employee & Sub Broker File
        </Typography>
        <Typography sx={{ color: 'text.labelColor' }}>
          ( Only CSV files are alllowed )
        </Typography>
      </Box>
      <FileUpload
        fileName={documentsMap[0]}
        index={0}
        files={files}
        setFiles={setFiles}
      />
      <Box sx={{ my: 3 }}>
        <Typography
          sx={{
            color: 'common.black',
            fontSize: { xs: '16px', xl: '18px' },
            fontWeight: 500,
            mb: 2,
          }}
        >
          To Import Data
        </Typography>
        <Typography sx={{ color: 'text.labelColor', mb: 1 }}>
          1. Download the template
        </Typography>
        <Typography sx={{ color: 'text.labelColor', mb: 1 }}>
          2. Add Data in the correct format
        </Typography>
        <Typography sx={{ color: 'text.labelColor' }}>3. Upload File</Typography>
      </Box>

      <Box
        sx={(theme: Theme) => ({
          padding: '1.5px',
          background: theme.palette.background.primaryLinearGradient4,
          borderRadius: '5px',
          position: 'relative',
          minWidth: '223px',
          maxWidth: '223px',
        })}
      >
        <Button
          sx={(theme: Theme) => ({
            'minWidth': '220px',
            'color': theme.palette.primary.main,
            'borderRadius': '5px',
            'backgroundColor': theme.palette.common.white,
            'fontSize': '14px',
            'fontWeight': '600',
            'padding': '10px 20px',
            // 'border': '1.5px solid',
            'backgroundClip': 'padding-box',
            '&:hover': {
              backgroundColor: theme.palette.common.white,
            },
            '&.Mui-disabled': {
              opacity: '0.3',
              color: theme.palette.common.white,
            },
            [theme.breakpoints.between(768, 1200)]: {
              fontSize: '13px',
            },
            [theme.breakpoints.down('md')]: {
              padding: '11px 20px',
              fontSize: '14px',
            },
          })}
          onClick={getTemplate}
        >
          Download Template
        </Button>
      </Box>

      {templateLink !== '' && (
        <Link
          to={templateLink ?? ''}
          target='_blank'
          download={'template_data'}
          className={'download-link'}
          style={{ display: 'none' }}
        />
      )}
    </Box>
  );
}
