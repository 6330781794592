import { Box, Divider, Grid, Theme, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Investorcard } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { PageNavigator } from '../../common/BackToHomePage';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { FormSelectInput } from '../../forms/FormSelect';
import SubmitButton from '../../forms/FormSubmitButton';
import FormTextInput from '../../forms/FormTextInput';
import EuinsFields from '../../transactions/euinsComponent';
import { useGetNFOLinkMutation } from '../slice';

const NFOTransact = () => {
  const { state } = useLocation();
  const { arn, subArn, subbrokerCode, euin } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const [link, setLink] = useState('');
  const { showToast } = useRootContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [generateNFOLink] = useGetNFOLinkMutation();
  const initialValues = {
    arn: arn || '',
    subArn: subArn || '',
    scheme: '',
    subBrokerCode: subbrokerCode || '',
    euin: euin || '',
  };

  const validationSchema = Yup.object().shape({
    arn: Yup.string(),
    subArn: Yup.string().test(
      'subArn',
      'Sub ARN code cannot be the same as ARN code',
      function (value) {
        const { arn } = this.parent;
        return value !== arn;
      },
    ),
    scheme: Yup.string().required('Scheme is required'),
  });

  function copyLink() {
    try {
      navigator.clipboard.writeText(link as string);
      showToast('Link copied!!', 'success');
    } catch (error: any) {
      showToast(error, 'error');
    }
  }

  async function handleSubmit(values: any, { resetForm }: FormikHelpers<any>) {
    try {
      const selectedScheme = state?.schemeList?.filter((scheme: any) => {
        if (`${scheme.scheme}_${scheme.plan}_${scheme.option}` === values.scheme) {
          return scheme;
        }
      });
      const payload = {
        scheme: selectedScheme?.[0]?.scheme,
        pln: selectedScheme?.[0]?.plan,
        opt: selectedScheme?.[0]?.option,
        fund: state.amcCode,
        schemeDesc: selectedScheme?.[0]?.schemeDesc,
        planDesc: selectedScheme?.[0]?.planDesc,
        optionDesc: selectedScheme?.[0]?.optionDesc,
        fundName: selectedScheme?.[0]?.fundName,
        subArn: values.subArn || subArn,
        subBroker: values.subBrokerCode || subbrokerCode,
        euin: values.euin,
      };
      dispatch(startLoading());
      const response = await generateNFOLink(payload).unwrap();
      setLink(response.url);
      resetForm();
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }
  return (
    <>
      <PageNavigator
        title='NFO Transact'
        sx={(theme: Theme) => ({
          [theme.breakpoints.down('md')]: {
            mt: 0,
          },
        })}
      />

      <Investorcard
        sx={(theme: Theme) => ({
          padding: { sm: '30px' },
          [theme.breakpoints.down('sm')]: {
            boxShadow: 'unset',
            border: 'unset',
          },
        })}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, setValues, errors }) => {
            return (
              <Form>
                <Box>
                  <Grid
                    container
                    spacing={2}
                    sx={{ columnGap: '40px' }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={5}
                    >
                      <FormTextInput
                        name='arn'
                        label='ARN'
                        required={false}
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                    >
                      <FormTextInput
                        name='subArn'
                        label='SUB ARN Code'
                        required={false}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                    >
                      <FormSelectInput
                        name='scheme'
                        label='Select Scheme'
                        options={state?.schemeList?.map((scheme: any) => ({
                          label: `${scheme.schemeDesc} - ${scheme.planDesc}`,
                          value: `${scheme.scheme}_${scheme.plan}_${scheme.option}`,
                        }))}
                        onChange={async (e: any) =>
                          setFieldValue('scheme', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                    >
                      <FormTextInput
                        name='subBrokerCode'
                        label='SUB Broker code'
                        required={false}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <EuinsFields
                        name='euin'
                        defaultValue={euin || ''}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  sx={{
                    border: '1px dashed',
                    borderColor: 'text.borderColorDark',
                    mt: 3,
                  }}
                />
                <SubmitButton
                  label='Submit'
                  sx={{ minWidth: { xs: '100%', sm: '200px' } }}
                />
              </Form>
            );
          }}
        </Formik>
        <CustomDialog
          isVisible={!!link}
          firstButtonHandler={copyLink}
          secondButtonHandler={() => {
            setLink('');
            navigate(-1);
          }}
          firstButtonTitle='Copy Link'
          secondButtonTitle='Close'
          handleClose={() => setLink('')}
          showSecondButton={true}
        >
          <Box sx={{ textAlign: 'center', pt: 2 }}>
            <Typography>
              {state?.fundName} NFO has been created successfully.
              <br />
              <Typography
                component='span'
                sx={{ fontWeight: 'bold' }}
              >
                Link: {link}
              </Typography>
            </Typography>
          </Box>
        </CustomDialog>
      </Investorcard>
    </>
  );
};

export default NFOTransact;
