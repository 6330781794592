import { Grid, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import CalenderIconTmage from '../../../assets/calendar-icon.svg';
import { ReactComponent as FileIcon } from '../../../assets/download-excel.svg';
import { CustomSearch } from '../../common/search';

const SearchTypo = styled(Typography)(({ theme }) => ({
  color: '#6C737F',
  fontSize: '14px',
  fontWeight: 400,
}));

interface TableFilterProps {
  handleDateChange: (newDate: Date, type: string) => void;
  startDate: Date;
  endDate: Date;
  searchText: string;
  onSearchTextChange: (value: string) => void;
  currentLink: string;
  handleDownloadCSV: () => void;
  downloadLinkRef: React.RefObject<HTMLAnchorElement>;
  fileName: string;
  isButtonDisabled: boolean;
}

function CalendarIcon() {
  return (
    <img
      src={CalenderIconTmage}
      alt='calendar icon'
    />
  );
}

const ReportsFilter: FunctionComponent<TableFilterProps> = ({
  handleDateChange,
  startDate,
  endDate,
  searchText,
  onSearchTextChange,
  currentLink,
  handleDownloadCSV,
  downloadLinkRef,
  fileName,
  isButtonDisabled,
}) => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
        >
          {/* <Grid
            item
            xs={6}
            sm={2}
          >
            <DatePicker
              inputFormat='DD/MM/YYYY'
              disabled={false}
              value={startDate}
              onChange={(val: any) => {
                if (val?.$d) {
                  handleDateChange(val.$d, 'startDate');
                }
              }}
              renderInput={({ inputRef, inputProps, InputProps }: any) => {
                const inputPropsWithPlaceholder = {
                  ...inputProps,
                  placeholder: 'Start Date',
                };
                return (
                  <FormControl
                    variant='filled'
                    fullWidth
                  >
                    <InputLabel
                      sx={theme => ({
                        'color': 'text.labelColor',
                        'fontSize': '14px',
                        'fontWeight': '500',
                        'top': '2px',
                        'left': '6px',
                        '&.Mui-focused': {
                          color: 'text.labelColor',
                        },
                        [theme.breakpoints.between(768, 1200)]: {
                          fontSize: '13px',
                        },
                      })}
                    >
                      Start Date
                    </InputLabel>
                    <BootstrapInput
                      sx={theme => ({
                        'border': `1px solid ${theme.palette.text.borderColorLight}`,
                        'borderRadius': '5px',
                        'paddingRight': '16px',
                        '&.Mui-disabled': {
                          bgcolor: 'text.borderColorDark',
                        },
                        '& .MuiInputBase-input': {
                          border: 'none',
                          padding: '16px 16px 0px 16px',
                        },
                      })}
                      ref={inputRef}
                      id={`${name}-input`}
                      {...inputPropsWithPlaceholder}
                      {...InputProps}
                    />
                  </FormControl>
                );
              }}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
            />
          </Grid> */}
          {/* <Grid
            item
            xs={6}
            sm={2}
          > */}
          {/* Add Date Picker for the second item
            <DatePicker
              inputFormat='DD/MM/YYYY'
              disabled={false}
              value={endDate}
              onChange={(val: any) => {
                if (val?.$d) {
                  handleDateChange(val.$d, 'endDate');
                }
              }}
              renderInput={({ inputRef, inputProps, InputProps }: any) => {
                const inputPropsWithPlaceholder = {
                  ...inputProps,
                  placeholder: 'End Date',
                };
                return (
                  <FormControl
                    variant='filled'
                    fullWidth
                  >
                    <InputLabel
                      sx={theme => ({
                        'color': 'text.labelColor',
                        'fontSize': '14px',
                        'fontWeight': '500',
                        'top': '2px',
                        'left': '6px',
                        '&.Mui-focused': {
                          color: 'text.labelColor',
                        },
                        [theme.breakpoints.between(768, 1200)]: {
                          fontSize: '13px',
                        },
                      })}
                    >
                      End Date
                    </InputLabel>
                    <BootstrapInput
                      sx={theme => ({
                        'border': `1px solid ${theme.palette.text.borderColorLight}`,
                        'borderRadius': '5px',
                        'paddingRight': '16px',
                        '&.Mui-disabled': {
                          bgcolor: 'text.borderColorDark',
                        },
                        '& .MuiInputBase-input': {
                          border: 'none',
                          padding: '16px 16px 0px 16px',
                        },
                      })}
                      ref={inputRef}
                      id={`${name}-input`}
                      {...inputPropsWithPlaceholder}
                      {...InputProps}
                    />
                  </FormControl>
                );
              }}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
            /> */}
          {/* </Grid> */}

          <Grid
            item
            xs={12}
            sm={6}
          >
            <CustomSearch
              placeholder='Search'
              value={searchText}
              onChange={onSearchTextChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
          >
            <Link
              to={!isButtonDisabled ? currentLink : ''}
              target='_blank'
              download={!isButtonDisabled ? fileName : ''}
              style={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                fontSize: '15px',
                color: isButtonDisabled
                  ? theme.palette.text.disabled
                  : theme.palette.primary.main,
                fontWeight: 600,
                columnGap: '10px',
                borderRadius: '5px',
                border: `1px solid ${
                  isButtonDisabled
                    ? theme.palette.text.disabled
                    : theme.palette.primary.main
                }`,
                padding: '10px 10px',
                pointerEvents: isButtonDisabled ? 'none' : 'auto',
                cursor: isButtonDisabled ? 'default' : 'pointer',
                background: 'none',
              }}
            >
              <FileIcon />
              Download in Excel
            </Link>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default ReportsFilter;
