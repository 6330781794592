import { Box, Grid, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../../data/root.context';
import { TransactionResponse } from '../../../../features/transactions/transactionSlice';
import FormDateInput from '../../../../forms/FormDateInput';
import { FormSelectInput } from '../../../../forms/FormSelect';
import { FormSelectWithCheckBoxInput } from '../../../../forms/FormSelectWithCheckbox';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput, { FormPasswordInput } from '../../../../forms/FormTextInput';
import Layout from '../../../Layout';
import { emailRegex } from '../../../regex';
import { useListOfSchemesMutation } from '../../../slice';
import { periodOptions } from '../../constant';
import { useCglReportMutation } from '../../slice';
import { getFiscalYear } from '../../utils';
import { Option } from '..';

export interface CapitalGainsStatementType {
  pan: string;
  emailId: string;
  period: number;
  amc: string[];
  startDate: any;
  endDate: any;
  deliveryOption: string;
  statementFormat: string;
  password: string;
  confirmPassword: string;
}

export default function ConsolidatedCapitalGainsStatement() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const { pan } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [responseMsg, setResponseMsg] = useState<string>('');
  const [amcOptions, setAmcOptions] = useState<Option[]>([]);
  const [fs] = getFiscalYear(0);

  const [listOfSchemes] = useListOfSchemesMutation();
  const [getCapitalGainLoss] = useCglReportMutation();

  const initialValues: CapitalGainsStatementType = {
    pan: pan || '',
    emailId: '',
    period: 0,
    amc: [],
    startDate: fs || null,
    endDate: new Intl.DateTimeFormat('en-US').format(new Date()) || null,
    deliveryOption: 'Email a download link',
    statementFormat: 'PDF',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    pan: Yup.string().required('PAN is required'),
    emailId: Yup.string()
      .required('Email ID is required')
      .matches(emailRegex, 'email must be a valid email'),
    period: Yup.string().required('Period is required'),
    amc: Yup.array().required('AMC is required').min(1, 'Please select at least one AMC'),
    startDate: Yup.date()
      .nullable()
      .typeError('Invalid Date')
      .required('Start date is required'),
    endDate: Yup.date()
      .nullable()
      .typeError('Invalid Date')
      .required('End date is required'),
    statementFormat: Yup.string().required('Statement format is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]+$/,
        'Password should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)',
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .test(
        'passwordMatch',
        'The Password and confirm Password do not match',
        function (value) {
          return value === this.parent.password;
        },
      ),
  });

  useEffect(() => {
    async function fetchData() {
      if (!pan) return;
      try {
        const data: any[] = await listOfSchemes({ pan: pan.toUpperCase() }).unwrap();
        const fundNames: Option[] = data.map((item: any) => ({
          label: item.amcName,
          value: item.fund,
        }));
        const uniqueAmc: Option[] = Array.from(
          new Set(fundNames.map(option => option.label)),
        )
          .map(label => fundNames.find(option => option.label === label))
          .filter((value): value is Option => value !== undefined);
        setAmcOptions(uniqueAmc);
      } catch (error: any) {
        showToast(error.message, 'error');
      }
    }
    fetchData();
  }, []);

  const handlePeriodOptionChange = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    const [fs, fe] = getFiscalYear(e.target.value);
    switch (e.target.value) {
      case 0:
        setFieldValue('startDate', fs);
        setFieldValue('endDate', fe);
        break;
      case -1:
        setFieldValue('startDate', fs);
        setFieldValue('endDate', fe);
        break;
      case -2:
        setFieldValue('startDate', fs);
        setFieldValue('endDate', fe);
        break;
      case -3:
        setFieldValue('startDate', fs);
        setFieldValue('endDate', fe);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (values: CapitalGainsStatementType) => {
    const params = {
      emailId: values.emailId,
      pan: values.pan,
      password: values.password,
      cnmPassword: values.confirmPassword,
      fromDate: values.startDate,
      toDate: values.endDate,
      emailDownload: values.deliveryOption,
      fund: values.amc.join(',').replace('"', ''),
      accountStFormat: values.statementFormat,
    };
    try {
      const response: any = await getCapitalGainLoss(params).unwrap();
      if (response?.message) {
        setResponseMsg(response?.message);
        setOpenDialogBox(true);
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  return (
    <>
      <Layout
        title='Consolidated Capital Gains Statement'
        isNctTransaction
      >
        <Investorcard
          sx={(theme: Theme) => ({
            padding: '30px 30px',
            [theme.breakpoints.down('sm')]: {
              boxShadow: 'unset',
              border: 'unset',
              padding: '0px',
            },
          })}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormTextInput
                        name='pan'
                        label='PAN'
                        disabled={pan !== ''}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormTextInput
                        name='emailId'
                        label='Email ID'
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormSelectInput
                        name='period'
                        label='period'
                        defaultValue={0}
                        options={periodOptions}
                        required
                        onChange={e => handlePeriodOptionChange(e, setFieldValue)}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormSelectWithCheckBoxInput
                        name='amc'
                        label='AMC'
                        options={amcOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormDateInput
                        name='startDate'
                        label='Start Date'
                        required={true}
                        disableFuture
                        viewOnly
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormDateInput
                        name='endDate'
                        label='End Date'
                        required={true}
                        disableFuture
                        viewOnly
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormTextInput
                        name='deliveryOption'
                        label='Delivery Option'
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        sx={{
                          mb: 2,
                          fontSize: { xs: '14px', xl: '16px' },
                          fontWeight: 500,
                          color: 'text.valueColor',
                        }}
                      >
                        Statement Format
                      </Typography>
                      <FormStyledRadioButton
                        options={[
                          { label: 'PDF', value: 'PDF' },
                          { label: 'Excel', value: 'Excel' },
                        ]}
                        name='statementFormat'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormPasswordInput
                        name='password'
                        label='Self Extraction Password'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormPasswordInput
                        name='confirmPassword'
                        label='Confirm Self Extraction Password'
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', mt: 5 }}>
                    <SubmitButton
                      sx={(theme: Theme) => ({
                        '&.MuiButtonBase-root': {
                          borderRadius: '5px',
                          minWidth: '200px',

                          [theme.breakpoints.down('sm')]: {
                            minWidth: '100%',
                          },
                        },
                      })}
                      label='Submit'
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Investorcard>

        <CustomDialog
          isVisible={openDialogBox}
          showFirstButton={false}
          firstButtonHandler={() => {}}
          firstButtonTitle=''
          showSecondButton
          secondButtonTitle='Continue'
          secondButtonHandler={() => navigate('/transaction-dashboard/dashboard')}
          handleClose={() => {}}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& img': {
                maxWidth: { xs: '17%', md: '70%' },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography
              variant='h3'
              sx={{ color: 'primary.main', my: 2 }}
            >
              Successfully submitted your request
            </Typography>
            <Typography variant='subtitle1'>{responseMsg}</Typography>
          </Box>
        </CustomDialog>
      </Layout>
    </>
  );
}
