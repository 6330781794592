import { Box, Grid, Stack } from '@mui/material';

import { CartLabel, CartValue } from '../../auth/login/styles/styledComponents';
import { MMddToDDMMYYYY } from '../reports/utils';
import { inrCurrencyFormatter } from '../utils';

const NewPurchaseCart = ({
  data,
  children,
  cartItemslength,
}: {
  data?: any;
  children?: React.ReactNode;
  cartItemslength?: boolean;
}) => {
  const payloadObj = JSON.parse(data.payload);
  const Amount =
    data?.transactionType === 'NPL' ? payloadObj?.amount : payloadObj?.installmentAmount;

  return (
    <Box
      sx={{
        'pb': 2,
        'pt': 3,
        'mb': 3,
        '&:nth-of-type(n+2)': {
          borderTop: cartItemslength ? '1px solid ' : 'unset',
          borderColor: cartItemslength ? 'text.borderColorLight' : 'unset',
        },
      }}
    >
      {children}

      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: 'space-between',
          my: 1,
        }}
      >
        <Grid
          item
          xs={!(data?.transactionType !== 'NPL') ? 9 : 12}
        >
          <Stack gap='10px'>
            <CartLabel>{payloadObj?.fundName}</CartLabel>
            <CartValue>{payloadObj?.schemeName}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          lg={3}
        >
          <Stack gap='10px'>
            <CartLabel>
              {data?.transactionType === 'NPL' ? 'Amount:' : 'Sip Amount'}
            </CartLabel>
            <CartValue>₹{Amount}</CartValue>
          </Stack>
        </Grid>
        {data?.transactionType !== 'NPL' && (
          <>
            {/* <Grid
              item
              xs={3}
            >
              <Stack gap='10px'>
                <CartLabel>SIP Day:</CartLabel>
                <CartValue>{payloadObj?.sipDay}</CartValue>
              </Stack>
            </Grid> */}
            <Grid
              item
              xs={6}
              lg={3}
            >
              <Stack gap='10px'>
                <CartLabel>No. Of Installments:</CartLabel>
                <CartValue>{payloadObj?.noOfInstallments}</CartValue>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              lg={3}
            >
              <Stack gap='10px'>
                <CartLabel>Start Date:</CartLabel>
                <CartValue>{MMddToDDMMYYYY(payloadObj?.sipStartDate)}</CartValue>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              lg={3}
            >
              <Stack gap='10px'>
                <CartLabel>End Date:</CartLabel>
                <CartValue>{MMddToDDMMYYYY(payloadObj?.sipEndDate)}</CartValue>
              </Stack>
            </Grid>
            {data?.investmentAmount !== 0 && (
              <Grid
                item
                xs={6}
                lg={3}
              >
                <Stack gap='10px'>
                  <CartLabel>Lumpsum Amount:</CartLabel>
                  <CartValue>{inrCurrencyFormatter(payloadObj?.amount)}</CartValue>
                </Stack>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default NewPurchaseCart;
