import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { CheckBoxHtmlTooltip } from '../../../common/checkbox';
import { startLoading, stopLoading } from '../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../data/root.context';
import { TransactionResponse } from '../../../features/transactions/transactionSlice';
import Layout from '../../Layout';
import { useCreateBankDetailsChangeRequestMutation } from '../../slice';
import BankForm from '../common/BankForm';
import DetailsDataTable from '../common/DetailsDataTable';
import { BankFormValues, DetailType } from '../common/types';

const ChangeBankDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [requestId, setRequestId] = useState('');
  const [selectedFolios, setSelectedFolios] = useState<any>();
  const [createBankDetailsChangeRequest] = useCreateBankDetailsChangeRequestMutation();
  const [formData, setFormData] = useState<BankFormValues>({
    accountNo: '',
    confirmAccountNo: '',
    ifsc: '',
    name: '',
    accountType: '',
    branch: '',
    city: '',
  });

  const { pan, mobile, email } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );

  async function handleTableSubmit(data: any) {
    const bankData = data[0].banks.find((bank: any) => bank.default === 'Y');
    if (bankData) {
      setSelectedFolios(data);
      setFormData({
        accountNo: '',
        confirmAccountNo: '',
        ifsc: '',
        name: '',
        accountType: bankData.accountType?.toUpperCase(),
        branch: '',
        city: '',
      });
    } else {
      showToast('Default Account not present', 'error');
    }
  }

  async function handleFormSubmit(data: BankFormValues) {
    try {
      dispatch(startLoading());
      const payload = {
        requestData: {
          pan: pan,
          mobile: mobile,
          email: email,
          data: selectedFolios.map((folio: any) => {
            return {
              folio: `${folio.folio}`,
              amc: folio.amcId,
              bankdetails: {
                accountNo: data.accountNo,
                accountType: data?.accountType?.toUpperCase(),
                name: data.name,
                newIfsc: data.ifsc,
                branch: data.branch,
                city: data.city,
              },
            };
          }),
        },
      };
      const response: any = await createBankDetailsChangeRequest(payload).unwrap();
      setRequestId(response.req_id);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }

  function isRowDisabled(currentRow: any, selected?: any): boolean {
    if (!selected?.length || currentRow.folio === selected[0].folio) return false;
    const selectedBank = selected[0].banks.filter((bank: any) => bank.default === 'Y')[0];
    const currentBank = currentRow.banks.filter((bank: any) => bank.default === 'Y')[0];
    if (!selectedBank || !currentBank) return true;
    if (
      selectedBank.accountNo === currentBank.accountNo &&
      selectedBank.name === currentBank.name
    )
      return false;
    return true;
  }

  function getTooltip(isSelected: boolean, isDisabled: boolean) {
    if (isSelected || !isDisabled) return undefined;
    return ({ children }: { children: JSX.Element }) => {
      return (
        <CheckBoxHtmlTooltip
          title={
            <>
              <Typography color='inherit'>
                Account Number and Bank Name should be same
              </Typography>
            </>
          }
        >
          {children}
        </CheckBoxHtmlTooltip>
      );
    };
  }

  return (
    <Layout
      title={'Change of Bank Account Details'}
      isNctTransaction
    >
      {!selectedFolios?.length ? (
        <DetailsDataTable
          columns={[
            'Folio Number',
            'Fund House',
            'Primary Holder',
            'Bank Name',
            'Account No.',
            'IFSC Code',
          ]}
          onSubmit={handleTableSubmit}
          detailType={DetailType.Investor}
          showEmailPhoneCombination
          isRowDisabled={isRowDisabled}
          disableSelectAll
          checkboxTooltip={getTooltip}
        />
      ) : (
        <BankForm
          initialValues={formData}
          onSubmit={handleFormSubmit}
          header='Add New Bank Account Details'
          subHeader='Please Note: Kindly enter the details carefully'
          folios={selectedFolios}
          setFolios={setSelectedFolios}
          isUpdateIfsc={false}
        />
      )}
      {!!requestId && (
        <CustomDialog
          isVisible={true}
          showFirstButton={false}
          firstButtonTitle=''
          firstButtonHandler={() => {}}
          secondButtonHandler={() => navigate('../')}
          secondButtonTitle='OK'
          handleClose={() => {}}
          showSecondButton
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& .MuiTypography-root': {
                // 'opacity': '0.85',
                'my': 2,
                '& >*': {
                  fontWeight: '600',
                },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography>
              Your request for{' '}
              <Typography component={'span'}>Change of Bank Account Details </Typography>
              is created successfully with request id{' '}
              <Typography component={'span'}>{requestId}</Typography>
            </Typography>
            <Typography>
              A Link has been sent to the Investor Email ID. After investor authorises the
              transaction, details will be updated after T+1 day.
            </Typography>
          </Box>
        </CustomDialog>
      )}
    </Layout>
  );
};

export default ChangeBankDetails;
