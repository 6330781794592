import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import InvestorProfileIcon from '../../assets/investor-profile.png';
import { Investorcard } from '../auth/login/styles/styledComponents';
import { TransactionResponse } from '../features/transactions/transactionSlice';
export function ConfirmationProfile({ data }: { data?: any }) {
  const { arn, name, subArn } = useSelector((state: { login: any }) => state.login);
  return (
    <Investorcard
      sx={{ bgcolor: 'text.rightProfileCard', display: { xs: 'none', md: 'block' } }}
    >
      <Box
        sx={{
          padding: '30px 20px',
          borderBottom: '0.5px solid ',
          borderColor: 'text.borderColorLight',
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            sx={{
              color: 'primary.main',
              fontWeight: 500,
              fontSize: { xs: '16px', xl: '18px' },
            }}
          >
            Investor Profile
          </Typography>
          <CardMedia
            component='img'
            image={InvestorProfileIcon}
            alt='Mutual Fund Pooled Investment'
            sx={{ width: '44px', height: '40px' }}
          />
        </Stack>
        <Grid
          container
          spacing={2}
          my='10px'
          sx={{
            '& .MuiTypography-root': {
              wordBreak: 'break-all',
            },
          }}
        >
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Name</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {data?.investorName || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>PAN</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {data?.pan || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Mobile Number</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {data?.mobile || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Email</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {data?.email || 'NA'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '30px 20px' }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            sx={{
              color: 'primary.main',
              fontWeight: 500,
              fontSize: { xs: '16px', xl: '18px' },
            }}
          >
            Distributor Profile
          </Typography>
          <CardMedia
            component='img'
            image={InvestorProfileIcon}
            alt='Mutual Fund Pooled Investment'
            sx={{ width: '44px', height: '40px' }}
          />
        </Stack>
        <Grid
          container
          spacing={2}
          my='10px'
        >
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>ARN Code</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {arn || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>ARN Name</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {name || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Sub ARN Code</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {subArn || 'NA'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Investorcard>
  );
}

export default function InvestorProfile({
  children,
  bottomComponent,
  isNctTransaction,
}: {
  children?: React.ReactNode;
  bottomComponent?: React.ReactNode;
  isNctTransaction?: boolean;
}) {
  const { name, pan, mobile, email } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  return (
    <>
      <Investorcard
        sx={{
          bgcolor: 'text.rightProfileCard',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Box
          sx={{
            padding: '30px 20px',
          }}
        >
          <Stack
            direction='column'
            gap='16px'
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: { xs: '16px', xl: '18px' },
                }}
              >
                Investor Profile
              </Typography>
              <CardMedia
                component='img'
                image={InvestorProfileIcon}
                alt='Mutual Fund Pooled Investment'
                sx={{ width: '44px', height: '40px' }}
              />
            </Stack>
            {
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>Name</Typography>
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  {name || 'NA'}
                </Typography>
              </Stack>
            }
            {
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>PAN</Typography>
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  {pan?.toUpperCase() || 'NA'}
                </Typography>
              </Stack>
            }
            {isNctTransaction && (
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>Mobile Number</Typography>
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  {mobile || 'NA'}
                </Typography>
              </Stack>
            )}
            {isNctTransaction && (
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>Email</Typography>
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  {email || 'NA'}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
        {children}
      </Investorcard>
      {bottomComponent}
    </>
  );
}
