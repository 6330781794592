import { Box, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';

import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput, { FormPasswordInput } from '../../forms/FormTextInput';
import { BankDetailsFormValues, BankValues } from '../BankDetails';
import { useCheckIfscMutation, useVerifyBankDetailsMutation } from '../slices';

interface AddBankAccountProps {
  setFieldError: (field: string, errorMessage: string) => void;
  index: number;
  isFromKyd?: boolean;
  setCurrentBankAccountToken: (token: string) => void;
  setHasValidationFailed: (hasFailed: boolean) => void;
}
const FIELD_NAMES = [
  'accountType',
  'ifsc',
  'bankName',
  'accountNumber',
  'verifiedAccountNumber',
  'isDefault',
  'ifscCodeEntered',
];

const AddBankAccount: React.FC<AddBankAccountProps> = ({
  index,
  isFromKyd,
  setFieldError,
  setHasValidationFailed,
  setCurrentBankAccountToken,
}) => {
  const { showToast } = useRootContext();
  const dispatch = useDispatch();
  const { setValues, values } = useFormikContext<BankDetailsFormValues>();
  const [checkIfscCode, { isSuccess: checkIfscSuccess }] = useCheckIfscMutation();
  const [verifyBankDetails, { isSuccess: verificationFailed, isLoading }] =
    useVerifyBankDetailsMutation();
  const fetchData = async (ifsc: string) => {
    if (ifsc.length == 11) {
      try {
        const { bankname } = await checkIfscCode({ ifsc }).unwrap();
        if (bankname === 'No Record Found') {
          showToast('Bank name not found for this code, pls try another one', 'error');
          return '';
        }
        return bankname;
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
        return '';
      }
    }
  };
  const validateBankAccount = async (
    bank: {
      accountNumber: string;
      verifiedAccountNumber: string;
      accountType: string;
      ifsc: string;
      bankName: string;
      isDefault: boolean;
    },
    index: number,
  ) => {
    setHasValidationFailed(false);

    try {
      const response: any = await verifyBankDetails({
        ifsc: bank.ifsc,
        accountType: bank.accountType === 'Savings' ? 'saving' : 'current',
        accountNumber: bank.accountNumber,
        isDefault: bank.isDefault ? 'true' : 'false',
        bankname: bank.bankName,
      }).unwrap();
      setCurrentBankAccountToken(response?.banktoken);
    } catch (error: any) {
      setCurrentBankAccountToken('');

      showToast((error.data as { message: string }).message, 'error');
    }

    // Check if verification failed
    // if (!verificationFailed) {
    //   // Handle failure case
    //   setHasValidationFailed(true);
    //   setFieldError(
    //     `bankList.${index}.accountNumber`,
    //     'Bank Account validation failed! Try again with updating the bank account number or upload canceled cheque.',
    //   );
    // } else {
    //   if ('data' in response) {
    //     setCurrentBankAccountToken(response.data.banktoken);
    //   }
    // }
  };

  const resetLaterFields = (
    values: BankDetailsFormValues,
    key: any,
    fieldValues: Partial<BankValues>,
    index: number, // added index type
  ): BankDetailsFormValues => {
    const allBanks = values?.bankList;
    setHasValidationFailed(false);

    const foundIndex = FIELD_NAMES.findIndex(fieldName => fieldName === key);

    const updatedValues = FIELD_NAMES.slice(foundIndex).reduce<Partial<BankValues>>(
      (acc, curr) => {
        return {
          ...acc,
          [curr]: '', // Reset each field to empty
        };
      },
      {},
    );

    const updatedBankList = allBanks.map((bank, i) => {
      if (i === index) {
        return {
          ...bank,
          ...updatedValues,
          ...fieldValues,
        };
      }
      return bank;
    });

    return {
      ...values,
      bankList: updatedBankList,
    };
  };

  return (
    <Box
      sx={{
        my: 2.5,
      }}
    >
      <Typography
        variant='subtitle1'
        sx={{ color: 'text.valueColor', mb: 2 }}
      >
        Account Type*
      </Typography>
      <FormStyledRadioButton
        options={[
          { label: 'Savings', value: 'Savings' },
          { label: 'Current', value: 'Current' },
        ]}
        name={`bankList.${index}.accountType`}
        handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!isFromKyd) {
            const newValues = resetLaterFields(
              values,
              'accountType',
              {
                accountType: event.target.value as string,
              },
              index,
            );
            setValues(newValues);
          } else {
            const updatedBankList = values.bankList.map((bank, i) => {
              if (i === index) {
                return {
                  ...bank,
                  accountType: event.target.value as string,
                };
              }
              return bank;
            });

            setValues({
              ...values,
              bankList: updatedBankList,
            });
          }
        }}
      />
      <Grid
        container
        sx={{ my: 1 }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormTextInput
            name={`bankList.${index}.ifsc`}
            label='IFSC Code'
            disabled={isFromKyd}
            onChange={async event => {
              dispatch(startLoading());
              const fetchedbankName = await fetchData(event.target.value?.toUpperCase());
              const newValues = resetLaterFields(
                values,
                'ifsc',
                {
                  ifsc:
                    fetchedbankName !== ''
                      ? (event.target.value?.toUpperCase() as string)
                      : '',
                  bankName: fetchedbankName,
                },
                index,
              );
              setValues(newValues);
              dispatch(stopLoading());
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormTextInput
            name={`bankList.${index}.bankName`}
            label='Select Bank'
            disabled={true}
            defaultValue={isFromKyd ? values.bankList[index].bankName : ''}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormPasswordInput
            name={`bankList.${index}.accountNumber`}
            label='Account Number'
            autoComplete='off'
            onInput={(e: any) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
              if (e.target.value.startsWith('0')) {
                e.target.value = e.target.value.replace(/^0+/, '');
              }
              if (e.target.value.length > 16) {
                e.target.value = e.target.value.slice(0, 16);
              }
            }}
            disabled={isFromKyd || isLoading}
            onChange={event => {
              const newValues = resetLaterFields(
                values,
                'accountNumber',
                {
                  accountNumber: event.target.value as string,
                },
                index,
              );
              setValues(newValues);
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormTextInput
            name={`bankList.${index}.verifiedAccountNumber`}
            label='Confirm Account Number'
            disabled={isFromKyd}
            onInput={(e: any) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
              if (e.target.value.startsWith('0')) {
                e.target.value = e.target.value.replace(/^0+/, '');
              }
              if (e.target.value.length > 16) {
                e.target.value = e.target.value.slice(0, 16);
              }
            }}
            onChange={event => {
              const bank = values.bankList[index];
              const verifiedAccountNumber = event.target.value;

              if (
                bank.accountNumber === verifiedAccountNumber &&
                bank.accountNumber.trim() !== '' &&
                bank.accountNumber.length >= 10 &&
                bank.accountNumber.length <= 16
              ) {
                validateBankAccount(bank, index);
              }
            }}
          />
          {isLoading && (
            <Typography
              variant='body2'
              sx={{ color: 'text.valueColor' }}
            >
              Validating Account Number...
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddBankAccount;
