import { Box, Grid, Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';

import { getDayFromMMDDYYYY } from '../../../utils';
import { CartLabel, CartValue } from '../../auth/login/styles/styledComponents';
import { FromAndToHeader } from '../common/helper';
import { TransactionType } from '../types';
import { inrCurrencyFormatter2 } from '../utils';

const formatDate = (isoDateString: string): string => {
  try {
    // Parse the ISO string into a Date object
    const date = parseISO(isoDateString);

    // Format the date as dd/MM/yyyy
    return format(date, 'dd/MM/yyyy');
  } catch (error) {
    return ''; // Return a default value or handle the error as needed
  }
};

const Cart = ({ data, children }: { data?: any; children?: React.ReactNode }) => {
  return (
    <Box>
      {children}
      <Typography sx={{ color: 'text.labelColor', mb: 2 }}>{data.fundName}</Typography>
      <FromAndToHeader
        data={data}
        type={TransactionType.STP}
      />
      <Grid
        container
        spacing={2}
        sx={theme => ({
          justifyContent: 'space-between',
          mb: 3,
        })}
      >
        <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            <CartLabel>Folio</CartLabel>
            <CartValue>{data.folio}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            <CartLabel>STP Type</CartLabel>
            <CartValue>{data.stpOption}</CartValue>
          </Stack>
        </Grid>

        <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            <CartLabel>STP Installment Amount</CartLabel>
            <CartValue>
              {inrCurrencyFormatter2(data?.installmentAmount || data?.investmentAmount)}
            </CartValue>
          </Stack>
        </Grid>

        <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            <CartLabel>STP Day</CartLabel>
            <CartValue>{getDayFromMMDDYYYY(data?.stpStartDate)}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            <CartLabel>Start date</CartLabel>
            <CartValue>{data?.stpStartDate}</CartValue>
          </Stack>
        </Grid>

        <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            <CartLabel>End date</CartLabel>
            <CartValue>{data?.stpEndDate}</CartValue>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cart;
