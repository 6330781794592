import { Box, Grid, styled, Theme, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

import Completed_PRANS from '../../../assets/Reports/completed-prans.svg';
import Contributions from '../../../assets/Reports/contributions.svg';
import Pending_PRANS from '../../../assets/Reports/pending-prans.svg';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../common/BackToHomePage';

interface ReportsCardData {
  title: string;
  image: string;
  type: string;
}
export default function NPSReports() {
  const navigate = useNavigate();

  const reportsCardData: ReportsCardData[] = [
    {
      title: 'Pending PRANs',
      image: Pending_PRANS,
      type: 'PENDING',
    },
    {
      title: 'Completed PRANs',
      image: Completed_PRANS,
      type: 'COMPLETED',
    },
    {
      title: 'Contributions',
      image: Contributions,
      type: 'CONTRIBUTIONS',
    },
  ];
  const TransactCardMedia = styled(Box)(({ theme }) => ({
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '40px',
      height: '40px',
    },
  }));
  const cardHandler = (type: string, heading: string) => {
    navigate('../reports', {
      state: { type, heading },
    });
  };

  return (
    <>
      <PageNavigator title='MIS Reports' />
      <Grid
        container
        spacing={3}
      >
        {reportsCardData.map((item: ReportsCardData, index: number) => (
          <Grid
            item
            xs={12}
            sm={4}
            lg={3}
            key={index}
          >
            <Investorcard
              sx={(theme: Theme) => ({
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                columnGap: '20px',
                padding: '20px',
                maxWidth: '280px',
                [theme.breakpoints.down('sm')]: {
                  padding: '15px 20px',
                  width: '100%',
                  maxWidth: '100%',
                },
              })}
              onClick={() => cardHandler(item.type, item.title)}
            >
              <TransactCardMedia>
                <img
                  src={item?.image}
                  alt='pending-PRANS'
                />
              </TransactCardMedia>
              <Typography>{item.title}</Typography>
            </Investorcard>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
