import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as CircleAddIcon } from '../../assets/rounded-add-icon.svg';
import {
  FieldSubHeader3,
  StepperAddButton,
  StyledSubmitButton,
} from '../auth/login/styles/styledComponents';
import { NUMBERS_ARRAY } from '../common/constants';
import { useRootContext } from '../data/root.context';
import { nomineeList } from '../features/onboarding/onboardingSlice';
import { calculateAge } from './common';
import { NomineeFormSection, nomineeValidationSchema } from './NomineeFormContent';
import { useAddNomineeDetailsMutation, useLazyGetNomineeDetailsQuery } from './slices';
import { NomineeDetailsPayload, NomineeList } from './types';

export const NOMINEE_NUMBERS = NUMBERS_ARRAY.slice(0, 5);

interface NomineeDetailsProps {
  fromdistrubutorProfile?: boolean;
  individual?: boolean;
}

export const NomineeDetails: React.FC<NomineeDetailsProps> = props => {
  const { fromdistrubutorProfile } = props;
  const [addNomineeDetails] = useAddNomineeDetailsMutation();
  const [getNominees, nomineesList] = useLazyGetNomineeDetailsQuery();
  const { showToast } = useRootContext();
  const [isNomineesSaved, setIsNomineesSaved] = useState(false);
  const [formCounter, setFormCounter] = useState<number>(0);
  const [editMode, setEditMode] = useState(false);
  const formikRefIndex = useRef<number | null>(null);
  const [savedNominees, setSavedNominees] = useState<NomineeDetailsPayload[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showNomineeForm = !isNomineesSaved && !editMode;
  const formikRef = useRef<FormikProps<NomineeList>>(null);
  const NOMINEE_DETAILS = {
    name: '',
    relation: '',
    gender: '',
    dateOfBirth: '',
    percent: 100,
    pan: '',
    email: '',
    mobile: '',
    address: '',
    addressTwo: '',
    city: '',
    pincode: '',
    state: '',
    country: '',
    guardianName: '',
    guardianPan: '',
    guardianRelation: '',
    guardianGender: '',
  };

  const checkNomineePercentage = () => {
    const sumOfPercent = savedNominees.reduce(
      (acc, nominee) => acc + Number(nominee.percent),
      0,
    );
    return sumOfPercent === 100 ? true : false;
  };

  const handleSubmit = async (values: any, addedForms: number) => {
    try {
      if (isNomineesSaved && !editMode) {
        const payload = { nominees: savedNominees };
        if (!checkNomineePercentage()) {
          showToast(
            'Nominee Share Percentage should not be greater or less than 100',
            'error',
          );
          return;
        }
        await addNomineeDetails(payload).unwrap();
        dispatch(nomineeList(nomineesList.data));
        showToast('Nominees added successfully', 'success');
        if (!fromdistrubutorProfile) navigate('../bank-details');
      } else {
        setFormCounter(prev => prev + addedForms);
        setIsNomineesSaved(true);
        setEditMode(false);
        setSavedNominees([...values.nominees]);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getNominees('');
    };
    fetchData();
  }, []);

  const savedNomineesRef = formikRef.current?.values.nominees;
  useEffect(() => {
    if (nomineesList.data) {
      if (Array.isArray(nomineesList.data.nominees)) {
        const apiNominees = nomineesList.data.nominees;
        // removing extra keys(id,updatedAt, createdAt) which are not in NomineeDetails Payload
        const mergedNominees = apiNominees.map((apiNominee: { [key: string]: any }) => {
          const filteredNominee = Object.keys(NOMINEE_DETAILS).reduce((acc, key) => {
            if (key in apiNominee && typeof apiNominee[key] !== 'undefined') {
              acc[key as keyof NomineeDetailsPayload] = apiNominee[key];
            }
            return acc;
          }, {} as Partial<NomineeDetailsPayload>);
          return filteredNominee as NomineeDetailsPayload;
        });
        if (mergedNominees.length > 0) {
          formikRef.current?.setValues({
            ...formikRef.current.values,
            nominees: mergedNominees,
          });
          setIsNomineesSaved(true);
        }
        setFormCounter(apiNominees.length);
        setEditMode(false);
        setSavedNominees(mergedNominees);
      }
    }
  }, [nomineesList.data]);

  const initialValues = {
    nominees: [NOMINEE_DETAILS],
  };
  const boxStyling = {
    mt: 5,
    pt: 5,
    borderTop: '0.5px dashed',
    borderColor: 'text.borderColorDark',
  };
  return (
    <>
      <Box sx={fromdistrubutorProfile ? {} : boxStyling}>
        {!fromdistrubutorProfile && (
          <>
            <Stack
              direction='row'
              alignItems='center'
            >
              {/* <HomePage /> */}
              <Typography
                sx={{
                  fontSize: { xs: '18px', sm: '20px', xl: '24px' },
                  fontWeight: 600,
                  mb: '10px',
                }}
              >
                {' '}
                Nominee Details
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontSize: { xs: '14px', xl: '16px' },
                mb: '40px',
                color: 'text.labelColor',
              }}
            >
              Provide essential beneficiary information for secure asset transfer or
              representation.
            </Typography>
          </>
        )}

        <Box>
          {isNomineesSaved &&
            !editMode &&
            savedNominees.map((nominee, index) => {
              return (
                <Box
                  sx={{
                    boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
                    borderRadius: '15px',
                    my: 2,
                    // padding: '20px',
                    border: '0.3px solid',
                    borderColor: 'text.borderColorDark',
                  }}
                  key={index}
                >
                  <Box
                    sx={{
                      borderRadius: '15px 15px 0px 0px',
                      bgcolor: 'text.boxColor',
                      padding: '5px 20px',
                    }}
                  >
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <FieldSubHeader3>
                        {NOMINEE_NUMBERS[index]} Nominee Details
                      </FieldSubHeader3>
                      <Box>
                        <IconButton
                          onClick={() => {
                            setEditMode(true);
                            formikRefIndex.current = index;
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        {savedNominees.length > 1 && (
                          <IconButton
                            onClick={() => {
                              if (savedNomineesRef) savedNomineesRef.splice(index, 1);
                              setSavedNominees(prevNominees => {
                                const updatedNominees = prevNominees.filter(
                                  (_, index1) => index1 !== index,
                                );
                                return updatedNominees;
                              });
                              setFormCounter(formCounter - 1);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Stack>
                  </Box>

                  <Box sx={{ padding: '5px 20px 20px' }}>
                    <Grid
                      container
                      my='5px'
                      spacing={2}
                      sx={{
                        '&>.MuiGrid-item ': {
                          pb: 2,
                        },
                        '& .MuiTypography-root': {
                          fontSize: '14px',
                        },
                        '& .MuiTypography-h5 ': {
                          fontWeight: '400',
                          color: 'text.labelColor',
                        },
                        '& .MuiTypography-body1 ': {
                          fontWeight: '500',
                          color: 'text.valueColor',
                        },
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={4}
                      >
                        <Stack gap='10px'>
                          <Typography variant='h5'>Nomineee Name </Typography>

                          <Typography component='span'>{nominee.name}</Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                      >
                        <Stack gap='10px'>
                          <Typography variant='h5'>Relation :</Typography>

                          <Typography component='span'>{nominee.relation}</Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                      >
                        <Stack gap='10px'>
                          <Typography variant='h5'>Mobile No. :</Typography>

                          <Typography component='span'>
                            {nominee.mobile ?? 'N/A'}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                      >
                        <Stack gap='10px'>
                          <Typography variant='h5'>Gender : </Typography>

                          <Typography component='span'>{nominee.gender}</Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                      >
                        <Stack gap='10px'>
                          <Typography variant='h5'>Share :</Typography>

                          <Typography component='span'>{nominee.percent}%</Typography>
                        </Stack>
                      </Grid>

                      <Grid
                        item
                        sm={12}
                      >
                        <Stack gap='10px'>
                          <Typography variant='h5'>Address :</Typography>

                          <Typography component='span'>
                            {nominee.address ?? 'N/A'}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              );
            })}
          <Box>
            <Formik
              initialValues={initialValues}
              validationSchema={nomineeValidationSchema}
              onSubmit={async values =>
                handleSubmit(values, values.nominees.length - formCounter)
              }
              innerRef={formikRef}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ values, errors }) => {
                return (
                  <Form>
                    <>
                      <FieldArray name='nominees'>
                        {arrayHelpers => (
                          <>
                            {values.nominees.length > 0 &&
                              showNomineeForm &&
                              values.nominees.map((nominee, index) => {
                                if (index >= formCounter) {
                                  return (
                                    <>
                                      <NomineeFormSection
                                        values={values}
                                        editMode={editMode}
                                        age={calculateAge(nominee.dateOfBirth)}
                                        index={index}
                                        setFormCounter={setFormCounter}
                                        formCounter={formCounter}
                                        name='Nominee'
                                        errors={errors}
                                        userAddresses={nomineesList.data?.userAddress}
                                      />
                                    </>
                                  );
                                }
                              })}
                            {editMode && (
                              <NomineeFormSection
                                values={values}
                                editMode={editMode}
                                age={calculateAge(
                                  values.nominees[formikRefIndex.current!].dateOfBirth,
                                )}
                                index={formikRefIndex.current!}
                                name='Nominee'
                                errors={errors}
                                userAddresses={nomineesList.data?.userAddress}
                              />
                            )}
                            {/* <IndicatorTypo /> */}

                            <Stack
                              direction='row'
                              justifyContent={
                                values.nominees.length < 5 && isNomineesSaved && !editMode
                                  ? 'space-between'
                                  : 'center'
                              }
                              gap='20px'
                              mt='20px'
                            >
                              {values.nominees.length < 5 && !editMode && (
                                <StepperAddButton
                                  sx={{ flex: 1, my: 3, padding: '8px 20px' }}
                                  startIcon={<CircleAddIcon />}
                                  onClick={() => {
                                    const percentage =
                                      100 -
                                      values.nominees.reduce(
                                        (acc: number, nominee: any) =>
                                          acc + Number(nominee.percent),
                                        0,
                                      );
                                    arrayHelpers.push({
                                      ...NOMINEE_DETAILS,
                                      percent: percentage > 0 ? percentage : 0,
                                    });
                                    setIsNomineesSaved(false);
                                  }}
                                >
                                  Add&nbsp;Nominee
                                </StepperAddButton>
                              )}

                              <StyledSubmitButton
                                sx={{
                                  minWidth: { xs: 'unset', md: '350px' },
                                  padding: '8px 20px',
                                  flex:
                                    editMode ||
                                    (!editMode && values.nominees.length === 5)
                                      ? '0 0 350px'
                                      : 1,
                                }}
                                label={
                                  isNomineesSaved && !editMode
                                    ? 'Save and Proceed'
                                    : 'Save'
                                }
                              />
                            </Stack>
                          </>
                        )}
                      </FieldArray>
                    </>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Box>
    </>
  );
};
