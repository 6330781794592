/* eslint-disable no-unused-vars */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FormControl,
  FormHelperText,
  // FormLabel,
  IconButton,
  InputAdornment,
  InputBase,
  InputBaseProps,
  InputLabel,
  // OutlinedInput,
  //OutlinedInput,
  styled,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent, ReactElement, useMemo, useState } from 'react';

import VisibilityOff from '../../../assets/password-not-visible.svg';
import VisibilityOn from '../../../assets/password-visible.svg';

const getBootstrapInput = (labelExist?: boolean) => {
  return styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      'height': '33px',
      'padding': labelExist ? '16px 16px 0px 16px' : ' 8px 16px 8px 16px',
      'fontSize': 13,
      'fontWeight': '500',
      'color': theme.palette.text.primary,
      'borderColor': theme.palette.text.borderColorLight,
      'borderRadius': '8px',
      'borderWidth': '1px',
      '&.Mui-disabled': {
        'backgroundColor': theme.palette.text.lightGray,
        'color': theme.palette.text.primary,
        '-webkitTextFillColor': 'unset',
      },
    },
  }));
};

export const BootstrapInput = getBootstrapInput();

export function FormTextArea({
  name,
  label,
  fullWidth = true,
  required = true,
  placeholder: _placeholder,
  rows = 4,
  onChange,
  ...rest
}: InputBaseProps & {
  name: string;
  label?: string | ReactElement;
  rows?: number;
}): JSX.Element {
  const formikContext = useFormikContext();
  const { isSubmitting: disabled, setFieldTouched } = formikContext;
  const [field, meta, helpers] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  const placeholder = _placeholder || `Enter ${label}`;
  const BootstrapTextareaWithLabel = useMemo(
    () => getBootstrapTextarea(!!label),
    [label],
  );

  return (
    <FormControl
      margin='dense'
      variant='filled'
      sx={{
        cursor: rest.disabled ? 'not-allowed' : '',
      }}
      fullWidth={fullWidth}
      error={hasError}
    >
      {label && (
        <InputLabel
          required={required}
          sx={(theme: Theme) => ({
            'color': 'text.labelColor',
            'fontSize': '14px',
            'fontWeight': '500',
            'top': '2px',
            'left': '6px',
            '&.Mui-focused': {
              color: 'text.labelColor',
            },
            [theme.breakpoints.between(768, 1200)]: {
              fontSize: '13px',
            },
          })}
        >
          {label}
        </InputLabel>
      )}
      <BootstrapTextareaWithLabel
        id={`${name}-textarea`}
        disabled={disabled}
        {...field}
        {...rest}
        placeholder={placeholder}
        rows={rows}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          formikContext.handleChange(e);
          onChange?.(e);
        }}
      />
      <FormHelperText
        style={{ color: 'error.main', fontSize: '11px', fontWeight: '400' }}
      >
        {errorText && (
          <InfoOutlinedIcon
            sx={(theme: Theme) => ({
              fontSize: '14px',
              verticalAlign: 'middle',
              mr: 0.5,
              [theme.breakpoints.between(768, 1200)]: {
                fontSize: '13px',
              },
            })}
            color='error'
          />
        )}
        {errorText}
      </FormHelperText>
    </FormControl>
  );
}

function getBootstrapTextarea(labelPresent: boolean) {
  const baseStyles = {
    'backgroundColor': 'background.paper',
    'borderRadius': '4px',
    'padding': '10px',
    '& .MuiFilledInput-root': {
      backgroundColor: 'background.paper',
      borderRadius: '4px',
    },
    '& .MuiFilledInput-input': {
      padding: '10px',
    },
    'fontSize': '14px',
    'color': 'text.primary',
  };

  const labelStyles = labelPresent
    ? {
        '& .MuiFilledInput-root': {
          paddingTop: '32px',
        },
      }
    : {};

  return function BootstrapTextarea(props: any) {
    return (
      <TextField
        {...props}
        multiline
        variant='filled'
        InputProps={{
          ...props.InputProps,
          disableUnderline: true,
        }}
        sx={{ ...baseStyles, ...labelStyles }}
        rows={props.rows}
      />
    );
  };
}

function FormTextInput({
  name,
  label,
  fullWidth = true,
  required = true,
  placeholder: _placeholder,
  onChange,
  typeOfInput,
  ...rest
}: InputBaseProps & {
  name: string;
  label?: string | ReactElement;
  typeOfInput?: string;
}): JSX.Element {
  const formikContext = useFormikContext();
  const { isSubmitting: disabled, setFieldTouched } = formikContext;
  const [field, meta, helpers] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  const placeholder = _placeholder || `Enter ${label}`;
  const BootstrapInputWithLabel = useMemo(() => getBootstrapInput(!!label), [label]);
  return (
    <FormControl
      margin='dense'
      variant='filled'
      sx={{
        cursor: rest.disabled ? 'not-allowed' : '',
      }}
      fullWidth={fullWidth}
      error={hasError}
    >
      {label && (
        <InputLabel
          required={required}
          sx={(theme: Theme) => ({
            'color': 'text.labelColor',
            'fontSize': '14px',
            // transform: 'translate(12px, 7px) scale(1)',
            'fontWeight': '500',
            'top': '2px',
            'left': '6px',
            '&.Mui-focused': {
              color: 'text.labelColor',
            },
            [theme.breakpoints.between(768, 1200)]: {
              fontSize: '13px',
            },
          })}
        >
          {label}
        </InputLabel>
      )}
      <BootstrapInputWithLabel
        id={`${name}-input`}
        disabled={disabled}
        {...field}
        {...rest}
        placeholder={placeholder}
        onChange={e => {
          if (typeOfInput === 'number' && !/^\d*\.?\d*$/.test(e.target.value)) {
            return;
          }
          if (
            typeOfInput === 'alphabet' &&
            !/^(?:\d|\s+)|[^a-z\s]/gi.test(e.target.value)
          ) {
            return;
          }
          if (typeOfInput === 'singleSpace') {
            e.target.value = e.target.value
              .replace(/[^a-zA-Z\s]/g, '')
              .replace(/(\s{2,})/g, ' ')
              .replace(/^\s*/, '')
              .trimStart();
          }
          if (typeOfInput === 'alphanumeric') {
            e.target.value = e.target.value
              .replace(/[^a-zA-Z0-9\s]/g, '')
              .replace(/(\s{2,})/g, ' ')
              .replace(/^\s*/, '')
              .trimStart();
          }
          if (typeOfInput === 'address') {
            e.target.value = e.target.value
              .replace(/[^a-zA-Z0-9\s\-,\\/]/g, '')
              .replace(/(\s{2,})/g, ' ')
              .replace(/^\s*/, '')
              .trimStart();
          }
          if (typeOfInput === 'query') {
            e.target.value = e.target.value
              .replace(/(\s{2,})/g, ' ')
              .replace(/^\s*/, '')
              .trimStart();
          }
          if (name === 'pan') {
            helpers.setValue(e.target.value.toUpperCase());
          } else if (name !== 'email') {
            helpers.setValue(e.target.value);
          } else {
            formikContext.handleChange(e);
          }
          onChange?.(e);
        }}
        onWheel={e => (e.target as HTMLInputElement).blur()}
      />
      <FormHelperText
        style={{ color: 'error.main', fontSize: '11px', fontWeight: '400' }}
      >
        {errorText && (
          <InfoOutlinedIcon
            sx={(theme: Theme) => ({
              fontSize: '14px',
              verticalAlign: 'middle',
              mr: 0.5,
              [theme.breakpoints.between(768, 1200)]: {
                fontSize: '13px',
              },
            })}
            color='error'
          />
        )}
        {errorText}
      </FormHelperText>
    </FormControl>
  );
}
export function FormPasswordInput({
  ...rest
}: InputBaseProps & { name: string; label: string | ReactElement }): JSX.Element {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <FormTextInput
      sx={{
        'border': '1px solid',
        'borderColor': 'text.borderColorLight',
        'borderRadius': '8px',
        'paddingRight': '16px',
        '& .MuiInputBase-input': {
          border: 'none',
        },
      }}
      {...rest}
      type={passwordVisible ? 'text' : 'password'}
      autoComplete='off'
      endAdornment={
        <InputAdornment position='end'>
          <IconButton
            aria-label='toggle password visibility'
            edge='end'
            color='primary'
            onClick={() => {
              setPasswordVisible(!passwordVisible);
            }}
            disableRipple
          >
            {passwordVisible ? (
              <img
                src={VisibilityOn}
                alt='visiblity-on'
              />
            ) : (
              <img
                src={VisibilityOff}
                alt='visibility-off'
              />
            )}
          </IconButton>
        </InputAdornment>
      }
    ></FormTextInput>
  );
}
export function FormIconInput({
  onClick,
  inputIcon,
  ...rest
}: InputBaseProps & {
  name: string;
  label: string | ReactElement;
  onClick: () => void;
  inputIcon: any;
}): JSX.Element {
  return (
    <FormTextInput
      sx={{
        'border': '1px solid',
        'borderColor': 'text.borderColorLight',
        'borderRadius': '8px',
        'paddingRight': '16px',
        '&.Mui-disabled': {
          bgcolor: 'text.lightGray',
        },
        '& .MuiInputBase-input': {
          border: 'none',
        },
      }}
      {...rest}
      type='text'
      endAdornment={
        <InputAdornment position='end'>
          <IconButton
            aria-label='toggle password visibility'
            edge='end'
            color='primary'
            onClick={() => onClick()}
            disableRipple
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                color: 'primary.main',
                textDecoration: 'underline',
              }}
            >
              {inputIcon}
            </Typography>
          </IconButton>
        </InputAdornment>
      }
    ></FormTextInput>
  );
}
export default FormTextInput;
