import {
  Box,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DropdownIcon } from '../../../../..//assets/select-dropdown-icon.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/close-icon.svg';
import { ReactComponent as BankDetailsIcon } from '../../../../../assets/NPS-CKYC/bank-details.svg';
import { ReactComponent as BankActiveIcon } from '../../../../../assets/NPS-CKYC/bank-details-active.svg';
import { ReactComponent as CompletedIcon } from '../../../../../assets/NPS-CKYC/completed-icon.svg';
import { ReactComponent as FatcaIcon } from '../../../../../assets/NPS-CKYC/fatca-details.svg';
import { ReactComponent as FatcaActiveIcon } from '../../../../../assets/NPS-CKYC/fatca-details-active.svg';
import { ReactComponent as InvestMentDetailsIcon } from '../../../../../assets/NPS-CKYC/investment-details.svg';
import { ReactComponent as InvestMentActiveIcon } from '../../../../../assets/NPS-CKYC/investment-details-active.svg';
import { ReactComponent as InvestorActiveIcon } from '../../../../../assets/NPS-CKYC/investor-details-active.svg';
import { ReactComponent as NomineeDetailsIcon } from '../../../../../assets/NPS-CKYC/nominee-details.svg';
import { ReactComponent as NomineeActiveIcon } from '../../../../../assets/NPS-CKYC/nominee-details-active.svg';
import { ReactComponent as ProcessIcon } from '../../../../../assets/NPS-CKYC/processing-icon.svg';
import { ReactComponent as SchemeDetailsIcon } from '../../../../../assets/NPS-CKYC/scheme-details.svg';
import { ReactComponent as SchemeActiveIcon } from '../../../../../assets/NPS-CKYC/scheme-details-active.svg';
import { ReactComponent as SummaryIcon } from '../../../../../assets/NPS-CKYC/summary-icon.svg';
import { ReactComponent as SummaryActiveIcon } from '../../../../../assets/NPS-CKYC/summary-icon-active.svg';
import { ReactComponent as UploadIcon } from '../../../../../assets/NPS-CKYC/upload-document.svg';
import { ReactComponent as UploadActiveIcon } from '../../../../../assets/NPS-CKYC/upload-document-active.svg';
import { DetailsHeader } from '../../../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../../../common/BackToHomePage';
import { Breadcrumb } from '../../../../common/breadcrumbs';
import { onboardingStepsCount } from '../../../../common/constants';
import { useRootContext } from '../../../../data/root.context';
import { setBasicDetails, setStepNumber } from '../context/NewSubscriberAction';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';
import { useGetEaadhaarMutation } from '../slice';
import BankDetails from './BankDetails';
import FatcaDetails from './FatcaDetails';
import InvestmentDetails from './InvestmentDetails';
import InvestorDetails from './InvestorDetails';
import { KycDetails } from './KycDetails';
import NomineeDetails from './NomineeDetails';
import { SchemeDetails } from './SchemeDetails';
import Summary from './Summary';
import UploadDocument from './UploadDocument';

const steps = [
  {
    inactiveIcon: <ProcessIcon />,
    activeIcon: <ProcessIcon />,
    label: 'Step 1',
    description: 'KYC Details',
    component: <KycDetails />,
  },
  {
    inactiveIcon: <InvestorActiveIcon />,
    activeIcon: <InvestorActiveIcon />,
    label: 'Step 2',
    description: 'Investor Details',
    component: <InvestorDetails />,
  },
  {
    inactiveIcon: <FatcaIcon />,
    activeIcon: <FatcaActiveIcon />,
    label: 'Step 3',
    description: 'FATCA Details',
    component: <FatcaDetails />,
  },
  {
    inactiveIcon: <InvestMentDetailsIcon />,
    activeIcon: <InvestMentActiveIcon />,
    label: 'Step 4',
    description: 'Investment Details',
    component: <InvestmentDetails />,
  },
  {
    inactiveIcon: <SchemeDetailsIcon />,
    activeIcon: <SchemeActiveIcon />,
    label: 'Step 5',
    description: 'Scheme Details',
    component: <SchemeDetails />,
  },
  {
    inactiveIcon: <NomineeDetailsIcon />,
    activeIcon: <NomineeActiveIcon />,
    label: 'Step 6',
    description: 'Nominee Details',
    component: <NomineeDetails />,
  },
  {
    inactiveIcon: <BankDetailsIcon />,
    activeIcon: <BankActiveIcon />,
    label: 'Step 7',
    description: 'Bank Details',
    component: <BankDetails />,
  },
  {
    inactiveIcon: <UploadIcon />,
    activeIcon: <UploadActiveIcon />,
    label: 'Step 8',
    description: 'Upload Document',
    component: <UploadDocument />,
  },
  {
    inactiveIcon: <SummaryIcon />,
    activeIcon: <SummaryActiveIcon />,
    label: 'Step 9',
    description: 'Summary',
    component: <Summary />,
  },
];

// TODO: implement logic for mobile screens, also make use of common code for all screens
function ProgressMobileStepper() {
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const {
    dispatch: contextDispatch,
    state: { pan, currentStep },
  } = useNewSubscriberContext();
  const [activeStep, setActiveStep] = useState(0);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '90px',
          left: 0,
          width: '100%',
          zIndex: 2,
          mb: 4,
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Box
          sx={{
            bgcolor: 'text.babyBlue',
            padding: '20px 10px 20px 20px',
            borderBottom: '1px solid',
            borderColor: 'text.borderColorLight',
          }}
        >
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack
              direction={'row'}
              columnGap={'15px'}
            >
              {steps[currentStep].activeIcon}
              <Stack>
                <DetailsHeader sx={{ fontSize: '14px', mb: 1 }}>
                  {steps[currentStep].label} of {steps.length}
                </DetailsHeader>
                <Typography
                  sx={{ fontSize: '16px', color: 'primary.main', fontWeight: 600 }}
                >
                  {steps[currentStep].description}
                </Typography>
              </Stack>
            </Stack>
            <IconButton onClick={handleDrawerOpen}>
              <DropdownIcon />
            </IconButton>
          </Stack>
        </Box>
        <LinearProgress
          sx={{
            'bgcolor': 'transparent',
            'height': '3px',
            'width': '100%',
            'position': 'absolute',
            'bottom': '1px',
            '& .MuiLinearProgress-bar': {
              borderRadius: '0px 23px 31px 0px',
            },
          }}
          variant={'determinate'}
          value={(currentStep + 1) * (100 / onboardingStepsCount.nps)}
        />
      </Box>

      <Drawer
        anchor='bottom'
        sx={{
          '& .MuiPaper-root ': {
            borderRadius: '25px 25px 0px 0px',
            backgroundColor: 'common.white',
          },
        }}
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Box role='presentation'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: { xs: '10px 25px', sm: '0px 25px' },
              borderBottom: '0.5px solid',
              borderColor: 'text.borderColorLight',
              marginBottom: '20px',
            }}
          >
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: { xs: 500, sm: 700 },
                fontSize: { xs: '16px', sm: '18px' },
              }}
            >
              NPS Onboarding
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ maxWidth: 400, padding: { xs: '20px 20px', sm: 'unset' } }}>
            <Stepper
              activeStep={currentStep}
              orientation='vertical'
              sx={{
                '& .MuiStepLabel-root ': {
                  padding: '0px',
                },
                '& .MuiStepConnector-root ': {
                  'marginLeft': '20px',
                  '& .MuiStepConnector-line': {
                    minHeight: '45px',
                  },
                },
              }}
            >
              {steps.map((step, index) => {
                const isCompleted = index < currentStep;
                return (
                  <Step key={step.label}>
                    <StepLabel
                      icon={
                        isCompleted ? (
                          <CompletedIcon />
                        ) : index === currentStep ? (
                          step.activeIcon
                        ) : (
                          step.inactiveIcon
                        )
                      }
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{
                          color: 'text.labelColor',
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        sx={{
                          color: 'text.labelColor',
                          fontWeight: 700,
                        }}
                      >
                        {step.description}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

const NewSubscriberSteps = () => {
  const { showToast } = useRootContext();

  const { search = '' } = location;
  const {
    dispatch: contextDispatch,
    state: { pan, currentStep },
  } = useNewSubscriberContext();
  const navigate = useNavigate();
  const [getEaadhaar] = useGetEaadhaarMutation();

  useEffect(() => {
    const localContextString = localStorage.getItem('newSub');

    const localContext = localContextString ? JSON.parse(localContextString) : '';
    if (!pan && !localContext) {
      navigate('../');
      return;
    }
    if (!localContext) {
      return;
    }
    const {
      dispatch: contextDispatcher,
      state: { ...others },
    } = localContext;
    contextDispatch(
      setBasicDetails({
        ...others,
      }),
    );
    localStorage.removeItem('newSub');
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { code, error, error_description } = params;
    const getAadhaarDetails = async (_code: string) => {
      try {
        const response = await getEaadhaar({ code: _code }).unwrap();
        if (response.data.digilockerData?.Certificate) {
          contextDispatch(
            setBasicDetails({
              aadhaarDetails: response.data.digilockerData,
            }),
          );
        } else {
          showToast('Failed to fetch AADHAAR details. Please try again.', 'error');
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      } finally {
      }
    };
    getAadhaarDetails(code);
  }, []);

  return (
    <Box>
      <ProgressMobileStepper />
      <Grid
        container
        spacing={2}
        sx={{ mt: { xs: '85px', sm: 0 } }}
      >
        <Grid
          item
          md={3}
          sx={{
            bgcolor: 'text.babyBlue',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Box sx={{ padding: '50px 20px' }}>
            <Stepper
              activeStep={currentStep}
              orientation='vertical'
              sx={{
                '& .MuiStepLabel-root ': {
                  padding: '0px',
                },
                '& .MuiStepConnector-root ': {
                  'marginLeft': '20px',
                  '& .MuiStepConnector-line': {
                    minHeight: '65px',
                  },
                },
              }}
            >
              {steps.map((step, index) => {
                const isCompleted = index < currentStep;
                return (
                  <Step key={step.label}>
                    <StepLabel
                      icon={
                        isCompleted ? (
                          <CompletedIcon />
                        ) : index === currentStep ? (
                          step.activeIcon
                        ) : (
                          step.inactiveIcon
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      <Typography
                        sx={{
                          color:
                            index === currentStep ? 'text.dodgerBlue' : 'text.labelColor',
                          fontSize: '12px',
                          fontWeight: 500,
                          cursor: 'pointer',
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography
                        sx={{
                          color:
                            index === currentStep ? 'text.dodgerBlue' : 'text.labelColor',
                          fontSize: { sm: '16px', xl: '18px' },
                          fontWeight: 700,
                          cursor: 'pointer',
                        }}
                      >
                        {step.description}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Grid>
        {/* Right side (70%) */}
        <Grid
          item
          md={8}
          xs={12}
        >
          <Breadcrumb
            title='Home'
            subtitle='NPS Investor Onboarding'
            onClick={() => navigate('../')}
          />
          <PageNavigator
            sx={(theme: Theme) => ({
              [theme.breakpoints.down('md')]: {
                mt: 0,
              },
            })}
            backHandler={() => {
              if (currentStep === 0) {
                navigate('../');
              } else {
                contextDispatch(setStepNumber(currentStep - 1));
              }
            }}
            title={steps[currentStep].description}
          />

          {steps[currentStep].component}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewSubscriberSteps;
