export const onBoardingStatusCompany = {
  AssetClass: 'select-asset',
  PrimaryDetails: 'primary-details',
  BankDetails: 'bank-details',
  AuthSignDetails: 'auth-sign',
  documents: 'documents',
  Completed: 'completed',
};

export const onBoardingStatusIndividual = {
  AssetClass: 'select-asset',
  PersonalDetails: 'personal-details',
  NomineeDetails: 'nominee-details',
  BankDetails: 'bank-details',
  Completed: 'completed',
};

export function toFixedToNumber(num: any, precision = 2): number {
  if (num?.toString().trim() === '' || isNaN(num)) {
    return 0;
  }

  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
}

export const NUMBERS_ARRAY = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
];

export const assetsFullName = (asset: string) => {
  const assetValue = asset?.toUpperCase();
  switch (asset) {
    case 'MFS':
      return 'Mutual Fund';
    case 'NPS':
      return 'NPS';
    default:
      return 'Mutual Fund';
  }
};

export const statesList: { Name: string }[] = [
  { Name: 'Andhra Pradesh' },
  { Name: 'Arunachal Pradesh' },
  { Name: 'Assam' },
  { Name: 'Bihar' },
  { Name: 'Chhattisgarh' },
  { Name: 'Goa' },
  { Name: 'Gujarat' },
  { Name: 'Haryana' },
  { Name: 'Himachal Pradesh' },
  { Name: 'Jammu and Kashmir' },
  { Name: 'Jharkhand' },
  { Name: 'Karnataka' },
  { Name: 'Kerala' },
  { Name: 'Madhya Pradesh' },
  { Name: 'Maharashtra' },
  { Name: 'Manipur' },
  { Name: 'Meghalaya' },
  { Name: 'Mizoram' },
  { Name: 'Nagaland' },
  { Name: 'Odisha' },
  { Name: 'Punjab' },
  { Name: 'Rajasthan' },
  { Name: 'Sikkim' },
  { Name: 'Tamil Nadu' },
  { Name: 'Telangana' },
  { Name: 'Tripura' },
  { Name: 'Uttarakhand' },
  { Name: 'Uttar Pradesh' },
  { Name: 'West Bengal' },
  { Name: 'Andaman and Nicobar Islands' },
  { Name: 'Chandigarh' },
  { Name: 'Dadra and Nagar Haveli' },
  { Name: 'Daman and Diu' },
  { Name: 'Delhi' },
  { Name: 'Lakshadweep' },
  { Name: 'Puducherry' },
];

export const onboardingStepsCount = {
  mfs: 5,
  nps: 9,
};
export const CANCELLATION_REASONS_LIST = [
  'Non availability of Funds',
  'Scheme not performing',
  'Service issue',
  'Load Revised',
  'Wish to invest in other schemes',
  'Change in Fund Manager',
  'Goal Achieved',
  'Not comfortable with market volatility',
  'Will be restarting SIP after few months',
  'Modifications in bank/mandate/date etc',
  'I have decided to invest elsewhere',
  'This is not the right time to invest',
  'Others-',
];

export const countryListOptions = [
  {
    country_name: 'Afghanistan',
    countryValue: 'AFGHANISTAN',
  },
  {
    country_name: 'Albania',
    countryValue: 'ALBANIA',
  },
  {
    country_name: 'Algeria',
    countryValue: 'ALGERIA',
  },
  {
    country_name: 'Andorra',
    countryValue: 'ANDORRA',
  },
  {
    country_name: 'Angola',
    countryValue: 'ANGOLA',
  },
  {
    country_name: 'Antigua and Barbuda',
    countryValue: 'ANTIGUA AND BARBUDA',
  },
  {
    country_name: 'Argentina',
    countryValue: 'ARGENTINA',
  },
  {
    country_name: 'Armenia',
    countryValue: 'ARMENIA',
  },
  {
    country_name: 'Australia',
    countryValue: 'AUSTRALIA',
  },
  {
    country_name: 'Austria',
    countryValue: 'AUSTRIA',
  },
  {
    country_name: 'Azerbaijan',
    countryValue: 'AZERBAIJAN',
  },
  {
    country_name: 'Bahamas',
    countryValue: 'BAHAMAS',
  },
  {
    country_name: 'Bahrain',
    countryValue: 'BAHRAIN',
  },
  {
    country_name: 'Bangladesh',
    countryValue: 'BANGLADESH',
  },
  {
    country_name: 'Barbados',
    countryValue: 'BARBADOS',
  },
  {
    country_name: 'Belarus',
    countryValue: 'BELARUS',
  },
  {
    country_name: 'Belgium',
    countryValue: 'BELGIUM',
  },
  {
    country_name: 'Belize',
    countryValue: 'BELIZE',
  },
  {
    country_name: 'Benin',
    countryValue: 'BENIN',
  },
  {
    country_name: 'Bhutan',
    countryValue: 'BHUTAN',
  },
  {
    country_name: 'Bolivia (Plurinational State of)',
    countryValue: 'BOLIVIA (PLURINATIONAL STATE OF)',
  },
  {
    country_name: 'Bosnia and Herzegovina',
    countryValue: 'BOSNIA AND HERZEGOVINA',
  },
  {
    country_name: 'Botswana',
    countryValue: 'BOTSWANA',
  },
  {
    country_name: 'Brazil',
    countryValue: 'BRAZIL',
  },
  {
    country_name: 'Brunei Darussalam',
    countryValue: 'BRUNEI DARUSSALAM',
  },
  {
    country_name: 'Bulgaria',
    countryValue: 'BULGARIA',
  },
  {
    country_name: 'Burkina Faso',
    countryValue: 'BURKINA FASO',
  },
  {
    country_name: 'Burundi',
    countryValue: 'BURUNDI',
  },
  {
    country_name: 'Cabo Verde',
    countryValue: 'CABO VERDE',
  },
  {
    country_name: 'Cambodia',
    countryValue: 'CAMBODIA',
  },
  {
    country_name: 'Cameroon',
    countryValue: 'CAMEROON',
  },
  {
    country_name: 'Canada',
    countryValue: 'CANADA',
  },
  {
    country_name: 'Central African Republic',
    countryValue: 'CENTRAL AFRICAN REPUBLIC',
  },
  {
    country_name: 'Chad',
    countryValue: 'CHAD',
  },
  {
    country_name: 'Chile',
    countryValue: 'CHILE',
  },
  {
    country_name: 'China',
    countryValue: 'CHINA',
  },
  {
    country_name: 'Colombia',
    countryValue: 'COLOMBIA',
  },
  {
    country_name: 'Comoros',
    countryValue: 'COMOROS',
  },
  {
    country_name: 'Congo',
    countryValue: 'CONGO',
  },
  {
    country_name: 'Cook Islands',
    countryValue: 'COOK ISLANDS',
  },
  {
    country_name: 'Costa Rica',
    countryValue: 'COSTA RICA',
  },
  {
    country_name: 'Cote dIvoire',
    countryValue: 'COTE DIVOIRE',
  },
  {
    country_name: 'Croatia',
    countryValue: 'CROATIA',
  },
  {
    country_name: 'Cuba',
    countryValue: 'CUBA',
  },
  {
    country_name: 'Cyprus',
    countryValue: 'CYPRUS',
  },
  {
    country_name: 'Czechia',
    countryValue: 'CZECHIA',
  },
  {
    country_name: 'Democratic Peoples Republic of Korea',
    countryValue: 'DEMOCRATIC PEOPLES REPUBLIC OF KOREA',
  },
  {
    country_name: 'Democratic Republic of the Congo',
    countryValue: 'DEMOCRATIC REPUBLIC OF THE CONGO',
  },
  {
    country_name: 'Denmark',
    countryValue: 'DENMARK',
  },
  {
    country_name: 'Djibouti',
    countryValue: 'DJIBOUTI',
  },
  {
    country_name: 'Dominica',
    countryValue: 'DOMINICA',
  },
  {
    country_name: 'Dominican Republic',
    countryValue: 'DOMINICAN REPUBLIC',
  },
  {
    country_name: 'Ecuador',
    countryValue: 'ECUADOR',
  },
  {
    country_name: 'Egypt',
    countryValue: 'EGYPT',
  },
  {
    country_name: 'El Salvador',
    countryValue: 'EL SALVADOR',
  },
  {
    country_name: 'Equatorial Guinea',
    countryValue: 'EQUATORIAL GUINEA',
  },
  {
    country_name: 'Eritrea',
    countryValue: 'ERITREA',
  },
  {
    country_name: 'Estonia',
    countryValue: 'ESTONIA',
  },
  {
    country_name: 'Eswatini',
    countryValue: 'ESWATINI',
  },
  {
    country_name: 'Ethiopia',
    countryValue: 'ETHIOPIA',
  },
  {
    country_name: 'Faroe Islands',
    countryValue: 'FAROE ISLANDS',
  },
  {
    country_name: 'Fiji',
    countryValue: 'FIJI',
  },
  {
    country_name: 'Finland',
    countryValue: 'FINLAND',
  },
  {
    country_name: 'France',
    countryValue: 'FRANCE',
  },
  {
    country_name: 'Gabon',
    countryValue: 'GABON',
  },
  {
    country_name: 'Gambia',
    countryValue: 'GAMBIA',
  },
  {
    country_name: 'Georgia',
    countryValue: 'GEORGIA',
  },
  {
    country_name: 'Germany',
    countryValue: 'GERMANY',
  },
  {
    country_name: 'Ghana',
    countryValue: 'GHANA',
  },
  {
    country_name: 'Greece',
    countryValue: 'GREECE',
  },
  {
    country_name: 'Grenada',
    countryValue: 'GRENADA',
  },
  {
    country_name: 'Guatemala',
    countryValue: 'GUATEMALA',
  },
  {
    country_name: 'Guinea',
    countryValue: 'GUINEA',
  },
  {
    country_name: 'Guinea-Bissau',
    countryValue: 'GUINEA-BISSAU',
  },
  {
    country_name: 'Guyana',
    countryValue: 'GUYANA',
  },
  {
    country_name: 'Haiti',
    countryValue: 'HAITI',
  },
  {
    country_name: 'Honduras',
    countryValue: 'HONDURAS',
  },
  {
    country_name: 'Hong kong',
    countryValue: 'HONG KONG',
  },
  {
    country_name: 'Hungary',
    countryValue: 'HUNGARY',
  },
  {
    country_name: 'Iceland',
    countryValue: 'ICELAND',
  },
  {
    country_name: 'India',
    countryValue: 'INDIA',
  },
  {
    country_name: 'Indonesia',
    countryValue: 'INDONESIA',
  },
  {
    country_name: 'Iran (Islamic Republic of)',
    countryValue: 'IRAN (ISLAMIC REPUBLIC OF)',
  },
  {
    country_name: 'Iraq',
    countryValue: 'IRAQ',
  },
  {
    country_name: 'Ireland',
    countryValue: 'IRELAND',
  },
  {
    country_name: 'Israel',
    countryValue: 'ISRAEL',
  },
  {
    country_name: 'Italy',
    countryValue: 'ITALY',
  },
  {
    country_name: 'Jamaica',
    countryValue: 'JAMAICA',
  },
  {
    country_name: 'Japan',
    countryValue: 'JAPAN',
  },
  {
    country_name: 'Jordan',
    countryValue: 'JORDAN',
  },
  {
    country_name: 'Kazakhstan',
    countryValue: 'KAZAKHSTAN',
  },
  {
    country_name: 'Kenya',
    countryValue: 'KENYA',
  },
  {
    country_name: 'Kiribati',
    countryValue: 'KIRIBATI',
  },
  {
    country_name: 'Kuwait',
    countryValue: 'KUWAIT',
  },
  {
    country_name: 'Kyrgyzstan',
    countryValue: 'KYRGYZSTAN',
  },
  {
    country_name: 'Lao Peoples Democratic Republic',
    countryValue: 'LAO PEOPLES DEMOCRATIC REPUBLIC',
  },
  {
    country_name: 'Latvia',
    countryValue: 'LATVIA',
  },
  {
    country_name: 'Lebanon',
    countryValue: 'LEBANON',
  },
  {
    country_name: 'Lesotho',
    countryValue: 'LESOTHO',
  },
  {
    country_name: 'Liberia',
    countryValue: 'LIBERIA',
  },
  {
    country_name: 'Libya',
    countryValue: 'LIBYA',
  },
  {
    country_name: 'Lithuania',
    countryValue: 'LITHUANIA',
  },
  {
    country_name: 'Luxembourg',
    countryValue: 'LUXEMBOURG',
  },
  {
    country_name: 'Madagascar',
    countryValue: 'MADAGASCAR',
  },
  {
    country_name: 'Malawi',
    countryValue: 'MALAWI',
  },
  {
    country_name: 'Malaysia',
    countryValue: 'MALAYSIA',
  },
  {
    country_name: 'Maldives',
    countryValue: 'MALDIVES',
  },
  {
    country_name: 'Mali',
    countryValue: 'MALI',
  },
  {
    country_name: 'Malta',
    countryValue: 'MALTA',
  },
  {
    country_name: 'Marshall Islands',
    countryValue: 'MARSHALL ISLANDS',
  },
  {
    country_name: 'Mauritania',
    countryValue: 'MAURITANIA',
  },
  {
    country_name: 'Mauritius',
    countryValue: 'MAURITIUS',
  },
  {
    country_name: 'Mexico',
    countryValue: 'MEXICO',
  },
  {
    country_name: 'Micronesia (Federated States of)',
    countryValue: 'MICRONESIA (FEDERATED STATES OF)',
  },
  {
    country_name: 'Monaco',
    countryValue: 'MONACO',
  },
  {
    country_name: 'Mongolia',
    countryValue: 'MONGOLIA',
  },
  {
    country_name: 'Montenegro',
    countryValue: 'MONTENEGRO',
  },
  {
    country_name: 'Morocco',
    countryValue: 'MOROCCO',
  },
  {
    country_name: 'Mozambique',
    countryValue: 'MOZAMBIQUE',
  },
  {
    country_name: 'Myanmar',
    countryValue: 'MYANMAR',
  },
  {
    country_name: 'Namibia',
    countryValue: 'NAMIBIA',
  },
  {
    country_name: 'Nauru',
    countryValue: 'NAURU',
  },
  {
    country_name: 'Nepal',
    countryValue: 'NEPAL',
  },
  {
    country_name: 'Netherlands',
    countryValue: 'NETHERLANDS',
  },
  {
    country_name: 'New Zealand',
    countryValue: 'NEW ZEALAND',
  },
  {
    country_name: 'Nicaragua',
    countryValue: 'NICARAGUA',
  },
  {
    country_name: 'Niger',
    countryValue: 'NIGER',
  },
  {
    country_name: 'Nigeria',
    countryValue: 'NIGERIA',
  },
  {
    country_name: 'Niue',
    countryValue: 'NIUE',
  },
  {
    country_name: 'North Macedonia',
    countryValue: 'NORTH MACEDONIA',
  },
  {
    country_name: 'Norway',
    countryValue: 'NORWAY',
  },
  {
    country_name: 'Oman',
    countryValue: 'OMAN',
  },
  {
    country_name: 'Pakistan',
    countryValue: 'PAKISTAN',
  },
  {
    country_name: 'Palau',
    countryValue: 'PALAU',
  },
  {
    country_name: 'Panama',
    countryValue: 'PANAMA',
  },
  {
    country_name: 'Papua New Guinea',
    countryValue: 'PAPUA NEW GUINEA',
  },
  {
    country_name: 'Paraguay',
    countryValue: 'PARAGUAY',
  },
  {
    country_name: 'Peru',
    countryValue: 'PERU',
  },
  {
    country_name: 'Philippines',
    countryValue: 'PHILIPPINES',
  },
  {
    country_name: 'Poland',
    countryValue: 'POLAND',
  },
  {
    country_name: 'Portugal',
    countryValue: 'PORTUGAL',
  },
  {
    country_name: 'Qatar',
    countryValue: 'QATAR',
  },
  {
    country_name: 'Republic of Korea',
    countryValue: 'REPUBLIC OF KOREA',
  },
  {
    country_name: 'Republic of Moldova',
    countryValue: 'REPUBLIC OF MOLDOVA',
  },
  {
    country_name: 'Romania',
    countryValue: 'ROMANIA',
  },
  {
    country_name: 'Russian Federation',
    countryValue: 'RUSSIAN FEDERATION',
  },
  {
    country_name: 'Rwanda',
    countryValue: 'RWANDA',
  },
  {
    country_name: 'Saint Kitts and Nevis',
    countryValue: 'SAINT KITTS AND NEVIS',
  },
  {
    country_name: 'Saint Lucia',
    countryValue: 'SAINT LUCIA',
  },
  {
    country_name: 'Saint Vincent and the Grenadines',
    countryValue: 'SAINT VINCENT AND THE GRENADINES',
  },
  {
    country_name: 'Samoa',
    countryValue: 'SAMOA',
  },
  {
    country_name: 'San Marino',
    countryValue: 'SAN MARINO',
  },
  {
    country_name: 'Sao Tome and Principe',
    countryValue: 'SAO TOME AND PRINCIPE',
  },
  {
    country_name: 'Saudi Arabia',
    countryValue: 'SAUDI ARABIA',
  },
  {
    country_name: 'Senegal',
    countryValue: 'SENEGAL',
  },
  {
    country_name: 'Serbia',
    countryValue: 'SERBIA',
  },
  {
    country_name: 'Seychelles',
    countryValue: 'SEYCHELLES',
  },
  {
    country_name: 'Sierra Leone',
    countryValue: 'SIERRA LEONE',
  },
  {
    country_name: 'Singapore',
    countryValue: 'SINGAPORE',
  },
  {
    country_name: 'Slovakia',
    countryValue: 'SLOVAKIA',
  },
  {
    country_name: 'Slovenia',
    countryValue: 'SLOVENIA',
  },
  {
    country_name: 'Solomon Islands',
    countryValue: 'SOLOMON ISLANDS',
  },
  {
    country_name: 'Somalia',
    countryValue: 'SOMALIA',
  },
  {
    country_name: 'South Africa',
    countryValue: 'SOUTH AFRICA',
  },
  {
    country_name: 'South Sudan',
    countryValue: 'SOUTH SUDAN',
  },
  {
    country_name: 'Spain',
    countryValue: 'SPAIN',
  },
  {
    country_name: 'Sri Lanka',
    countryValue: 'SRI LANKA',
  },
  {
    country_name: 'Sudan',
    countryValue: 'SUDAN',
  },
  {
    country_name: 'Suriname',
    countryValue: 'SURINAME',
  },
  {
    country_name: 'Sweden',
    countryValue: 'SWEDEN',
  },
  {
    country_name: 'Switzerland',
    countryValue: 'SWITZERLAND',
  },
  {
    country_name: 'Syrian Arab Republic',
    countryValue: 'SYRIAN ARAB REPUBLIC',
  },
  {
    country_name: 'Tajikistan',
    countryValue: 'TAJIKISTAN',
  },
  {
    country_name: 'Thailand',
    countryValue: 'THAILAND',
  },
  {
    country_name: 'Timor-Leste',
    countryValue: 'TIMOR-LESTE',
  },
  {
    country_name: 'Togo',
    countryValue: 'TOGO',
  },
  {
    country_name: 'Tokelau',
    countryValue: 'TOKELAU',
  },
  {
    country_name: 'Tonga',
    countryValue: 'TONGA',
  },
  {
    country_name: 'Trinidad and Tobago',
    countryValue: 'TRINIDAD AND TOBAGO',
  },
  {
    country_name: 'Tunisia',
    countryValue: 'TUNISIA',
  },
  {
    country_name: 'Turkey',
    countryValue: 'TURKEY',
  },
  {
    country_name: 'Turkmenistan',
    countryValue: 'TURKMENISTAN',
  },
  {
    country_name: 'Tuvalu',
    countryValue: 'TUVALU',
  },
  {
    country_name: 'Uganda',
    countryValue: 'UGANDA',
  },
  {
    country_name: 'Ukraine',
    countryValue: 'UKRAINE',
  },
  {
    country_name: 'United Arab Emirates',
    countryValue: 'UNITED ARAB EMIRATES',
  },
  {
    country_name: 'United Kingdom of Great Britain and Northern Ireland',
    countryValue: 'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
  },
  {
    country_name: 'United Republic of Tanzania',
    countryValue: 'UNITED REPUBLIC OF TANZANIA',
  },
  {
    country_name: 'Uruguay',
    countryValue: 'URUGUAY',
  },
  {
    country_name: 'Usa',
    countryValue: 'USA',
  },
  {
    country_name: 'Uzbekistan',
    countryValue: 'UZBEKISTAN',
  },
  {
    country_name: 'Vanuatu',
    countryValue: 'VANUATU',
  },
  {
    country_name: 'Venezuela (Bolivarian Republic of)',
    countryValue: 'VENEZUELA (BOLIVARIAN REPUBLIC OF)',
  },
  {
    country_name: 'Viet Nam',
    countryValue: 'VIET NAM',
  },
  {
    country_name: 'Yemen',
    countryValue: 'YEMEN',
  },
  {
    country_name: 'Zambia',
    countryValue: 'ZAMBIA',
  },
  {
    country_name: 'Zimbabwe',
    countryValue: 'ZIMBABWE',
  },
];
export type AssetKey = keyof typeof ASSETS_TITLES;

export const ASSETS_TITLES = {
  MFS: 'Pooled investments for diversification and proffesional management.',
  NPS: ' Professional management for personalized investment portfolios.',
};
export const DASHBOARD_COLORS = ['#C5FCE2', '#C6A1FC', '#FFE089', '#A7E5FE'];
