import { Box, CardMedia, Divider, Grid, Stack, Theme, Typography } from '@mui/material';
import { format, isValid, parseISO } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import AmountIcon from '../../../assets/amount-coin.svg';
import DatePickerIcon from '../../../assets/date-picker.svg';
import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import {
  FieldSubHeader3,
  Investorcard,
  MainHeader,
  StepperCancelButton,
} from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import MobileInvestorProfile from '../../common/UI/MobileInvestorProfile';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormCheckbox from '../../forms/FormCheckbox';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { StepperNextButton } from '../../onboarding/styles/styledComponents';
import SubmitCartButtons from '../common/SubmitCartButtons';
import EuinsFields from '../euinsComponent';
import Layout from '../Layout';
import { familyDescriptionOptions } from '../mfs/constants';
import {
  useAllPaymentModesMutation,
  useInvestorDetailsMutation,
} from '../mfs/newInvestorOnboarding/slice';
import { mapFrequency, mapFrequencyToLetter } from '../mfs/newInvestorOnboarding/utils';
import {
  useAddItemToCartMutation,
  useGetEnachAotmDetailsMutation,
  // useGetEUINSQuery,
  useGetSchemaDetailsMutation,
  useGetSipStartandEndDatesMutation,
} from '../slice';
import { handlePerpetualSipBoxChnage } from '../utils';
import {
  setCartState,
  setFatcaDetails,
  setHolderDetails,
  setInvestmentDetails,
  setInvestorBankDetails,
  setInvestorDetails,
  setInvestorDetailsFromResponse,
  setIsNomination,
  setNomineeDetails,
  setStepNumber,
} from './context/MfsNewPurchaseAction';
import { useMfsNewPurchaseContext } from './context/MfsNewPurchaseContext';

interface Option {
  value: any;
  label: any;
}

export interface InvestmentDetails {
  arnCode: string;
  subArnCode: string;
  subBrokerCode: string;
  euin: string;
  emailFamilyFlag: string;
  emailId: string;
  mobileFamilyFlag: string;
  phoneNo: string;
  invType: string;
  minorDob: Date | null;
  invCategory: string;
  modeOfTransaction: string;
  investmentAmount: string;
  modeOfRegistration: string;
  frequency: string;
  sipDay: string;
  numberOfInstalments: string;
  startDate: Date | null;
  endDate: Date | null;
  lumpsumAmount: string;
  perpetual: boolean;
}

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const currentDate = new Date().getDate();
const minYear = currentYear - 18;
const minDate = new Date(minYear, currentMonth, currentDate);

//TODO: Add Type of investor

const RightBottomComp = ({ schemaData }: any) => {
  return (
    <Box
      sx={{
        padding: '30px 20px',
        borderTop: '1px solid',
        borderColor: 'text.borderColorDark',
      }}
    >
      <Stack
        direction='column'
        gap='16px'
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            sx={{
              color: 'primary.main',
              fontWeight: 500,
              fontSize: { xs: '16px', xl: '18px' },
            }}
          >
            Purchase Scheme
          </Typography>
          <CardMedia
            sx={{
              '&.MuiCardMedia-root': {
                width: '12%',
              },
            }}
            component='img'
            image={AmountIcon}
            alt='amount'
          />
        </Stack>
        {schemaData?.schemeDesc && (
          <Typography
            variant='subtitle2'
            sx={{ color: 'text.valueColor' }}
          >
            {`${schemaData?.schemeDesc} - ${schemaData?.planMode} - ${schemaData?.optionDesc}` ||
              'NA'}
          </Typography>
        )}
        {schemaData?.nav && (
          <>
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Current Nav</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                ₹{schemaData?.nav || 'NA'}
              </Typography>
            </Stack>
            <Stack
              gap='5px'
              direction='row'
              alignItems='center'
            >
              <img
                src={DatePickerIcon}
                alt='date-picker'
              />
              <Typography
                sx={{
                  color: 'text.labelColor3',
                  fontSize: '12px',
                  fontWeight: '500',
                }}
              >
                As on Date
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
};

export const areAllTheValuesEmpty = (values: any) =>
  !(values.sipDay && values.numberOfInstalments && values.frequency);

export const formatDate = (dateValue: any, defaultFormat = 'yyyy/MM/dd'): string => {
  if (!dateValue) return '';
  let date: Date;
  if (typeof dateValue === 'string') {
    if (dateValue?.includes('T')) {
      date = parseISO(dateValue);
    } else {
      date = new Date(dateValue);
    }
  } else if (dateValue instanceof Date) {
    date = dateValue;
  } else {
    return '';
  }
  if (!isValid(date)) {
    return '';
  }
  return format(date, defaultFormat);
};

const NewInvestmentDetails: React.FC = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [schemaData, setSchemaData] = useState<any>({});
  const [cartResponse, setCartResponse] = useState<any>({});
  const { showToast } = useRootContext();
  const [sipFrequencyOption, setSipFrequencyOptions] = useState<Option[]>([]);
  const [sipDays, setSipDays] = useState<Option[]>([]);
  const [paymentModeOptions, setPaymnetModeOptions] = useState<any[]>([]);
  const [fieldType, setFieldType] = useState('');
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openUniqueEmailDialogBox, setOpenUniqueEmailDialogBox] = useState(false);
  const [isPanBasedAotm, setIsPanBasedAotm] = useState(false);
  const [isInvalidScheme, setIsInvalidScheme] = useState(false);
  const [selectedFrequencyAndSip, setSelectedFrequencyAndSip] = useState<{
    selectedFrequency: any;
    selectedSipDay: any;
    noOfInstallments: number;
    isPerpetual: boolean;
  }>({
    selectedFrequency: null,
    selectedSipDay: null,
    noOfInstallments: 0,
    isPerpetual: false,
  });
  const [getAllPaymentModes] = useAllPaymentModesMutation();
  const [getAotmDetails] = useGetEnachAotmDetailsMutation();
  const formikRef = useRef<FormikProps<InvestmentDetails>>(null);

  const {
    arn,
    euin,
    subArn,
    subbrokerCode,
    mobile: distributorMobile,
    email: distributorEmail,
  } = useSelector((state: { login: LoginResponse }) => state.login);
  const {
    pan: invPan,
    email,
    mobile,
    name,
  } = useSelector((state: { transactions: TransactionResponse }) => state.transactions);

  const distMobile = distributorMobile?.split('+91')?.[1];

  const cartItem = state?.cartItem?.payload;
  const refactoredPayload = cartItem && JSON.parse(cartItem);
  const isFormCart = state?.isFormCart;

  const {
    dispatch: contextDispatch,
    state: { schemeDetails, fundName, investmentDetails },
  } = useMfsNewPurchaseContext();
  const [getSipStartandEndDates] = useGetSipStartandEndDatesMutation();

  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const [addItemToTheCart] = useAddItemToCartMutation();
  // const { data: allEUINs } = useGetEUINSQuery([]);
  const [getData] = useInvestorDetailsMutation();

  const initialValues: InvestmentDetails = {
    arnCode: investmentDetails?.arnCode || arn || 'NA',
    subArnCode: investmentDetails?.subArnCode || subArn || '',
    subBrokerCode: isFormCart
      ? refactoredPayload?.subBroker
      : investmentDetails?.subBrokerCode || subbrokerCode || '',
    euin: isFormCart ? refactoredPayload?.euin : investmentDetails?.euin || euin || '',
    emailFamilyFlag: isFormCart ? refactoredPayload?.familyEmailFlag : 'SE',
    emailId: isFormCart ? refactoredPayload?.email : email || '',
    mobileFamilyFlag: isFormCart ? refactoredPayload?.familyMobileFlag : 'SE',
    phoneNo: isFormCart ? refactoredPayload?.mobile : mobile || '',
    invType: isFormCart ? state?.cartItem?.invType : investmentDetails?.invType || 'I',
    invCategory: isFormCart ? state?.cartItem?.invCategory : '11',
    minorDob: isFormCart ? state?.cartItem?.minorDob : null,
    modeOfTransaction: isFormCart
      ? refactoredPayload?.transactionType === 'NPL'
        ? 'Lumpsum'
        : refactoredPayload?.transactionType === 'SINI'
        ? refactoredPayload?.amount !== 0 && refactoredPayload?.installmentAmount !== 0
          ? 'Lumpsum + SIP'
          : 'SIP'
        : investmentDetails?.modeOfTransaction || ''
      : '',
    investmentAmount: investmentDetails?.investmentAmount || '',
    frequency: investmentDetails?.frequency || '',
    sipDay: investmentDetails?.sipDay || '',
    numberOfInstalments: investmentDetails?.numberOfInstalments || '',
    startDate: investmentDetails?.startDate || null,
    endDate: investmentDetails?.endDate || null,
    perpetual: investmentDetails?.perpetual || '',
    lumpsumAmount: investmentDetails?.lumpsumAmount || '',
    modeOfRegistration: isFormCart
      ? refactoredPayload?.modeOfRegistration
      : investmentDetails?.lumpsumAmount || '',
  };

  useEffect(() => {
    const getPaymentOptions = async () => {
      const payload = {
        fund: isFormCart ? refactoredPayload?.fund : schemeDetails?.fund,
      };
      const response = await getAllPaymentModes(payload).unwrap();
      const options = Object.entries(response)
        .map(([key, value]) => {
          if (value === 'Y') {
            return { label: key?.toUpperCase(), value: key?.toUpperCase() };
          }
          return null;
        })
        .filter(Boolean);
      setPaymnetModeOptions(options);
    };
    getPaymentOptions();
  }, []);
  useEffect(() => {
    const getAotmStatus = async () => {
      try {
        const response: any = await getAotmDetails({
          fund: isFormCart ? refactoredPayload?.fund : schemeDetails?.fund,
        }).unwrap();

        const { enach_aotm_basedon } = response;
        setIsPanBasedAotm(enach_aotm_basedon.toUpperCase() === 'PAN');
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    };
    getAotmStatus();
  }, []);

  const validationSchema = Yup.object().shape({
    modeOfTransaction: Yup.string().required('Mode of Transaction is required'),
    arnCode: Yup.string().required('ARN code is required'),
    subArnCode: Yup.string().test(
      'subArnCode',
      'Sub ARN code cannot be the same as ARN code',
      function (value) {
        const { arnCode } = this.parent;
        return value !== arnCode;
      },
    ),
    emailFamilyFlag: Yup.string().required('Family email flag is required'),
    emailId: Yup.string()
      .required('Email Id is required')
      .matches(
        /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'email must be a valid email',
      ),
    mobileFamilyFlag: Yup.string().required('Family mobile flag is required'),
    phoneNo: Yup.string()
      .required('Mobile Number is required')
      .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
    invCategory: Yup.string().required('Investor category is required'),
    subBrokerCode: Yup.string().matches(
      /^[a-zA-Z0-9]+$/,
      'Sub broker code must contain only alphabets and numbers',
    ),
    investmentAmount: Yup.string()
      .required('Investment Amount is required')
      .min(1, 'Minimum investment amount should not be 0')
      .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
    frequency: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string().required('SIP frequency is required'),
    }),
    // invType: Yup.string().when('modeOfTransaction', {
    //   is: (modeOfTransaction: string) =>
    //     modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
    //   then: Yup.string().required('Investor type is required'),
    // }),
    modeOfRegistration: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string().required('Mode of registration is required'),
    }),
    // minorDob: Yup.date()
    //   .typeError('Invalid Date')
    //   .when(['invType', 'modeOfTransaction'], {
    //     is: (invType: string, modeOfTransaction: string) =>
    //       (modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP') &&
    //       invType === 'M',
    //     then: Yup.date().typeError('Invalid Date').required('Minor DOB is required'),
    //     otherwise: Yup.date().typeError('Invalid Date').nullable(),
    //   }),
    sipDay: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string().required('SIP Day is required'),
    }),
    numberOfInstalments: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string()
        .required('Number of Installment is required')
        .matches(/^[0-9]+$/, 'Number of installments must contain only numbers'),
    }),
    startDate: Yup.date()
      .typeError('Invalid Date')
      .nullable()
      .when('modeOfTransaction', {
        is: (modeOfTransaction: string) =>
          modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
        then: Yup.date().typeError('Invalid Date').required('Start date is required'),
      }),
    endDate: Yup.date()
      .typeError('Invalid Date')
      .nullable()
      .when('modeOfTransaction', {
        is: (modeOfTransaction: string) =>
          modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
        then: Yup.date().typeError('Invalid Date').required('End date is required'),
      }),
    lumpsumAmount: Yup.string().when('modeOfTransaction', {
      is: 'Lumpsum + SIP',
      then: Yup.string()
        .required('Lumpsum Amount is required')
        .min(1, 'Minimum investment amount should not be 0')
        .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
    }),
  });

  const handleAddAnotherTransaction = () => {
    setOpenDialogBox(false);
    navigate('../', {
      state: { cartItem: cartResponse?.cartItems, isFormCart: true },
      replace: true,
    });
  };

  const handleModeOfTransactionChange = (
    setFieldValue: (field: string, value: any) => void,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    setFieldError('investmentAmount', '');
    setFieldError('frequency', '');
    setFieldError('sipDay', '');
    setFieldError('numberOfInstalments', '');
    setFieldError('startDate', '');
    setFieldError('endDate', '');
    setFieldError('lumpsumAmount', '');

    setFieldValue('investmentAmount', '');
    setFieldValue('frequency', '');
    setFieldValue('sipDay', '');
    setFieldValue('numberOfInstalments', '');
    setFieldValue('startDate', null);
    setFieldValue('endDate', null);
    setFieldValue('lumpsumAmount', '');
  };

  const handleAddToCartClick = async (values: InvestmentDetails) => {
    try {
      dispatch(startLoading());
      const invResponse = await getData({ pan: invPan }).unwrap();
      const payload = {
        amount:
          values.modeOfTransaction === 'Lumpsum'
            ? Number(values.investmentAmount)
            : Number(values.lumpsumAmount),
        pan: invPan?.toUpperCase(),
        email: values.emailId || email,
        mobile: values.phoneNo || mobile || invResponse?.personalDetails?.mobile,
        scheme: schemeDetails?.scheme,
        plan: schemeDetails?.plan,
        option: schemeDetails?.option,
        fund: schemeDetails?.fund,
        schemeName: `${schemeDetails?.schemeDesc} - ${schemeDetails?.planMode} - ${schemeDetails?.optionDesc}`,
        planMode: schemeDetails?.planMode,
        optionDesc: schemeDetails?.optionDesc,
        invCategory: values.invCategory,
        investorName: name,
        familyEmailFlag: values.emailFamilyFlag || 'SE',
        familyMobileFlag: values.mobileFamilyFlag || 'SE',
        // subBroker: '',
        category: schemeDetails?.category,
        fundName: fundName,
        euin: values.euin,
        euinDeclaration: values.euin !== '' ? 'N' : 'Y',
        combo: false,
        newPurchaseSipType: '',
        transactionType: values.modeOfTransaction === 'Lumpsum' ? 'NPL' : 'SINI',
        ...(values.modeOfTransaction !== 'Lumpsum' && {
          noOfInstallments: Number(values.numberOfInstalments),
          remarks: '0',
          sipDay: values?.sipDay,
          sipEndDate: formatDate(values.endDate, 'MM/dd/yyyy'),
          sipFrequency: mapFrequencyToLetter(values?.frequency),
          sipStartDate: formatDate(values.startDate, 'MM/dd/yyyy'),
          installmentAmount: Number(values.investmentAmount),
          modeOfRegistration: values.modeOfRegistration?.toUpperCase(),
          perpetual: !values.perpetual ? '' : values.perpetual,
          investorStatus: values.invType,
          investorDob: formatDate(
            !values.minorDob ? new Date() : values.minorDob,
            'MM/dd/yyyy',
          ),

          isMinor: values.invType === 'M',
        }),
      };
      const res = await addItemToTheCart(payload).unwrap();
      setCartResponse(res);
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleAddingItemInCart = (values: any) => {
    contextDispatch(setInvestmentDetails(values));
    setOpenDialogBox(false);
    navigate('/transaction-dashboard/cart');
  };

  const handleSubmit = async (values: InvestmentDetails) => {
    const invResponse = await getData({ pan: invPan }).unwrap();
    contextDispatch(setInvestorDetailsFromResponse(invResponse));
    // contextDispatch(setSipDates({ values.startDate, endDate }));
    contextDispatch(setInvestmentDetails(values));
    contextDispatch(setStepNumber(0));
    navigate('../investor-details');
  };

  const validateMinInvestmentAmount = (values: InvestmentDetails) => {
    const errors: Record<string, any> = {};
    const minAmount = schemaData?.minTransactionAmount;

    const selectedFrequency = schemaData?.frequency?.find(
      (item: any) => mapFrequency(item.mode?.toUpperCase()) === values.frequency,
    );
    const minInstallments = selectedFrequency?.minInstallments || '';
    const minSipAmount = selectedFrequency?.minTransactionAmount;

    if (Number(values.investmentAmount) < minAmount) {
      errors.investmentAmount = `Minimum Investment Amount should be ${minAmount}`;
    }
    if (Number(values.investmentAmount) < minSipAmount) {
      errors.investmentAmount = `Minimum Investment Amount should be ${minSipAmount}`;
    }
    if (
      values.modeOfTransaction === 'Lumpsum + SIP' &&
      Number(values.lumpsumAmount) < minSipAmount
    ) {
      errors.lumpsumAmount = `Minimum Lumpsum Amount should be ${minSipAmount}`;
    }
    if (Number(values.numberOfInstalments) < minInstallments) {
      errors.numberOfInstalments = `Number of installments should be ${minInstallments}`;
    }
    return errors;
  };

  const handleSipFrequencyChange = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    setFieldValue('frequency', e.target.value);
    setSelectedFrequencyAndSip({
      ...selectedFrequencyAndSip,
      selectedFrequency: e.target.value,
      noOfInstallments: 0,
    });
    const sipCycle = schemaData?.frequency?.find(
      (item: any) => mapFrequency(item.mode.toUpperCase()) === e.target.value,
    );
    if (sipCycle) {
      const cycleArray = sipCycle.cycle?.split(',') || [];
      let sipDays = [];
      const isNumericSequence = cycleArray.every(
        (day: { trim: () => number }) => !isNaN(day.trim()),
      );
      if (!isNumericSequence) {
        sipDays = Array.from({ length: 28 }, (_, i) => {
          const day = (i + 1).toString();
          return {
            label: day,
            value: day,
          };
        });
      } else {
        sipDays =
          cycleArray?.map((day: string) => {
            const dayWithoutLeadingZero = parseInt(day, 10).toString();
            return {
              label: dayWithoutLeadingZero,
              value: dayWithoutLeadingZero,
            };
          }) || [];
      }
      setSipDays(sipDays);
    }
  };

  const handleModeOfRegistrationChange = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
    values: InvestmentDetails,
  ) => {
    setFieldValue('modeOfRegistration', e?.target?.value);

    if (values.modeOfRegistration !== '') {
      setFieldValue('frequency', '');
      setFieldValue('sipDay', '');
      setFieldValue('numberOfInstalments', '');
      setFieldValue('startDate', null);
      setFieldValue('endDate', null);
      setFieldValue('perpetual', false);
    }
  };

  useEffect(() => {
    if (isFormCart) {
      const getSchema = async () => {
        try {
          const payload = {
            fund: schemeDetails?.fund,
            scheme: schemeDetails?.scheme,
            plan: schemeDetails?.plan,
            option: schemeDetails?.option,
            trType: refactoredPayload?.transactionType === 'NPL' ? 'NPL' : 'SIP',
          };
          const result: any = await getSchemaDetails(payload).unwrap();
          setSchemaData(result);
          if (result?.frequency) {
            const uniqueModes = result?.frequency
              .map((option: { mode: any }) => option.mode)
              .filter(
                (mode: any, index: any, self: string | any[]) =>
                  self.indexOf(mode) === index,
              );
            const sipFrequency = uniqueModes.map((mode: any) => ({
              label: mapFrequency(mode.toUpperCase()),
              value: mapFrequency(mode.toUpperCase()),
            }));
            setSipFrequencyOptions(sipFrequency);
          }

          const sipCycle = result?.frequency?.find(
            (item: any) => item.mode === refactoredPayload?.sipFrequency,
          );
          if (sipCycle) {
            const cycleArray = sipCycle.cycle?.split(',') || [];
            let sipDays = [];
            const isNumericSequence = cycleArray.every(
              (day: { trim: () => number }) => !isNaN(day.trim()),
            );
            if (!isNumericSequence) {
              sipDays = Array.from({ length: 28 }, (_, i) => {
                const day = (i + 1).toString();
                return {
                  label: day,
                  value: day,
                };
              });
            } else {
              sipDays =
                cycleArray?.map((day: string) => {
                  const dayWithoutLeadingZero = parseInt(day, 10).toString();
                  return {
                    label: dayWithoutLeadingZero,
                    value: dayWithoutLeadingZero,
                  };
                }) || [];
            }
            setSipDays(sipDays);
          }
        } catch (error: any) {
          showToast(
            (error as { message: string })?.message || error?.data?.message,
            'error',
          );
        }
      };
      setSelectedFrequencyAndSip({
        ...selectedFrequencyAndSip,
        selectedFrequency: mapFrequency(refactoredPayload?.sipFrequency), //check
        selectedSipDay: refactoredPayload?.sipDay,
        noOfInstallments: refactoredPayload?.noOfInstallments,
      });
      getSchema();
    }
  }, []);

  useEffect(() => {
    contextDispatch(setInvestorDetails(null));
    contextDispatch(setHolderDetails(null));
    contextDispatch(setFatcaDetails(null));
    contextDispatch(setIsNomination(false));
    contextDispatch(setNomineeDetails(null));
    contextDispatch(setInvestorBankDetails(null));
    contextDispatch(setCartState(null));
  }, []);

  const handleSipDayChange = async (
    e: any,
    values: InvestmentDetails,
    setFieldValue: any,
  ) => {
    try {
      dispatch(startLoading());
      const dayValue = e.target.value;
      const payload = {
        tenant: 'IRIS',
        trtype: 'SINI',
        folio: 0,
        fund: schemeDetails?.fund,
        scheme: schemeDetails?.scheme,
        investorStatus: values.invType,
        investorDob:
          values.invType === 'I' ? '' : formatDate(values.minorDob as Date, 'yyyy/MM/dd'),
        modeofregistration: values.modeOfRegistration,
        frequency: values.frequency,
        installments: Number(values.numberOfInstalments),
        day: Number(dayValue),
      };
      const response = await getSipStartandEndDates(payload).unwrap();
      if (response) {
        setFieldValue('startDate', response?.startDate);
        setFieldValue('endDate', response?.endDate);
      } else {
        setFieldValue('sipDay', '');
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
      setFieldValue('sipDay', '');
    } finally {
      dispatch(stopLoading());
    }
  };

  return (
    <>
      <Layout
        title='Investment Details'
        subtitle='Please enter the investment details'
        rightBottomComponent={<RightBottomComp schemaData={schemaData} />}
      >
        <MobileInvestorProfile
          commercialTransactionTitle='New Purchase'
          data={schemaData}
        />
        <Typography
          sx={{
            color: 'primary.main',
            mb: 2,
            display: { xs: 'block', sm: 'none' },
            fontSize: { xs: '14px', sm: '16px', xl: '18px' },
          }}
        >
          Please enter the investment details
        </Typography>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            sm={12}
          >
            <Investorcard
              sx={(theme: Theme) => ({
                padding: '30px 30px',
                position: 'relative',
                [theme.breakpoints.down('sm')]: {
                  boxShadow: 'unset',
                  border: 'unset',
                  padding: '0px',
                },
              })}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                validate={validateMinInvestmentAmount}
                innerRef={formikRef}
              >
                {({ values, errors, setFieldValue, setFieldError, setValues }) => {
                  const selectedFrequency = schemaData?.frequency?.find(
                    (item: any) =>
                      mapFrequency(item.mode?.toUpperCase()) === values.frequency,
                  );
                  const minNoOfInstallments = selectedFrequency?.minInstallments || '';
                  const sipMinAmount = selectedFrequency?.minTransactionAmount || '';
                  return (
                    <Form>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '14px',
                            sm: '16px',
                            xl: '18px',
                          },
                          fontWeight: 500,
                          color: { xs: 'text.valueColor', sm: 'primary.main' },
                          mb: 2,
                        }}
                      >
                        Distributor Details
                      </Typography>
                      <Grid
                        container
                        spacing={2}
                        sx={{ mb: 2 }}
                      >
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormTextInput
                            name='arnCode'
                            label='ARN Code'
                            disabled
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormTextInput
                            name='subArnCode'
                            label='Sub ARN Code'
                            required={false}
                            disabled //TODO: Need to change subArn and subBroker disabled condtion based on user login.
                            typeOfInput='alphanumeric'
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormTextInput
                            name='subBrokerCode'
                            label='Sub Broker Code'
                            required={false}
                            disabled
                            typeOfInput='alphanumeric'
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                        >
                          <EuinsFields
                            name='euin'
                            // allEUINs={allEUINs}
                            // defaultValue={isFormCart ? refactoredPayload?.euin : euin}
                            disabled={isFormCart}
                            initialValue={
                              isFormCart
                                ? refactoredPayload?.euinDeclaration == 'Y'
                                  ? 'no'
                                  : 'yes'
                                : 'yes'
                            }
                            defaultValue={values?.euin}
                          />
                        </Grid>
                      </Grid>
                      <Divider
                        orientation='horizontal'
                        variant='fullWidth'
                        flexItem
                        sx={{
                          border: '1px dashed',
                          borderColor: 'text.borderColorDark',
                          my: 3,
                        }}
                      />
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormTextInput
                            name='emailId'
                            label='Email ID'
                            required
                            disabled={isFormCart && refactoredPayload?.email !== ''}
                            onChange={(e: any) => {
                              setFieldType('email');
                              if (e.target.value?.trim() === distributorEmail?.trim()) {
                                setOpenUniqueEmailDialogBox(true);
                                setFieldValue('emailId', '');
                              }
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormSelectInput
                            name='emailFamilyFlag'
                            label='Email Family Description'
                            defaultValue={''}
                            required
                            options={familyDescriptionOptions}
                            disabled={
                              isFormCart && refactoredPayload?.familyEmailFlag !== ''
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormTextInput
                            name='phoneNo'
                            label='Mobile No'
                            required
                            type='number'
                            disabled={isFormCart && refactoredPayload?.mobile !== ''}
                            onChange={(e: any) => {
                              setFieldType('mobile');
                              if (e.target.value?.trim() === distMobile?.trim()) {
                                setOpenUniqueEmailDialogBox(true);
                                setFieldValue('phoneNo', '');
                              }
                            }}
                            onInput={(e: any) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormSelectInput
                            name='mobileFamilyFlag'
                            label='Mobile Family Description'
                            defaultValue={''}
                            required
                            options={familyDescriptionOptions}
                            disabled={
                              isFormCart && refactoredPayload?.familyMobileFlag !== ''
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                                xl: '18px',
                              },
                              fontWeight: 500,
                              color: 'text.valueColor',
                              mb: 2,
                            }}
                          >
                            Investor Category *
                          </Typography>
                          <FormStyledRadioButton
                            options={[
                              { label: 'Resident of India', value: '11' },
                              { label: 'NRI', value: '20' },
                            ]}
                            name='invCategory'
                            disabled={isFormCart && refactoredPayload?.invCategory !== ''}
                          />
                        </Grid>
                      </Grid>
                      <Divider
                        orientation='horizontal'
                        variant='fullWidth'
                        flexItem
                        sx={{
                          border: '1px dashed',
                          borderColor: 'text.borderColorDark',
                          my: 3,
                        }}
                      />
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                                xl: '18px',
                              },
                              fontWeight: 500,
                              color: 'text.valueColor',
                              mb: 2,
                            }}
                          >
                            Mode of Transaction *
                          </Typography>
                          <FormStyledRadioButton
                            options={[
                              { label: 'Lumpsum', value: 'Lumpsum' },
                              { label: 'SIP', value: 'SIP' },
                              { label: 'Lumpsum + SIP', value: 'Lumpsum + SIP' },
                            ]}
                            name='modeOfTransaction'
                            disabled={
                              isFormCart && refactoredPayload?.transactionType !== ''
                            }
                            handleChange={async (e: any) => {
                              try {
                                dispatch(startLoading());
                                const payload = {
                                  fund: schemeDetails?.fund,
                                  scheme: schemeDetails?.scheme,
                                  plan: schemeDetails?.plan,
                                  option: schemeDetails?.option,
                                  trType: e.target.value === 'Lumpsum' ? 'NPL' : 'SIP',
                                };
                                const result: any = await getSchemaDetails(
                                  payload,
                                ).unwrap();
                                setSchemaData(result);
                                if (result?.frequency?.length === 0) {
                                  showToast(
                                    'Scheme is not allowed for transaction',
                                    'error',
                                  );
                                }
                                if (result?.frequency) {
                                  const uniqueModes = result.frequency
                                    .map((option: { mode: any }) => option.mode)
                                    .filter(
                                      (mode: any, index: any, self: string | any[]) =>
                                        self.indexOf(mode) === index,
                                    );
                                  const sipFrequency = uniqueModes.map((mode: any) => ({
                                    label: mapFrequency(mode.toUpperCase()),
                                    value: mapFrequency(mode.toUpperCase()),
                                  }));
                                  setSipFrequencyOptions(sipFrequency);
                                }
                              } catch (error: any) {
                                setIsInvalidScheme(true);
                                showToast(
                                  (error as { message: string })?.message ||
                                    error?.data?.message ||
                                    'Unknown error',
                                  'error',
                                );
                              } finally {
                                dispatch(stopLoading());
                              }
                              handleModeOfTransactionChange(setFieldValue, setFieldError);
                            }}
                          />
                        </Grid>
                        {values?.modeOfTransaction !== '' &&
                          values?.modeOfTransaction !== 'Lumpsum' && (
                            <>
                              {/* {TODO: add type of investor once got clarity} */}
                              {/* <Grid
                                item
                                xs={12}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: '14px',
                                      xl: '18px',
                                    },
                                    fontWeight: 500,
                                    color: 'text.valueColor',
                                    mb: 2,
                                  }}
                                >
                                  Type of Investor *
                                </Typography>
                                <FormStyledRadioButton
                                  options={[
                                    { label: 'Individual', value: 'I' },
                                    { label: 'Minor', value: 'M' },
                                  ]}
                                  name='invType'
                                  disabled={
                                    isFormCart && refactoredPayload?.invType !== ''
                                  }
                                />
                              </Grid>
                              {values.invType === 'M' && (
                                <Grid
                                  item
                                  sm={6}
                                  xs={12}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        xs: '14px',
                                        xl: '18px',
                                      },
                                      fontWeight: 500,
                                      color: 'text.valueColor',
                                    }}
                                  >
                                    Minor Date of Birth *
                                  </Typography>
                                  <FormDateInput
                                    name='minorDob'
                                    label='Minor DOB'
                                    required={true}
                                    disabled
                                    minDate={minDate}
                                    disableFuture
                                  />
                                </Grid>
                              )} */}
                              <Grid
                                item
                                sm={6}
                                xs={12}
                                mt={
                                  values.invType === 'M'
                                    ? { xs: '0px', sm: '20px' }
                                    : '0px'
                                }
                              >
                                <FormSelectInput
                                  name='modeOfRegistration'
                                  label='Mode Of Registration'
                                  defaultValue={''}
                                  required
                                  options={
                                    isPanBasedAotm
                                      ? paymentModeOptions.filter(
                                          item =>
                                            item.label !== 'NETBANKING' &&
                                            item.label !== 'UPI',
                                        )
                                      : paymentModeOptions.filter(
                                          item =>
                                            item.label !== 'NETBANKING' &&
                                            item.label !== 'UPI' &&
                                            item.label !== 'AOTM',
                                        )
                                  }
                                  disabled={
                                    isFormCart &&
                                    refactoredPayload?.modeOfRegistration !== ''
                                  }
                                  onChange={e =>
                                    handleModeOfRegistrationChange(
                                      e,
                                      setFieldValue,
                                      values,
                                    )
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                xs={12}
                              >
                                <FormSelectInput
                                  name='frequency'
                                  label='Frequency'
                                  defaultValue={''}
                                  required
                                  options={sipFrequencyOption}
                                  onChange={(e: any) => {
                                    setFieldValue('sipDay', '');
                                    setFieldValue('numberOfInstalments', '');
                                    setFieldValue('startDate', null);
                                    setFieldValue('endDate', null);
                                    setFieldValue('perpetual', false);
                                    handleSipFrequencyChange(e, setFieldValue);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                xs={12}
                              >
                                <FormTextInput
                                  name='numberOfInstalments'
                                  label='Number Of Installments'
                                  required
                                  onChange={e => {
                                    setFieldValue('sipDay', '');
                                    setFieldValue('startDate', null);
                                    setFieldValue('endDate', null);
                                    setSelectedFrequencyAndSip({
                                      ...selectedFrequencyAndSip,
                                      noOfInstallments: Number(e.target.value),
                                    });
                                  }}
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      '',
                                    );
                                    if (e.target.value.startsWith('0')) {
                                      e.target.value = e.target.value.replace(/^0+/, '');
                                    }
                                    if (e.target.value.length > 5) {
                                      e.target.value = e.target.value.slice(0, 5);
                                    }
                                    setFieldValue('numberOfInstalments', e.target.value);
                                  }}
                                  disabled={values?.perpetual}
                                  typeOfInput='number'
                                />

                                <Typography>
                                  Minimum number of installments: {minNoOfInstallments}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                xs={12}
                              >
                                <FormSelectInput
                                  name='sipDay'
                                  label='SIP Day'
                                  defaultValue={''}
                                  required
                                  options={sipDays}
                                  onChange={e => {
                                    setSelectedFrequencyAndSip({
                                      ...selectedFrequencyAndSip,
                                      selectedSipDay: e.target.value,
                                    });
                                    handleSipDayChange(e, values, setFieldValue);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                xs={12}
                              >
                                <FormDateInput
                                  name='startDate'
                                  label='Start Date'
                                  required={true}
                                  viewOnly
                                />
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                xs={12}
                                mt={
                                  values.invType === 'M'
                                    ? { xs: '0px', sm: '20px' }
                                    : '0px'
                                }
                              >
                                <FormDateInput
                                  name='endDate'
                                  label='End Date'
                                  required={true}
                                  viewOnly
                                />
                              </Grid>
                              {values.sipDay !== '' && (
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormCheckbox
                                    name='perpetual'
                                    label='Perpetual SIP'
                                    margin='dense'
                                    checked={values?.perpetual}
                                    onChange={e => {
                                      handlePerpetualSipBoxChnage(
                                        e,
                                        setFieldValue,
                                        values,
                                        selectedFrequencyAndSip,
                                      );
                                      setSelectedFrequencyAndSip({
                                        ...selectedFrequencyAndSip,
                                        isPerpetual: e.target.checked,
                                      });
                                    }}
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <Typography
                            sx={{
                              color: 'text.valueColor',
                              fontWeight: 500,
                              fontSize: { xs: '14px', xl: '16px' },
                            }}
                          >
                            Investment Amount
                          </Typography>
                          <FormTextInput
                            name='investmentAmount'
                            label='Investment Amount'
                            required
                            typeOfInput='number'
                            onInput={(e: any) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '');
                              if (e.target.value.startsWith('0')) {
                                e.target.value = e.target.value.replace(/^0+/, '');
                              }
                              if (e.target.value.length > 18) {
                                e.target.value = e.target.value.slice(0, 18);
                              }
                              setFieldValue('investmentAmount', e.target.value);
                            }}
                          />
                          <Typography>
                            Minimum Amount:{' '}
                            {values.modeOfTransaction === 'Lumpsum'
                              ? schemaData?.minTransactionAmount
                              : sipMinAmount}
                          </Typography>
                        </Grid>
                        {values.modeOfTransaction === 'Lumpsum + SIP' && (
                          <Grid
                            item
                            sm={6}
                            xs={12}
                          >
                            <Typography
                              sx={{
                                color: 'text.valueColor',
                                fontWeight: 500,
                                fontSize: { xs: '14px', xl: '16px' },
                              }}
                            >
                              Lumpsum Amount
                            </Typography>
                            <FormTextInput
                              name='lumpsumAmount'
                              label='Lumpsum Amount'
                              typeOfInput='number'
                              onInput={(e: any) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                if (e.target.value.startsWith('0')) {
                                  e.target.value = e.target.value.replace(/^0+/, '');
                                }
                                if (e.target.value.length > 18) {
                                  e.target.value = e.target.value.slice(0, 18);
                                }
                                setFieldValue('lumpsumAmount', e.target.value);
                              }}
                            />
                            <Typography>Minimum Amount:{sipMinAmount}</Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Divider
                        orientation='horizontal'
                        variant='fullWidth'
                        flexItem
                        sx={{
                          border: '1px dashed',
                          borderColor: 'text.borderColorDark',
                          my: 3,
                        }}
                      />
                      {isFormCart ? (
                        <Box sx={{ display: 'flex', columnGap: '20px' }}>
                          <StepperCancelButton
                            sx={{
                              ml: 0,
                              minWidth: { xs: 'unset', sm: '200px' },
                              flex: { xs: 1, sm: 'unset' },
                              fontSize: '14px',
                              mt: { xs: 2, sm: 0 },
                              backgroundColor: 'unset',
                            }}
                            onClick={() => navigate('/transaction-dashboard/cart')}
                          >
                            Go To Cart
                          </StepperCancelButton>
                          <StepperNextButton
                            sx={{
                              ml: 0,
                              minWidth: { xs: 'unset', sm: '200px' },
                              flex: { xs: 1, sm: 'unset' },
                              fontSize: '14px',
                              mt: { xs: 2, sm: 0 },
                            }}
                            onClick={async () => handleAddToCartClick(values)}
                            disabled={isInvalidScheme}
                          >
                            Add To Cart
                          </StepperNextButton>
                        </Box>
                      ) : (
                        <SubmitCartButtons
                          onAddToCartClick={async () => handleAddToCartClick(values)}
                          firstButtonTitle='Add to Cart'
                          secondButtonTitle='Save & Proceed'
                          disableCondition={isInvalidScheme}
                        />
                      )}
                      <CustomDialog
                        isVisible={openDialogBox}
                        firstButtonHandler={() => handleAddingItemInCart(values)}
                        secondButtonHandler={handleAddAnotherTransaction}
                        firstButtonTitle='Go to cart'
                        secondButtonTitle='Add another Transaction'
                        handleClose={() => {
                          setOpenDialogBox(false);
                        }}
                        showSecondButton={
                          cartResponse?.cartItems?.length < 5 ? true : false
                        }
                      >
                        <Box
                          sx={{
                            'textAlign': 'center',
                            'pt': 2,
                            '& img': {
                              width: { xs: '58px', sm: 'unset' },
                            },
                          }}
                        >
                          <img
                            src={VerifiedIcon}
                            alt='verified-icon'
                          />
                          <MainHeader sx={{ fontWeight: 500, mb: 2 }}>
                            Added To Cart
                          </MainHeader>
                          <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                            Scheme is added to investor cart
                          </FieldSubHeader3>
                        </Box>
                      </CustomDialog>
                    </Form>
                  );
                }}
              </Formik>
            </Investorcard>
          </Grid>
        </Grid>
        <CustomDialog
          isVisible={openUniqueEmailDialogBox}
          secondButtonHandler={() => {
            setOpenUniqueEmailDialogBox(false);
          }}
          firstButtonHandler={() => {}}
          firstButtonTitle=''
          secondButtonTitle='OK'
          handleClose={() => {}}
          showFirstButton={false}
          showSecondButton={true}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& .MuiTypography-root': {
                'fontSize': '16px',
                'color': 'text.valueColor',
                'fontWeight': '400',
                'opacity': '0.85',
                'my': 2,
                '& >*': {
                  fontWeight: '600',
                },
              },
            }}
          >
            <Typography variant='body1'>
              {`${
                fieldType === 'email' ? 'Email' : 'Mobile No'
              } cannot be same as distributor ${
                fieldType === 'email' ? 'Email' : 'Mobile No'
              }`}
            </Typography>
          </Box>
        </CustomDialog>
      </Layout>
    </>
  );
};

export default NewInvestmentDetails;
