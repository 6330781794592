import {
  Box,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ShimmerTable } from 'react-shimmer-effects';

import { ReactComponent as SortingIcon } from '../../assets/MF-Bank-Icons/sorting-icon.svg';
import { ReactComponent as NoDataIcon } from '../../assets/no-data-table.svg';
// import { startLoading, stopLoading } from '../common/Loader/loaderSlice';
import { useRootContext } from '../data/root.context';
import { findLogoWithAmcName, inrCurrencyFormatter } from '../transactions/utils';
import { useLazyGetAumSummaryQuery } from './slice';
interface Data {
  newInvestors: number;
  inactiveInvestors: number;
  activeInvestors: number;
  fundName: string;
  totalAssets: string;
}

type Order = 'asc' | 'desc';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  'backgroundColor': theme.palette.common.white,
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'fundName',
    numeric: false,
    disablePadding: true,
    label: 'AMC Name',
  },
  {
    id: 'newInvestors',
    numeric: true,
    disablePadding: false,
    label: 'New Investors',
  },
  {
    id: 'activeInvestors',
    numeric: true,
    disablePadding: false,
    label: 'Active Investors',
  },
  {
    id: 'inactiveInvestors',
    numeric: true,
    disablePadding: false,
    label: 'Inactive Investors',
  },
  {
    id: 'totalAssets',
    numeric: true,
    disablePadding: false,
    label: 'Total Asset',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={(theme: Theme) => ({
        '& .MuiTableCell-root.MuiTableCell-head': {
          fontSize: '15px',
          color: theme.palette.text.primary,
          fontWeight: '500',
          [theme.breakpoints.between(768, 1024)]: {
            fontSize: '13px',
          },
        },
      })}
    >
      <StyledTableRow
        sx={(theme: Theme) => ({
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
          // hide last border
          'backgroundColor': theme.palette.common.white,
          '&:last-child td, &:last-child th': {
            border: 0,
          },
        })}
      >
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={order === 'desc' ? SortingIcon : SortingIcon}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component='span'
                  sx={visuallyHidden}
                >
                  {order === 'desc' ? <SortingIcon /> : <SortingIcon />}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

export default function AumSummary() {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('newInvestors');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { showToast } = useRootContext();
  const [getAumSummary, AumSummaryResponse] = useLazyGetAumSummaryQuery();
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  React.useEffect(() => {
    const AumSummaryResponseData =
      (AumSummaryResponse.status === 'fulfilled' ||
        AumSummaryResponse.status === 'rejected') &&
      (!AumSummaryResponse.isLoading || !AumSummaryResponse.isFetching);

    if (AumSummaryResponseData) {
      setLoading(false);
    }
  }, [AumSummaryResponse]);

  React.useEffect(() => {
    setLoading(true);
    getAumSummary('');
  }, []);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(() => {
    return stableSort(
      AumSummaryResponse?.data || [],
      getComparator(order, orderBy),
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [order, orderBy, page, rowsPerPage, AumSummaryResponse?.data]);

  const emptyRows = rowsPerPage - visibleRows.length;

  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <ShimmerTable
          row={4}
          col={3}
        />
      ) : (
        <>
          <TableContainer>
            <Table aria-labelledby='tableTitle'>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={AumSummaryResponse?.data?.length || 0}
              />
              <TableBody
                sx={{
                  '&.MuiTableBody-root': {
                    '& .MuiTableRow-root': {
                      'backgroundColor': 'common.white',
                      'cursor': 'default',
                      '& .MuiTableCell-root ': {
                        'padding': '25px 25px',
                        '& .MuiTypography-root': {
                          fontSize: { sm: '14px', xl: '16px' },
                        },
                      },
                    },
                  },
                }}
              >
                {visibleRows.length > 0 ? (
                  <>
                    {visibleRows.map((row, index) => {
                      const amcLogo = findLogoWithAmcName(row.fundName);
                      return (
                        <StyledTableRow
                          key={`row-${index}`}
                          sx={(theme: Theme) => ({
                            borderBottom: `1px solid ${theme.palette.text.borderColorDark}`,
                            bgcolor: 'common.white',
                          })}
                        >
                          <TableCell
                            component='th'
                            scope='row'
                          >
                            <Stack
                              direction='row'
                              alignItems='center'
                              columnGap='20px'
                            >
                              <Box
                                sx={{
                                  borderRadius: '5px',
                                  border: '0.5px solid',
                                  borderColor: 'text.borderColorLight',
                                  boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                                  p: '2px 2px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '40px',
                                  height: '40px',
                                }}
                              >
                                {amcLogo?.icon && <amcLogo.icon />}
                              </Box>
                              <Typography>{row?.fundName || 0}</Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontWeight: 600, color: 'text.denimBlue' }}>
                              {row?.newInvestors || 0}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{ fontWeight: 600, color: 'text.greenShade' }}
                            >
                              {row?.activeInvestors || 0}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontWeight: 600, color: 'text.clearText' }}>
                              {row?.inactiveInvestors || 0}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              ₹ {inrCurrencyFormatter(String(row?.totalAssets || 0))}
                            </Typography>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                    {/* {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={5} />
                          </TableRow>
                        )} */}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Box
                        sx={{
                          display: AumSummaryResponse?.isLoading ? 'none' : 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          maxWidth: '400px',
                          margin: '5% auto',
                        }}
                      >
                        <NoDataIcon />
                        <Typography
                          sx={{
                            fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                            fontWeight: 500,
                            color: 'text.labelColor',
                            my: 4,
                          }}
                        >
                          You currently have no Investments for this transaction
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={AumSummaryResponse?.data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
}
