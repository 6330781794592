import { createSlice } from '@reduxjs/toolkit';

export interface LoginResponse {
  token: string;
  arn: string;
  onBoardingStatus: string;
  name: string;
  email: string;
  mobile: string;
  id: string;
  isIndividual: boolean;
  admin: boolean;
  euin: string;
  subArn: string;
  subbrokerCode: string;
  assets?: {};
  refreshFlag?: boolean;
  distributorPan: string;
}

const initialState: Partial<LoginResponse> = { admin: false };

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login(state, action) {
      const {
        token,
        arn,
        name,
        isIndividual,
        euin,
        subArn,
        subbrokerCode,
        onBoardingStatus,
        email,
        mobile,
        distributorPan,
      } = action.payload;
      state.token = token;
      state.onBoardingStatus = onBoardingStatus;
      state.arn = arn;
      state.subArn = subArn;
      state.subbrokerCode = subbrokerCode;
      state.name = name;
      state.email = email;
      state.mobile = mobile;
      state.isIndividual = isIndividual;
      state.euin = euin;
      state.subArn = subArn;
      state.subbrokerCode = subbrokerCode;
      state.distributorPan = distributorPan;
    },
    loggedInAs(state, action) {
      state.admin = action.payload;
    },
    updateUserDetails(state, action) {
      const { euin, subBrokerCode, subARN } = action.payload;
      state.euin = euin;
      state.subbrokerCode = subBrokerCode;
      state.subArn = subARN;
    },
    updateOnBoardingStatus(state, action) {
      state.onBoardingStatus = action.payload;
    },
    resetLoginState: () => initialState,
    loginCompany(state, action) {},
    updateAssets: (state, action) => {
      state.assets = action.payload;
    },
    updateRefreshFlag: (state, action) => {
      state.refreshFlag = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const {
  login,
  loginCompany,
  resetLoginState,
  loggedInAs,
  updateOnBoardingStatus,
  updateAssets,
  updateRefreshFlag,
  updateUserDetails,
  setToken,
} = loginSlice.actions;
export default loginSlice.reducer;
