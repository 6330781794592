import { Box, Grid, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../../data/root.context';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput, { FormPasswordInput } from '../../../../forms/FormTextInput';
import Layout from '../../../Layout';
import { dateToDDMMYYYY } from '../../../mfs/newInvestorOnboarding/utils';
import { emailRegex } from '../../../regex';
import { useCcgsReportMutation } from '../../slice';

export interface GrandFatheringAccountStatementType {
  pan: string;
  emailId: string;
  password: string;
  confirmPassword: string;
}

export default function GrandFatheringAccountStatement() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [responseMsg, setResponseMsg] = useState<string>('');

  const [getGrandFatheringAccStatment] = useCcgsReportMutation();

  const initialValues: GrandFatheringAccountStatementType = {
    pan: '',
    emailId: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    pan: Yup.string()
      .required('PAN is required')
      .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Enter valid PAN number'),
    emailId: Yup.string()
      .required('Email ID is required')
      .matches(emailRegex, 'email must be a valid email'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]+$/,
        'Password should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)',
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .test(
        'passwordMatch',
        'The Password and confirm Password do not match',
        function (value) {
          return value === this.parent.password;
        },
      ),
  });

  const handleSubmit = async (values: GrandFatheringAccountStatementType) => {
    const params = {
      trType: 'GF',
      fund: '',
      stDate: dateToDDMMYYYY(new Date()),
      endDate: dateToDDMMYYYY(new Date()),
      emailId: values.emailId,
      zipPwd: values.password,
      pan: values.pan || pan || '',
      detailed: '',
      canNo: '',
    };
    try {
      const response: any = await getGrandFatheringAccStatment(params).unwrap();
      if (response?.message) {
        setResponseMsg(response?.message);
        setOpenDialogBox(true);
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  return (
    <>
      <Layout
        title='Grandfathering Account Statement'
        isNctTransaction
      >
        <Investorcard
          sx={(theme: Theme) => ({
            padding: '30px 30px',
            [theme.breakpoints.down('sm')]: {
              boxShadow: 'unset',
              border: 'unset',
              padding: '0px',
            },
          })}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    sm={6}
                    xs={12}
                  >
                    <FormTextInput
                      name='pan'
                      label='PAN'
                      typeOfInput='alphanumeric'
                      onInput={(e: any) => {
                        if (e.target.value.startsWith('0')) {
                          e.target.value = e.target.value.replace(/^0+/, '');
                        }
                        if (e.target.value.length > 10) {
                          e.target.value = e.target.value.slice(0, 10);
                        }
                        setFieldValue('can', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    xs={12}
                  >
                    <FormTextInput
                      name='emailId'
                      label='Email ID'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormPasswordInput
                      name='password'
                      label='Self Extraction Password'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormPasswordInput
                      name='confirmPassword'
                      label='Confirm Self Extraction Password'
                    />
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', mt: 5 }}>
                  <SubmitButton
                    sx={(theme: Theme) => ({
                      '&.MuiButtonBase-root': {
                        borderRadius: '5px',
                        minWidth: '200px',

                        [theme.breakpoints.down('sm')]: {
                          minWidth: '100%',
                        },
                      },
                    })}
                    label='Submit'
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </Investorcard>

        <CustomDialog
          isVisible={openDialogBox}
          showFirstButton={false}
          firstButtonHandler={() => {}}
          firstButtonTitle=''
          showSecondButton
          secondButtonTitle='Continue'
          secondButtonHandler={() => navigate('/transaction-dashboard/dashboard')}
          handleClose={() => {}}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& img': {
                maxWidth: { xs: '17%', md: '70%' },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography
              variant='h3'
              sx={{ color: 'primary.main', my: 2 }}
            >
              Successfully submitted your request
            </Typography>
            <Typography variant='subtitle1'>{responseMsg}</Typography>
          </Box>
        </CustomDialog>
      </Layout>
    </>
  );
}
