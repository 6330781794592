import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import {
  Investorcard,
  StepperNextButton,
} from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { CheckBoxHtmlTooltip } from '../../../common/checkbox';
import { startLoading, stopLoading } from '../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../data/root.context';
import { FormSelectInput } from '../../../forms/FormSelect'; // Ensure the correct import for FormSelectInput and OptionType
import FormTextInput from '../../../forms/FormTextInput';
import Layout from '../../Layout';
import { emailRegex } from '../../regex';
import {
  useCreateUpdateEmailRequestMutation,
  useCreateUpdateMobileRequestMutation,
} from '../../slice';
import { getRelationShip } from '../../utils';
import DetailsDataTable from '../common/DetailsDataTable';
import { DetailType } from '../common/types';

export type DOB = string | Date;
export type PINCODE = string | number;
export type ADDRESS = {
  line1: string;
  line2?: string;
  line3?: string;
  country: string;
  state: string;
  city?: string;
  pincode: PINCODE;
};

export type GUARDIAN = {
  dob: DOB;
  name: string;
  pan: string;
  relation: string;
};

export type NOMINEE = {
  dob: DOB;
  gender: string;
  address: ADDRESS;
  id: string;
  name: string;
  pan: string;
  percent: number;
  relation: string;
  guardian?: GUARDIAN;
};

export interface RowInfoType {
  id: string | number;
  amcName: string;
  investorName?: string;
  email?: string;
  amc?: string;
  schemeNames?: string[];
  mobile?: string;
  assetType?: string[];
  isDisabled?: boolean;
  folio?: string;
  nominees?: NOMINEE[];
  taxStatusLabel?: string;
}
interface Payload {
  requestData: {
    email: string;
    mobile: string;
    pan: string;
    distributor_id: string;
    relation: any;
    image: any | null;
    data: any[];
    newMobile?: string; // Define newMobile as an optional property
    newEmail?: string; // Define newEmail as an optional property
  };
}

interface FormValues {
  inputValue: string;
  relationship: string;
}

export default function ChangeEmailPhone() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { pathname } = useLocation();
  const path = pathname.split('/').pop();
  const inputType: 'email' | 'mobile' = path?.includes('email') ? 'email' : 'mobile';
  const [requestId, setRequestId] = useState('');
  const [updateMobileRequest] = useCreateUpdateMobileRequestMutation();
  const [updateEmailRequest] = useCreateUpdateEmailRequestMutation();
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const { pan, email, mobile } = useSelector((state: any) => state.transactions); // selected investor PAN
  const { arn } = useSelector((state: { login: any }) => state.login);
  const [valueState, setValueState] = useState<FormValues>();
  const { showToast } = useRootContext();

  const initialValues: FormValues = {
    inputValue: '',
    relationship: '',
  };

  const emailSchema = Yup.object().shape({
    inputValue: Yup.string()
      .required('Email Id is required')
      .matches(emailRegex, 'Invalid email address')
      .test(
        'email-different',
        'New email cannot be the same as the current email',
        value => value !== email,
      ),
    relationship: Yup.string().required('Email Relationship is required'),
  });

  const mobileSchema = Yup.object().shape({
    inputValue: Yup.string()
      .required('Mobile number is required')
      .matches(/^\d{10}$/, 'Mobile number must be a valid 10-digit number')
      .test(
        'mobile-different',
        'New mobile number cannot be the same as the current mobile number',
        value => value !== mobile,
      ),
    relationship: Yup.string().required('Mobile Relationship is required'),
  });

  const validationSchema = inputType === 'email' ? emailSchema : mobileSchema;

  const handleSelectedRows = async (selectedRows: any) => {
    try {
      dispatch(startLoading());
      const transformedResult = selectedRows.map((obj: any) => ({
        folio: obj.folio,
        amc: obj.amcId,
        amc_name: obj.amcName,
      }));

      const payload: Payload = {
        requestData: {
          email: email,
          mobile: mobile,
          pan: pan,
          distributor_id: arn,
          relation: valueState?.relationship,
          image: null,
          data: transformedResult,
        },
      };

      let res;
      if (inputType === 'mobile') {
        payload.requestData.newMobile = valueState?.inputValue; // Assign newMobile value
        res = await updateMobileRequest(payload).unwrap();
      } else if (inputType === 'email') {
        payload.requestData.newEmail = valueState?.inputValue; // Assign newEmail value
        res = await updateEmailRequest(payload).unwrap();
      }
      setRequestId(res.req_id);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  function isRowDisabled(currentRow: any): boolean {
    return currentRow.modeOfHolding !== '1';
  }

  function getTooltip(isSelected: boolean, isDisabled: boolean) {
    if (isSelected || !isDisabled) return undefined;
    return ({ children }: { children: JSX.Element }) => {
      return (
        <CheckBoxHtmlTooltip
          title={
            <>
              <Typography color='inherit'>
                {`Folios with a mode of holding as 'Single' are the only applicable ones`}
              </Typography>
            </>
          }
        >
          {children}
        </CheckBoxHtmlTooltip>
      );
    };
  }

  function handleSubmit(values: FormValues) {
    setValueState(values);
    setIsSubmitted(true);
  }
  return (
    <Layout
      title={
        inputType === 'email'
          ? 'Change / Update Email'
          : 'Change Registered Mobile Number'
      }
      isNctTransaction
      onBackPress={() => {
        if (isSubmitted) {
          setIsSubmitted(false);
        } else {
          navigate(-1);
        }
      }}
    >
      {!isSubmitted ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            actions.resetForm();
            handleSubmit(values);
          }}
          innerRef={formikRef}
          validationSchema={validationSchema}
        >
          {({ values, handleChange }: { values: FormValues; handleChange: Function }) => (
            <Form>
              <Investorcard
                sx={theme => ({
                  padding: { xs: 0, sm: '30px' },
                  [theme.breakpoints.down('md')]: {
                    border: 'unset',
                    boxShadow: 'unset',
                    p: 0,
                  },
                })}
              >
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'primary.main', mb: 2 }}
                >
                  {' '}
                  Please enter the new{' '}
                  {inputType === 'email' ? 'email address' : 'phone number'} and choose
                  family relationship below
                </Typography>

                <Grid
                  container
                  sx={{ mb: 3 }}
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='inputValue'
                      label={`Enter New ${
                        inputType === 'email' ? 'Email' : 'Mobile Number'
                      }`}
                      placeholder={`Enter New ${
                        inputType === 'email' ? 'Email' : 'Mobile Number'
                      }*`}
                      value={values.inputValue}
                      onInput={(e: any) => {
                        let value = e.target.value;
                        if (inputType === 'email') {
                          const sanitizedValue = value
                            .replace(/[^a-zA-Z0-9@._-]/g, '')
                            .replace(/@{2,}/g, '@')
                            .replace(/\.+/g, '.')
                            .replace(/-+/g, '-')
                            .replace(/_+/g, '_');
                          e.target.value = sanitizedValue;
                        } else if (inputType === 'mobile') {
                          const sanitizedValue = value.replace(/[^0-9]/g, '');
                          e.target.value = sanitizedValue;
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormSelectInput
                      name='relationship'
                      label='Select Relationship'
                      required
                      options={Object.entries(getRelationShip).map(([key, value]) => ({
                        value: key,
                        label: value,
                      }))}
                      value={values.relationship}
                    />
                  </Grid>
                </Grid>
                <StepperNextButton
                  sx={{
                    my: { xs: 3, sm: 'unset' },
                    minWidth: { xs: '100%', sm: '195px' },
                  }}
                  type='submit'
                >
                  Next
                </StepperNextButton>
              </Investorcard>
            </Form>
          )}
        </Formik>
      ) : (
        <DetailsDataTable
          columns={[
            'Folio Number',
            'Fund House',
            'Primary Holder',
            'Mobile Number',
            'Holding',
            'Email ID',
          ]}
          onSubmit={handleSelectedRows}
          isFromEmailOrPhone={inputType}
          detailType={DetailType.Investor}
          isRowDisabled={isRowDisabled}
          checkboxTooltip={getTooltip}
        />
      )}
      {!!requestId && (
        <CustomDialog
          isVisible={true}
          secondButtonHandler={() => navigate('../')}
          firstButtonHandler={() => {}}
          secondButtonTitle='OK'
          firstButtonTitle=''
          showSecondButton
          showFirstButton={false}
          handleClose={() => {}}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& .MuiTypography-root': {
                'my': 2,
                '& >*': {
                  fontWeight: '600',
                },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography>
              Your request for updating the{' '}
              <Typography component={'span'}>{`Change ${
                inputType === 'email' ? '/ Update Email' : 'Registered Mobile Number'
              }`}</Typography>{' '}
              is created successfully with request id{' '}
              <Typography component={'span'}>{requestId}</Typography>
            </Typography>
            <Typography>
              A Link has been sent to the Investor Email ID. After investor authorises the
              transaction, details will be updated after T+1 day.
            </Typography>
          </Box>
        </CustomDialog>
      )}
    </Layout>
  );
}
