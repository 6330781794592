import { TabContext, TabList } from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import VerifiedIcon from '../../../assets/Portfolio/checked-icon.svg';
import EmailIcon from '../../../assets/Portfolio/email-icon.svg';
import Gain_Loss from '../../../assets/Portfolio/gain-loss-rounded.svg';
import Invested_Value from '../../../assets/Portfolio/invested-value-rounded.svg';
import Market_value from '../../../assets/Portfolio/market-value-rounded.svg';
import PhoneIcon from '../../../assets/Portfolio/phone-icon.svg';
import UserIcon from '../../../assets/Portfolio/portfolio-user-icon.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/Portfolio/reports-icon.svg';
import { ReactComponent as TransactIcon } from '../../../assets/Portfolio/transact-icon.svg';
import { ReactComponent as SearchIconMobile } from '../../../assets/search-icon-mobile.svg';

interface RowData {
  SchemeName: any;
  ContributionAmount: any;
  CurrentNAV: any;
  BalanceUnits: any;
  CurrentValuation: string;
}

import { useDispatch } from 'react-redux';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import {
  Detailslabel,
  // Investorcard,
  SubHeader,
  SubTitle,
} from '../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../common/BackToHomePage';
import { toFixedToNumber } from '../../common/constants';
import DataTable from '../../common/DataTable';
import { useRootContext } from '../../data/root.context';
// import SearchInputBox from '../../common/SearchBox';
import { SearchBox, SearchIconContainer } from '../transactionDashboard/investorTable';
import {
  useInvestmentDetailsMutation,
  useServiceRequestDetailsMutation,
  useTransactionDetailsMutation,
} from '../transactionDashboard/slice';
import { inrCurrencyFormatter, inrCurrencyFormatter2 } from '../utils';
import { calculatePortfolioItem } from './constants';

const MFSTabList = ['Investments', 'Portfolio', 'Transactions', 'Service Request'];
const NPSTabList = [
  'Tier 1 Investments',
  'Tier 2 Investments',
  'Transactions',
  'Service Request',
];

function getKeysOfFirstObject(arr: any[]): string[] {
  if (arr.length > 0) {
    const firstObject = arr[0];
    let keys = Object.keys(firstObject);

    return keys;
  }
  return [];
}

export default function InvestorPortfolio() {
  const [tabValue, setTabValue] = React.useState(1);
  const [loading, setLoading] = React.useState<boolean>(true);
  const location = useLocation();
  const {
    investor,
    investorPortfolio,
    portfolioSummary,
    type,
    percentageGainLoss,
    showNoDataPieChart,
  } = location.state;
  const [rows, setRows] = React.useState<RowData[]>([]);
  const [filteredRows, setFilteredRows] = React.useState<any[]>([]);
  const tabList = type === 'MFS' ? MFSTabList : NPSTabList;
  const [getinvestmentDetails] = useInvestmentDetailsMutation();
  const [getTransactionDetails] = useTransactionDetailsMutation();
  const [getServiceRequestDetails] = useServiceRequestDetailsMutation();
  const [tableData, setTableData] = React.useState([{}]);
  const { showToast } = useRootContext();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    function CreateRowData() {
      const tier1Schemes = investorPortfolio['Tier-1-Schemes'] || [];
      const tier2Schemes = investorPortfolio['Tier-2-Schemes'] || [];

      const allSchemes = [...tier1Schemes, ...tier2Schemes];
      investorPortfolio['All-Schemes'] = allSchemes;

      const rowData = allSchemes.map(scheme => ({
        SchemeName: scheme.SchemeName,
        ContributionAmount: scheme.CurrentAmount,
        CurrentNAV: scheme.CurrentNav,
        BalanceUnits: scheme.BalanceUnits,
        CurrentValuation: '234455', //todo
      }));

      setRows(rowData);

      const filteredRows = rowData.filter(
        scheme =>
          scheme.SchemeName.includes('Tier I') && !scheme.SchemeName.includes('Tier II'),
      );
      setFilteredRows(filteredRows);
    }

    async function fetchInvestmentDetails() {
      try {
        setLoading(true);
        const res = await getinvestmentDetails({
          pan: investor.pan,
        }).unwrap();
        setTableData(res);
      } catch (error: any) {
        const errorMessage =
          error.data && error.data.message ? error.data.message : 'Unknown error';
        showToast(errorMessage, 'error');
      } finally {
        setLoading(false);
      }
    }
    if (investorPortfolio && Object.keys(investorPortfolio).length && type === 'NPS') {
      CreateRowData();
    } else if (portfolioSummary && type === 'MFS') {
      fetchInvestmentDetails();
    }
  }, [investorPortfolio]);

  const handleTabChange = async (event: React.SyntheticEvent, newValue: string) => {
    try {
      const numericValue = Number(newValue);

      switch (type) {
        case 'NPS':
          switch (numericValue) {
            case 1: {
              // Filter Tier-1 schemes
              setFilteredRows(
                rows.filter(
                  (scheme: { SchemeName: string }) =>
                    scheme.SchemeName.includes('Tier I') &&
                    !scheme.SchemeName.includes('Tier II'),
                ),
              );
              break;
            }

            case 2: {
              // Filter Tier-2 schemes
              setFilteredRows(
                rows.filter((scheme: { SchemeName: string }) =>
                  scheme.SchemeName.includes('Tier II'),
                ),
              );
              break;
            }

            case 3: {
              // Return a combination of Tier-1 and Tier-2 schemes
              setFilteredRows(rows);
              break;
            }

            default: {
              setFilteredRows([]); // Set to empty array if no matching case
              break;
            }
          }
          break;

        default:
          switch (numericValue) {
            case 1: {
              setLoading(true);
              const investmentDetails = await getinvestmentDetails({
                pan: investor?.pan,
              }).unwrap();
              setTableData(investmentDetails);
              break;
            }

            case 3: {
              setLoading(true);
              const transactionDetails = await getTransactionDetails({
                pan: investor?.pan,
              }).unwrap();
              setTableData(transactionDetails);
              break;
            }

            case 4: {
              setLoading(true);
              const serviceRequestDetails = await getServiceRequestDetails({
                pan: investor?.pan,
              }).unwrap();
              setTableData(serviceRequestDetails);
              break;
            }

            default: {
              // Handle unexpected case, if necessary
              break;
            }
          }
          break;
      }

      setTabValue(Number(newValue));
    } catch (error: any) {
      const errorMessage =
        error.data && error.data.message ? error.data.message : 'Unknown error';
      showToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const filterTabs = (
    <>
      <TabContext value={tabValue}>
        <Box
          sx={{
            'width': '100%',
            'borderBottom': '1px solid',
            'borderColor': 'text.borderColorLight',
            '& .MuiTabs-root': {
              '& .MuiTabs-flexContainer': {
                'pl': '0px',
                'columnGap': '25px',
                '& .MuiButtonBase-root.MuiTab-root': {
                  'pb': '20px',
                  'fontSize': { xl: '18px', lg: '16px', xs: '14px' },
                  'fontWeight': 500,
                  '&.Mui-disabled': {
                    color: 'rgba(108, 115, 127, 0.50)',
                  },
                },
              },
              '& .MuiTabs-indicator': {
                height: '5px',
                borderRadius: '100px 100px 0px 0px',
              },
            },
            '& .MuiTabPanel-root': {
              padding: '50px 50px',
            },
          }}
        >
          <TabList
            onChange={handleTabChange}
            aria-label='lab API tabs example'
          >
            {tabList.map((tab, index) => (
              <Tab
                key={tab}
                label={tab}
                value={Number(index + 1)}
              />
            ))}
          </TabList>
        </Box>
      </TabContext>
      <SearchBox>
        <SearchIconContainer>
          <SearchIconMobile />
        </SearchIconContainer>
        <TextField
          id='filled-basic'
          variant='filled'
          placeholder='Search'
          fullWidth
          sx={{
            '& .MuiInputBase-root ': {
              'color': 'inherit',
              'backgroundColor': 'common.white',
              'borderTopLeftRadius': '8px',
              'borderTopRightRadius': '8px',
              '&:hover,&::after,&::before': {
                borderBottom: 'unset',
              },
              '& .MuiInputBase-input': {
                padding: '15px 12px 15px 40px',
                backgroundColor: 'common.white',
                borderRadius: '5px',
                fontWeight: '500',
                fontSize: '14px',
                color: '#6C737F',
                border: '1px solid #CDCDCD',
              },
            },
          }}
        />
      </SearchBox>
    </>
  );

  const {
    totalInvestedValue,
    equityClassAmount,
    debtClassAmount,
    liquidClassAmount,
    others,
  } = portfolioSummary;

  const data =
    type === 'MFS'
      ? !showNoDataPieChart && Number(totalInvestedValue) > 0
        ? [
            calculatePortfolioItem('equity', totalInvestedValue, equityClassAmount),
            calculatePortfolioItem('debt', totalInvestedValue, debtClassAmount),
            calculatePortfolioItem('liquid', totalInvestedValue, liquidClassAmount),
            calculatePortfolioItem('Others', totalInvestedValue, others),
          ]
        : [{ name: 'No Data', value: 1, percentage: 0, inr: 0 }]
      : [
          { name: 'equity', value: 25, percentage: '25%', inr: '₹2,22,561' },
          { name: 'debt', value: 9.53, percentage: '25%', inr: '₹2,22,561' },
          { name: 'liquid', value: 37.5, percentage: '25%', inr: '₹2,22,561' },
          { name: 'Others', value: 28, percentage: '25%', inr: '₹2,22,561' },
        ]; // TODO - remove static data

  const COLORS = ['#ADD8E6', '#D8BFD8', '#FFD700', '#9370DB'];
  //TODO - remove static data

  const absoluteCalculation = () => {
    if (type === 'NPS') {
      const totalCurrentValuation = Number(investorPortfolio?.totalCurrentValuation ?? 0);
      const totalContributionAmount = Number(
        investorPortfolio?.totalContributionAmount ?? 0,
      );

      // Calculate the result only if totalContributionAmount is not zero
      const result =
        totalContributionAmount === 0
          ? 0
          : ((totalCurrentValuation - totalContributionAmount) /
              totalContributionAmount) *
            100;

      return result;
    } else {
      return portfolioSummary?.absoluteReturns;
    }
  };

  const portFolioChart = (
    <Box sx={{ padding: '50px 10px' }}>
      <Grid
        container
        spacing={2}
        alignItems={'center'}
      >
        <Grid
          item
          sm={4}
        >
          <Box>
            <PieChart
              style={{ width: '200px', height: '200px', margin: '0 auto' }}
              width={200}
              height={200}
            >
              <Pie
                dataKey='value'
                data={data}
                cx='50%'
                cy='50%'
                startAngle={90}
                endAngle={-270}
                innerRadius={60} // Adjust inner radius to create space in the middle
                outerRadius={80}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value, name) => [`${name} - ${value} %`, '']} />
            </PieChart>
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1} />
                  <TableCell>
                    <Typography
                      variant='subtitle2'
                      sx={{
                        color: 'text.valueColor',
                        fontWeight: 600,
                        textAlign: 'center',
                      }}
                    >
                      Percentage(%)
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='subtitle2'
                      sx={{ color: 'text.valueColor', fontWeight: 600 }}
                    >
                      INR
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  '& .MuiTableRow-root:nth-of-type(odd)': {
                    bgcolor: 'common.white',
                  },
                }}
              >
                {data.map((entry, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        columnGap={'20px'}
                      >
                        <Box
                          sx={{
                            backgroundColor: COLORS[index as number] || 'red',
                            width: '11px',
                            height: '11px',
                            borderRadius: '50px',
                          }}
                        />
                        <Typography sx={{ color: 'text.valueColor' }}>
                          {entry.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor', textAlign: 'center' }}>
                        {entry.percentage}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>
                        {entry.inr}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <PageNavigator title='Investor Portfolio' />
      <Box
        sx={{
          backgroundColor: 'text.boxBgColor',
          padding: { xs: '20px 10px', md: '40px 0px 40px 30px' },
          pr: '0',
          borderRadius: '12px',
          mb: 3,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={4}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                columnGap: '25px',
                mb: { xs: 3, md: 5 },
              }}
            >
              <img
                src={UserIcon}
                alt='user-icon'
              />
              <Box>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      my: 1,
                      fontWeight: 500,
                      color: 'primary.main',
                    }}
                  >
                    {investor.name}
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 500, color: 'text.valueColor', mb: 1.75 }}
                  >
                    <Typography
                      component={'span'}
                      sx={{ color: 'text.labelColor' }}
                    >
                      PAN:
                    </Typography>
                    {investor.pan}
                  </Typography>
                </Stack>
                <Stack direction={'row'}>
                  {['KYC', 'FATCA'].map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        'display': 'flex',
                        'backgroundColor': '#22C55E26',
                        'borderRadius': '46px',
                        'padding': '5px 8px',
                        'columnGap': '5px',
                        '&:first-child': {
                          marginRight: '12px',
                        },
                      }}
                    >
                      <img
                        src={VerifiedIcon}
                        alt='verified'
                      />
                      <Typography
                        sx={{ fontWeight: 500, fontSize: { xs: '11px', md: '12px' } }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                columnGap: '12px',
                mb: 2.5,
              }}
            >
              <img
                src={EmailIcon}
                alt='email'
              />
              <Typography sx={{ fontWeight: '500', color: 'text.labelColor' }}>
                {investor?.email || 'NA'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '12px' }}>
              <img
                src={PhoneIcon}
                alt='phone'
              />
              <Typography sx={{ fontWeight: '500', color: 'text.labelColor' }}>
                {investor?.mobile || 'NA'}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
          >
            <Box
              sx={{
                borderLeft: { md: '1px solid' },
                borderTop: { xs: '3px dashed', md: 'unset' },
                borderColor: { xs: 'text.borderColorLight', md: 'text.borderColorLight' },
                padding: { xs: '30px 0px 0px 0px', md: '26px 0px 26px 30px' },
                mt: { xs: '30px', md: 'unset' },
              }}
            >
              <Grid container>
                <Grid
                  item
                  md={9}
                  xs={12}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          columnGap: '12px',
                          alignItems: 'center',
                          mb: 4,
                        }}
                      >
                        <img
                          src={Invested_Value}
                          alt='invested-value'
                        />
                        <Stack>
                          <SubHeader sx={{ display: 'flex' }}>
                            <Detailslabel sx={{ fontSize: '20px' }}>₹</Detailslabel>
                            {type === 'MFS'
                              ? inrCurrencyFormatter(
                                  String(
                                    toFixedToNumber(
                                      portfolioSummary?.totalInvestedValue,
                                      2,
                                    ),
                                  ),
                                ) || 0
                              : inrCurrencyFormatter(
                                  String(
                                    toFixedToNumber(
                                      investorPortfolio?.totalCurrentValuation,
                                      2,
                                    ),
                                  ),
                                ) || 0}{' '}
                          </SubHeader>
                          <SubTitle sx={{ fontWeight: 500 }}>Invested Value</SubTitle>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          columnGap: '12px',
                          alignItems: 'center',
                          justifyContent: { xs: 'flex-end', md: 'flex-start' },
                        }}
                      >
                        <img
                          src={Gain_Loss}
                          alt='gain-loss'
                        />
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: { lg: '20px', xl: '22px' },
                              fontWeight: 600,
                              color: 'text.greenShade',
                            }}
                          >
                            {Number(percentageGainLoss) > 0 ? '+' : '-'}{' '}
                            {String(
                              inrCurrencyFormatter2(percentageGainLoss.toFixed(2)),
                            )?.replace('-', '')}
                          </Typography>
                          <SubTitle sx={{ fontWeight: 500 }}>Gain/Loss</SubTitle>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          columnGap: '12px',
                          alignItems: 'center',
                          mb: 4,
                        }}
                      >
                        <img
                          src={Market_value}
                          alt='market-value'
                        />
                        <Stack>
                          <SubHeader sx={{ display: 'flex' }}>
                            <Detailslabel sx={{ fontSize: '20px' }}>₹</Detailslabel>
                            {type === 'MFS'
                              ? inrCurrencyFormatter(
                                  String(
                                    toFixedToNumber(portfolioSummary?.marketValue, 2),
                                  ),
                                ) || 0
                              : inrCurrencyFormatter(
                                  String(
                                    toFixedToNumber(
                                      investorPortfolio?.totalCurrentValuation,
                                      2,
                                    ),
                                  ),
                                ) || 0}{' '}
                          </SubHeader>
                          <SubTitle sx={{ fontWeight: 500 }}>Market Value</SubTitle>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2.5}
                      sx={{ textAlign: { xs: 'center', md: 'unset' } }}
                    >
                      <Box
                        sx={(theme: Theme) => ({
                          borderRadius: '53px',
                          fontSize: { xs: '10px', md: '14px' },
                          fontWeight: 400,
                          minHeight: { md: '34px' },
                          padding: { xs: '5px 5px', md: '4px 12px' },
                          background: theme.palette.background.primaryLinearGradient2,
                          color: 'common.white',
                          minWidth: { xs: '100px', md: '120px' },
                          mb: { xs: 1, md: 2 },
                          textAlign: 'center',
                          display: { xs: 'inline-flex', md: 'flex' },
                          justifyContent: 'center',
                          alignItems: 'center',
                        })}
                      >
                        Absolute
                      </Box>
                      <Typography sx={{ textAlign: 'center' }}>
                        {absoluteCalculation()}
                        {'%'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <Stack
                    sx={{
                      alignItems: 'end',
                      height: '100%',
                      justifyContent: { xs: 'space-between', md: 'center' },
                      gap: { xs: '20px', md: '20px' },
                      flexDirection: { xs: 'row-reverse', md: 'column' },
                    }}
                  >
                    <Button
                      sx={(theme: Theme) => ({
                        background: theme.palette.background.primaryLinearGradient2,
                        borderRadius: { xs: '5px', md: '100px 0px 0px  100px' },
                        padding: '12px 20px',
                        minWidth: { md: '175px' },
                        justifyContent: { xs: 'center', md: 'flex-start' },
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: 600,
                        color: 'common.white',
                        flex: { xs: 1, md: 'unset' },
                      })}
                      startIcon={<TransactIcon />}
                      onClick={() =>
                        type === 'MFS'
                          ? navigate('../transact', { replace: true })
                          : navigate('../nps/additional-purchase', { replace: true })
                      }
                    >
                      Transact
                    </Button>
                    <Button
                      sx={{
                        borderRadius: { xs: '5px', md: '100px 0px 0px  100px' },
                        minWidth: { md: '175px' },
                        padding: '12px 20px',
                        justifyContent: { xs: 'center', md: 'flex-start' },
                        border: '1px solid',
                        borderColor: 'primary.main',
                        bgcolor: 'text.boxColor',
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: 600,
                        color: 'primary.main',
                        borderRight: { md: '0px' },
                        flex: { xs: 1, md: 'unset' },
                      }}
                      startIcon={<ReportsIcon />}
                      onClick={() =>
                        type === 'MFS'
                          ? navigate('../mis/mfs-reports', { replace: true })
                          : navigate('../mis/nps', { replace: true })
                      }
                    >
                      Send Reports
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {type === 'NPS' ? (
        <DataTable
          loading={loading}
          setLoading={setLoading}
          rows={filteredRows}
          columns={[
            'Scheme Name',
            'Contribution Amount',
            'Current NAV',
            'Balance Units',
            'Current Valuation',
          ]}
          onSubmit={() => {}}
          filter={filterTabs}
          hideUpdateButton={true}
          hidecheckbox={true}
        />
      ) : (
        <>
          <DataTable
            loading={loading}
            setLoading={setLoading}
            rows={Number(tabValue) !== 2 ? tableData : []}
            columns={Number(tabValue) !== 2 ? getKeysOfFirstObject(tableData) : []}
            onSubmit={() => {}}
            filter={filterTabs}
            hideUpdateButton={true}
            hidecheckbox={true}
            hideNoData={Number(tabValue) === 2 ? false : true}
            showPieChart={Number(tabValue) === 2 ? true : false}
            portFolioChart={portFolioChart}
          />
        </>
      )}
      {/* Footer */}
    </>
  );
}
