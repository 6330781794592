import { toFixedToNumber } from '../../common/constants';

interface PortfolioItem {
  name: string;
  value: number;
  percentage: number;
  inr: number;
}

export function calculatePortfolioItem(
  name: string,
  totalInvestedValue: number,
  classAmount: number,
): PortfolioItem {
  const safeClassAmount =
    classAmount !== undefined && classAmount !== null ? classAmount : 0;
  const value = totalInvestedValue
    ? toFixedToNumber(safeClassAmount / totalInvestedValue, 2)
    : 0;

  return {
    name,
    value,
    percentage: value,
    inr: toFixedToNumber(safeClassAmount, 2),
  };
}
