import { LinearProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import { AppThemeContextProvider } from './AppTheme';
import useAuthManagement from './components/auth/utils/useAuthManagement';
import AppLayout from './components/common/AppLayout';
import {
  onBoardingStatusCompany,
  onBoardingStatusIndividual,
} from './components/common/constants';
import RootContextProvider from './components/data/root.context';
import { LoginResponse } from './components/features/login/loginSlice';
import RequireAuth from './components/require-auth/require-auth';
// import RequireAuth from './components/require-auth/require-auth';
import { TransactionRoutes } from './components/transactions/router';
import { TransactionDashboard } from './components/transactions/transactionDashboard';

const AuthRouter = lazy(async () => import('./components/auth/signup/router'));
const OnboardingRouter = lazy(async () => import('./components/onboarding/router'));
// const TransactionsRouter = lazy(() => import('./components/transactions/router'));

function App() {
  useEffect(() => {
    function handleMessage(event: any) {
      if (event.data?.type === 'newVersionAvailable') {
        if (
          window.confirm(
            'A new version of the application is available, please reload the page to see changes.',
          )
        ) {
          window.location.reload();
        }
      }
    }
    navigator.serviceWorker?.addEventListener('message', handleMessage);
    return () => {
      navigator.serviceWorker?.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      <AppThemeContextProvider>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <RootContextProvider>
            <AppRoutes />
          </RootContextProvider>
        </SnackbarProvider>
      </AppThemeContextProvider>
    </>
  );
}
const getNavigationPath = () => {
  const { isIndividual, onBoardingStatus, token } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );

  if (!token) {
    return '/auth/login';
  }

  if (onBoardingStatus?.toLowerCase() === 'completed') {
    return '/transaction-dashboard/dashboard';
  }

  let onBoardingStatusPath = !isIndividual
    ? onBoardingStatusCompany[onBoardingStatus as keyof typeof onBoardingStatusCompany]
    : onBoardingStatusIndividual[
        onBoardingStatus as keyof typeof onBoardingStatusIndividual
      ];

  if (onBoardingStatusPath === 'select-asset') {
    onBoardingStatusPath = '../../../onboarding';
  }

  return isIndividual
    ? `../../../onboarding/company/${onBoardingStatusPath}`
    : `../../../onboarding/individual/${onBoardingStatusPath}`;
};
const routes = () => {
  const navigationStr = getNavigationPath();
  useAuthManagement();

  return [
    {
      path: '/',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <Navigate to={navigationStr} />,
        },
        {
          path: 'transaction-dashboard/*',
          element: (
            <RequireAuth>
              <TransactionAppRoutes />
            </RequireAuth>
          ),
        },
        {
          path: 'auth/*',
          element: <AuthRouter />,
        },
        {
          path: 'onboarding/*',
          element: <OnboardingRouter />,
        },
        // {
        //   path: 'dashboard/*',
        //   element: <RequireAuth />,
        // },
      ],
    },
  ];
};

function AppRoutes() {
  const routing = useRoutes(routes());
  return (
    <AppLayout>
      <Suspense fallback={<LinearProgress />}>{routing}</Suspense>
    </AppLayout>
  );
}

function TransactionAppRoutes() {
  const routing = useRoutes(TransactionRoutes());
  return (
    <TransactionDashboard>
      <Suspense fallback={<LinearProgress />}>{routing}</Suspense>
    </TransactionDashboard>
  );
}
export default App;
