import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  parseISO,
} from 'date-fns';

import { freqType, getEnumValueFromString } from '../hooks/useSip';
export function capitalizeFirstLetter(str: any): string {
  // Handle non-string input by returning an empty string or throwing an error.
  if (typeof str !== 'string') {
    return ''; // Or throw new Error('Input is not a string');
  }

  // Trim the string to remove extra spaces at the start or end.
  return str
    .trim()
    .split(/\s+/) // Handle multiple spaces between words by splitting based on one or more spaces.
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function calculateTatDate(frequencyCoolingPeriod: number) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const tatDate = new Date(today);
  tatDate.setDate(today.getDate() + frequencyCoolingPeriod);
  tatDate.setHours(0, 0, 0, 0);

  if (tatDate.getDay() === 0) {
    // If tatDate is Sunday
    tatDate.setDate(tatDate.getDate() + 1);
  } else if (tatDate.getDay() === 6) {
    // If tatDate is Saturday
    tatDate.setDate(tatDate.getDate() + 2);
  }

  return tatDate;
}
export const disableDatesOnStartDate = (date: Date, calculatedTatDate: Date) => {
  const currentDate = new Date(date);
  currentDate.setHours(0, 0, 0, 0); // Removing Timezone for better comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const dayOfWeek = currentDate.getDay(); // for checking weekends.
  const dayOfMonth = currentDate.getDate(); // Get the day of the month

  if (currentDate < today) {
    return true; // Disable past dates
  }

  // Disable weekends and dates greater than 28
  if (dayOfMonth > 28 || dayOfWeek === 0 || dayOfWeek === 6) {
    return true; // Disable weekends and dates greater than 28
  }

  // Only enabling dates coming after tatDate.
  if (currentDate > calculatedTatDate) {
    return false; // Enable dates after calculatedTatDate
  }

  return true; // Disable other dates
};

export const disableDatesOnEndDate = (
  date: Date,
  frequency: string,
  startDate: Date,
  minInstallments: number,
) => {
  const currentDate = new Date(date);
  currentDate.setHours(0, 0, 0, 0);
  let today = startDate ? new Date(startDate) : new Date();
  today.setHours(0, 0, 0, 0);

  let nextEnabledDate = new Date(today);
  let enable = false;
  let count = 0;
  let freqString =
    frequency === 'Annually' || frequency === 'Annual' ? 'Annual' : frequency;
  const frequencyKey = getEnumValueFromString(freqString as string);
  while (nextEnabledDate <= currentDate) {
    count++;
    if (nextEnabledDate.getTime() === currentDate.getTime() && count >= minInstallments) {
      enable = true;
      break;
    }
    incrementDate[frequencyKey](nextEnabledDate);
  }

  return !enable;
};
export const incrementDate: { [key: string]: (date: Date) => void } = {
  W: (date: Date) => date.setDate(date.getDate() + 7),
  M: (date: Date) => date.setMonth(date.getMonth() + 1),
  Q: (date: Date) => date.setMonth(date.getMonth() + 3),
  H: (date: Date) => date.setMonth(date.getMonth() + 6),
  A: (date: Date) => date.setFullYear(date.getFullYear() + 1),
  F: (date: Date) => date.setDate(date.getDate() + 14),
  D: (date: Date) => date.setDate(date.getDate() + 1),
  DZ: (date: Date) => date.setDate(date.getDate() + 1),
};

export function calculateNextInstallmentDate(
  startDate: Date,
  frequencyType: string,
  minInstallments: number,
) {
  let currentDate = new Date(startDate);

  let freqString =
    frequencyType === 'Annually' || frequencyType === 'Annual' ? 'Annual' : frequencyType;
  const frequencyKey = getEnumValueFromString(freqString as string);

  for (let i = 1; i < minInstallments; i++) {
    incrementDate[frequencyKey](currentDate);
  }
  return currentDate;
}
const parseDate = (date: string | Date): Date => {
  if (typeof date === 'string') {
    if (date.endsWith('Z')) {
      return parseISO(date);
    }
    return new Date(date);
  }
  return date;
};

export const calculateDifference = (
  startDate: string | Date,
  endDate: string | Date,
  frequency: string,
) => {
  const parsedStartDate = parseDate(startDate);
  const parsedEndDate = parseDate(endDate);
  if (isNaN(parsedStartDate.getTime()) || isNaN(parsedEndDate.getTime())) {
    return null;
  }
  switch (frequency) {
    case freqType.W:
      return differenceInWeeks(parsedEndDate, parsedStartDate) + 1;

    case freqType.M:
      return differenceInMonths(parsedEndDate, parsedStartDate) + 1;

    case freqType.D:
    case freqType.DZ:
      return differenceInDays(parsedEndDate, parsedStartDate) + 1;

    case freqType.Q:
      return Math.floor(differenceInMonths(parsedEndDate, parsedStartDate) / 3) + 1;

    case freqType.H:
      return Math.floor(differenceInMonths(parsedEndDate, parsedStartDate) / 6) + 1;

    case freqType.Y:
      return differenceInYears(parsedEndDate, parsedStartDate) + 1;

    case freqType.F:
      return Math.floor(differenceInDays(parsedEndDate, parsedStartDate) / 14) + 1;

    default:
      return null;
  }
};

export function formatCurrencyInINR(value: number): string {
  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
}

export const getDayFromMMDDYYYY = (dateString: string): number => {
  // Regular expression to match the MM/DD/YYYY format
  const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

  // Test if the dateString matches the expected format
  if (!regex.test(dateString)) {
    return 0;
  }

  // Extract the day part from the dateString
  const day = parseInt(dateString.split('/')[1], 10);

  // Validate the day
  if (day < 1 || day > 31) {
    return 0;
  }

  return day;
};

export const formatToMMDDYYYYByUTC = (input?: Date | string | null): string => {
  let date: Date;

  if (!input) {
    // If input is null, undefined, or empty string, use current date
    date = new Date();
  } else if (input instanceof Date) {
    date = input;
  } else if (typeof input === 'string') {
    date = new Date(input);
  } else {
    return '';
  }

  if (isNaN(date.getTime())) {
    return '';
  }

  // Convert to IST by adding 5 hours and 30 minutes
  const istDate = new Date(date.getTime() + (5 * 60 + 30) * 60 * 1000);

  const month = String(istDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(istDate.getUTCDate()).padStart(2, '0');
  const year = istDate.getUTCFullYear();

  return `${month}/${day}/${year}`;
};

export function getDayFromDate(input?: Date | string | null): string {
  let date: Date;

  if (!input) {
    // If input is null, undefined, or empty string, use current date
    date = new Date();
  } else if (input instanceof Date) {
    // If input is already a Date object, use it directly
    date = input;
  } else if (typeof input === 'string') {
    // If input is a string, attempt to create a Date object
    date = new Date(input);
  } else {
    // If input is neither Date nor string, log warning and use current date
    date = new Date();
  }

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    date = new Date();
  }

  // Return only the day of the month
  return String(date.getDate());
}
export const toUTCDate = (date: Date) => {
  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return utcDate;
};
export function calculatePercentage(value: number, total: number): string {
  if (total === 0) return '0.00';
  const percent = (value / total) * 100;
  return percent.toFixed(2);
}
export function generateAgentReferenceNo(pan: string, arnCode: string): string | null {
  if (pan.length !== 10) {
    return null;
  }

  if (!arnCode.startsWith('ARN-')) {
    return null;
  }

  const arnNumeric = arnCode.slice(4);
  const paddedArn = String(arnNumeric).padStart(4, '0');

  const agentReferenceNo = `${pan}${paddedArn}`;

  return agentReferenceNo;
}
