import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Skeleton,
  Stack,
  TablePagination,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Clip_Icon } from '../../../../../assets/clipper.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete-red.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/filter-close-icon.svg';
import { ReactComponent as SearchIcon } from '../../../../../assets/search-icon.svg';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { Investorcard } from '../../../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../../../common/BackToHomePage';
import { CustomizedCheckbox } from '../../../../common/checkbox';
// import { startLoading, stopLoading } from '../../../../common/Loader/loaderSlice';
import { CustomSearch } from '../../../../common/search';
import { useRootContext } from '../../../../data/root.context';
import { planModes } from '../../../constants';
import { steps } from '../../constants';
import { setFundName, setInvestmentDetails } from '../context/NewMfsInvestorAction';
import { useNewMfsInvestorContext } from '../context/NewMfsInvestorContext';
import { useLazyAllSchemeDetailsQuery, useSchemeByfundMutation } from '../slice';
import { RadioCollapsable } from './RadioCollapsable';
import { ResponsiveInvOnBoardingFilters } from './ResponsiveInvOnboardingFilters';

const data = ['AMC', 'Category'];

export interface Scheme {
  fund: string;
  fundName: string;
}

export interface SchemeByfund {
  category: string;
  fund: string;
  option: string;
  plan: string;
  plan_mode: string;
  schemeDesc: string;
  scheme: string;
}

export function SchemeSelection() {
  // const isFirstRender = useRef<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [query, setQuery] = useState('');
  const [schemeSearchQuery, setSchemeSearchQuery] = useState('');
  const [allFundData, setAllFundData] = useState<Scheme[]>([]);
  const [tempFundData, setTempFundData] = useState<Scheme[]>([]);
  const [selectedScheme, setSelectedScheme] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [schemeName, setSchemeName] = useState(data[0] || '');
  const [schemesByFund, setSchemesByFund] = useState<SchemeByfund[]>([]);
  const [tempSchemeData, setTempSchemeData] = useState<SchemeByfund[]>([]);
  const [schemeCategoryData, setSchemeCategoryData] = useState<string[]>([]);
  const [tempAllFundData, setTempAllFundData] = useState<any>([]);
  const [chips, setChips] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [closeFilterOption, setCloseFilterOption] = useState<boolean>(false);

  const [getSchemes, getAllFundResponse] = useLazyAllSchemeDetailsQuery();
  const [getSchemesByFund] = useSchemeByfundMutation();

  const { dispatch: contextDispatch } = useNewMfsInvestorContext();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleCheckboxChange = async (index: number, e: any) => {
    setSchemeSearchQuery('');
    const isChecked = e.target.checked;
    const prev = selectedScheme;
    const itemIndex = prev.indexOf(allFundData[index]?.fund);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(allFundData[index]?.fund);
    }
    setSelectedScheme([...prev]);
    const fundNames = allFundData.map((item: any) => item.fund);
    try {
      setLoading(true);
      const schemeByFundBody = {
        funds: selectedScheme.length === 0 ? fundNames : selectedScheme,
        category: selectedCategory,
        investorPan: '',
        start: 0,
        end: 3000, //Todo: need to handle from backend
        schemeFilterParams: {
          planMode: planModes.REGULAR,
        },
      };
      const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

      const resFundWithOpenKey = resFund
        .map((item: SchemeByfund) => ({
          ...item,
          open: false,
          key: nanoid(),
        }))
        ?.filter((item: any) => item.newPurAllow);

      setSchemesByFund(resFundWithOpenKey);
      setPage(0);
    } catch (error: any) {
      showToast(error?.data?.message || 'unknown error', 'error');
    } finally {
      setLoading(false);
    }
    if (!isChecked) {
      setChips(chips.filter((chip: string) => chip !== allFundData[index]?.fundName));
    } else {
      setChips((prev: string[]) => [...new Set([...prev, allFundData[index]?.fundName])]);
    }
  };

  const handleCategoryChange = async (index: number, e: any) => {
    setSchemeSearchQuery('');
    const isChecked = e.target.checked;
    const prev = selectedCategory;
    const itemIndex = prev.indexOf(schemeCategoryData[index]);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(schemeCategoryData[index]);
    }
    setSelectedCategory([...prev]);
    try {
      setLoading(true);
      const schemeByFundBody = {
        funds:
          selectedScheme.length !== 0
            ? selectedScheme
            : allFundData.map((item: any) => item.fund),
        category: selectedCategory,
        investorPan: '',
        start: 0,
        end: 3000, //Todo: need to handle from backend
        schemeFilterParams: {
          planMode: planModes.REGULAR,
        },
      };
      const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

      const resFundWithOpenKey = resFund
        .map((item: SchemeByfund) => ({
          ...item,
          open: false,
          key: nanoid(),
        }))
        ?.filter((item: any) => item.newPurAllow);

      setSchemesByFund(resFundWithOpenKey);
      setPage(0);
      if (!isChecked) {
        setChips((prev: string[]) =>
          prev.filter((chip: string) => chip !== schemeCategoryData[index]),
        );
      } else {
        setChips((prev: string[]) => [...new Set([...prev, schemeCategoryData[index]])]);
      }
    } catch (error: any) {
      showToast(error?.data?.message || 'unknown error', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleClearClick = async () => {
    let updatedScheme = selectedScheme;
    let updatedCategory = selectedCategory;
    const fundNames = allFundData.map((item: any) => item.fundName);
    if (schemeName === 'AMC') {
      setSelectedScheme([]);
      setChips([]);
      updatedScheme = [];
      const updatedChips = chips.filter(chip => !fundNames.includes(chip));
      setChips(updatedChips);
    } else {
      const updatedChips = chips.filter(chip => !selectedCategory.includes(chip));
      setChips(updatedChips);
      updatedCategory = [];
      setSelectedCategory([]);
    }
    try {
      setLoading(false);
      const schemeByFundBody = {
        funds:
          updatedScheme.length === 0
            ? allFundData.map((item: any) => item.fund)
            : updatedScheme,
        category: schemeName === 'AMC' ? updatedCategory : [],
        investorPan: '',
        start: 0,
        end: 3000, //Todo: need to handle from backend
        schemeFilterParams: {
          planMode: planModes.REGULAR,
        },
      };
      const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

      const resFundWithOpenKey = resFund
        .map((item: SchemeByfund) => ({
          ...item,
          open: false,
          key: nanoid(),
        }))
        ?.filter((item: any) => item.newPurAllow);
      setSchemesByFund(resFundWithOpenKey);
      setPage(0);
      if (selectedScheme.length === 0 && selectedCategory.length === 0) {
        setTempAllFundData(resFundWithOpenKey);
      }
    } catch (error: any) {
      showToast(error?.data?.message || 'unknown error', 'error');
    } finally {
      setLoading(false);
    }
  };

  function sortByAction(action: string) {
    if (action === 'lowToHigh') {
      return schemesByFund.sort((a: any, b: any) => {
        const riskOrder = ['Low Risk', 'Moderate Risk', 'Very High Risk'];
        return riskOrder.indexOf(a.risk) - riskOrder.indexOf(b.risk);
      });
    } else if (action === 'highToLow') {
      return schemesByFund.sort((a: any, b: any) => {
        const riskOrder = ['Low Risk', 'Moderate Risk', 'Very High Risk'];
        return riskOrder.indexOf(b.risk) - riskOrder.indexOf(a.risk);
      });
    } else {
      return schemesByFund;
    }
  }

  //Used when get the data related to Risk
  const handleSortByChange = (event: any) => {
    setSortBy(event.target.value as string);
    switch (event.target.value) {
      case 'lowToHigh':
        setSchemesByFund(sortByAction('lowToHigh'));
        break;
      case 'highToLow':
        setSchemesByFund(sortByAction('highToLow'));
        break;
      default:
        return schemesByFund;
    }
  };

  const handleDelete = async (filteredScheme: string) => {
    const tempSelectedScheme = allFundData
      .filter(item => selectedScheme.indexOf(item.fund) !== -1)
      .filter((ele: any) => ele.fundName !== filteredScheme)
      .map(data => data.fund);
    setSelectedScheme(tempSelectedScheme);
    const tempCategry = selectedCategory.filter(
      (item: any) => !filteredScheme.includes(item),
    );
    setSelectedCategory(tempCategry);
    try {
      setLoading(true);
      const schemeByFundBody = {
        funds:
          tempSelectedScheme.length === 0
            ? allFundData.map((item: any) => item.fund)
            : tempSelectedScheme,
        category: tempCategry,
        investorPan: '',
        start: 0,
        end: 3000, //Todo: need to handle from backend
        searchQuery: schemeSearchQuery !== '' ? schemeSearchQuery : '',
        schemeFilterParams: {
          planMode: planModes.REGULAR,
        },
      };
      const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

      const resFundWithOpenKey = resFund
        .map((item: SchemeByfund) => ({
          ...item,
          open: false,
          key: nanoid(),
        }))
        ?.filter((item: any) => item.newPurAllow);
      const tempCategoryData = resFund.map((item: any) => item.category);
      const uniQueCategory = [...new Set(tempCategoryData)];
      setSchemesByFund(resFundWithOpenKey);
      setTempSchemeData(resFundWithOpenKey);
      setPage(0);
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    } finally {
      setLoading(false);
    }
    setChips((prev: string[]) => prev.filter((chip: string) => chip !== filteredScheme));
  };

  useEffect(() => {
    if (
      getAllFundResponse &&
      getAllFundResponse.isSuccess &&
      !getAllFundResponse.isFetching
    ) {
      const getAllSchemes = async () => {
        const allFunds = getAllFundResponse.data;
        setAllFundData(allFunds);
        setTempFundData(allFunds);
        setLoading(false);
        const fundNames = allFunds.map((item: any) => item.fund);
        try {
          setLoading(true);
          const schemeByFundBody = {
            funds: selectedScheme.length !== 0 ? selectedScheme : fundNames,
            category: selectedCategory.length !== 0 ? selectedCategory : [],
            investorPan: '',
            start: 0,
            end: 2000, //Todo: need to handle from backend
            searchQuery: schemeSearchQuery,
            schemeFilterParams: {
              planMode: planModes.REGULAR,
            },
          };
          const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

          const resFundWithOpenKey = resFund
            .map((item: SchemeByfund) => ({
              ...item,
              open: false,
              key: nanoid(),
            }))
            ?.filter((item: any) => item.newPurAllow);
          const tempCategoryData = resFund
            ?.map((item: any) => item.category)
            ?.filter((category: string) => category !== null && category !== '');
          const uniQueCategory = [...new Set(tempCategoryData)] as string[];
          setSchemesByFund(resFundWithOpenKey);
          setSchemeCategoryData(uniQueCategory);
          setTempSchemeData(resFundWithOpenKey);
          setPage(0);
          if (selectedScheme.length === 0 && selectedCategory.length === 0) {
            setTempAllFundData(resFundWithOpenKey);
          }
        } catch (error: any) {
          showToast(error?.data?.message || 'unknown error', 'error');
        } finally {
          setLoading(false);
        }
      };
      getAllSchemes();
    }
  }, [getAllFundResponse]);

  useEffect(() => {
    try {
      getSchemes('');
      contextDispatch(setInvestmentDetails(null));
      contextDispatch(setFundName(''));
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    }
  }, []);

  const handleClick = (scheme: string) => {
    setSchemeName(scheme);
  };

  useEffect(() => {
    const filteredData = allFundData?.filter((item: any) =>
      item.fundName.toLowerCase().includes(query.toLowerCase()),
    );

    if (query.length > 0) {
      setAllFundData(filteredData);
    } else {
      setAllFundData(tempFundData);
    }
  }, [query]);

  const handleSearch = useDebounce(async (searchText: string) => {
    const allFunds = getAllFundResponse.data;
    const fundNames = allFunds.map((item: any) => item.fund);
    if (searchText.length > -1) {
      try {
        setLoading(true);
        const schemeByFundBody = {
          funds: selectedScheme.length !== 0 ? selectedScheme : fundNames,
          category: selectedCategory.length !== 0 ? selectedCategory : [],
          investorPan: '',
          start: 0,
          end: 2000, //Todo: need to handle from backend
          searchQuery: searchText,
          schemeFilterParams: {
            planMode: planModes.REGULAR,
          },
        };
        const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

        const resFundWithOpenKey = resFund
          .map((item: SchemeByfund) => ({
            ...item,
            open: false,
            key: nanoid(),
          }))
          ?.filter((item: any) => item.newPurAllow);
        setSchemesByFund(resFundWithOpenKey);
        setPage(0);
      } catch (error: any) {
        showToast(error?.data?.message || 'unknown error', 'error');
      } finally {
        setLoading(false);
      }
    }
  }, 500);

  const handleSearchTextChanged = (text: string) => {
    setSchemeSearchQuery(text);
    setPage(0);
    handleSearch(text);
  };

  return (
    <>
      <PageNavigator
        title='New Investor'
        backHandler={() => navigate('/transaction-dashboard', { replace: true })}
        sx={(theme: Theme) => ({
          [theme.breakpoints.down('md')]: {
            mt: 0,
          },
        })}
      />

      <Box
        sx={{
          borderRadius: { xs: '10px', sm: '25px' },
          bgcolor: 'text.boxColor',
          padding: { xs: '32px 30px 15px', sm: '35px 35px' },
          position: 'relative',
          mt: { xs: 5, sm: 0 },
        }}
      >
        {/*Mobile UI */}
        <Box
          sx={{
            display: { xs: 'block', sm: 'none' },
            position: 'absolute',
            top: '-20px',
            transform: 'translateX(-50%)',
            left: '50%',
          }}
        >
          <Clip_Icon />
        </Box>
        {/*Mobile UI */}
        <Grid container>
          <Grid
            item
            sm={4.5}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: { sm: '20px', md: '26px' },
                  fontWeight: 700,
                  color: 'primary.main',
                  mb: 2,
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                Onboarding New Investor
              </Typography>
              <Typography
                sx={{
                  fontSize: { sm: '14px', md: '16px' },
                  fontWeight: 400,
                  color: 'text.labelColor',
                  mb: 2,
                  lineHeight: '30px',
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                Seamless Investor Onboarding: Initiate your journey by selecting preferred
                schemes, then effortlessly create your investor folio, ensuring a
                straightforward and hassle-free investment process.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            sm={7.5}
            xs={12}
            sx={{
              borderLeft: { xs: 'unset', sm: '1px dashed' },
              borderColor: { sm: 'text.borderColorLight' },
            }}
          >
            <Box sx={{ pl: { xs: '0px', sm: '35px' } }}>
              <Grid
                container
                sx={{
                  'position': 'relative',
                  'justifyContent': { xs: 'space-between', sm: 'unset' },
                  '&:before': {
                    position: 'absolute',
                    width: { xs: 'calc(100% - 160px)', sm: '100%' },
                    bgcolor: 'text.stepperLine',
                    height: '1px',
                    content: '""',
                    top: '9px',
                    left: { xs: '75px', sm: 'unset' },
                  },
                }}
              >
                {steps.map((step, idx) => (
                  <Grid
                    item
                    sm={6}
                    key={idx}
                  >
                    <Box
                      sx={{
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'ml': { xs: step.index === 1 ? '55px' : 'auto', sm: 'unset' },
                        'mr': { xs: step.index === 2 ? '66px' : 'unset', sm: 'unset' },
                        'width': { xs: '18px', md: '22px' },
                        'height': { xs: '18px', md: '22px' },
                        // 'width': {'22px'},
                        // 'height': '22.627px',
                        'transform': 'rotate(-45deg)',
                        'borderRadius': '5px',
                        'background': step.background,
                        '& .MuiTypography-root': {
                          transform: 'rotate(45deg)',
                          textAlign: 'center',
                          fontSize: { xs: '12px', sm: '14px' },
                          fontWeight: 500,
                          color: step.index === 2 ? '' : 'common.white',
                        },
                      }}
                    >
                      <Typography>{step.index}</Typography>
                    </Box>
                    <Box sx={{ my: { xs: 'unset', sm: 3 }, pr: { xs: 'unset', sm: 2 } }}>
                      <Typography
                        sx={{
                          fontSize: { xs: '12px', sm: '14px' },
                          mb: { xs: 0, sm: 2 },
                          mt: { xs: 2, sm: 'unset' },
                          color: step.index === 2 ? 'text.labelColor' : '',
                          fontWeight: 500,
                        }}
                      >
                        {step.title}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: { xs: '12px', sm: '14px' },
                          fontWeight: 400,
                          color: 'text.labelColor',
                          lineHeight: '25px',
                          display: { xs: 'none', sm: 'block' },
                        }}
                      >
                        {step.subtitle}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Typography
        variant='subtitle1'
        sx={{ color: 'primary.main', my: 2 }}
      >
        Please select the scheme you want to purchase
      </Typography>
      {/* Mobile UI Search Filter */}
      <ResponsiveInvOnBoardingFilters
        allFundData={allFundData}
        schemeCategoryData={schemeCategoryData}
        query={query}
        setQuery={setQuery}
        selectedScheme={selectedScheme}
        setSelectedScheme={setSelectedScheme}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        chips={chips}
        setChips={setChips}
        setSchemesByFund={setSchemesByFund}
        schemeSearchQuery={schemeSearchQuery}
        setSchemeSearchQuery={setSchemeSearchQuery}
        setSchemeCategoryData={setSchemeCategoryData}
        setTempSchemeData={setTempSchemeData}
        setPage={setPage}
        setTempAllFundData={setTempAllFundData}
      />
      {/* Mobile UI Search Filter */}

      <Investorcard sx={{ mb: 4, display: { xs: 'none', sm: 'block' } }}>
        <Box
          sx={{
            padding: '15px 20px',
            borderBottom: '1px solid',
            borderColor: 'text.borderColorLight',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>
              {data.map((scheme, index) => {
                return (
                  <Chip
                    onClick={() => handleClick(scheme)}
                    key={index}
                    sx={(theme: Theme) => ({
                      'backgroundImage':
                        schemeName === scheme
                          ? `linear-gradient(180deg,  ${theme.palette.text.ceruleanBlue} 0%,  ${theme.palette.text.aquaBlue} 100%)`
                          : 'white',
                      'borderRadius': '7px',
                      'padding': '6px 15px',
                      'border': schemeName === scheme ? 'unset' : '0.5px solid',
                      'borderColor':
                        schemeName === scheme ? 'unset' : 'text.borderColorLight',
                      'mr': 2,
                      '& .MuiChip-label': {
                        fontSize: '14px',
                        fontWeight: '500',
                        color: schemeName === scheme ? 'common.white' : 'text.labelColor',
                      },
                    })}
                    label={scheme}
                  />
                );
              })}
            </Box>
            {/* {selectedScheme.length !== 0 && ( */}
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                rowGap: '20px',
              }}
            >
              <Button
                sx={{ color: 'text.clearText', fontSize: '14px' }}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setSelectedCategory([]);
                  setSelectedScheme([]);
                  setChips([]);
                  setSchemesByFund(tempAllFundData);
                }}
              >
                Clear All
              </Button>
              <Button
                sx={{ color: 'text.primary', fontSize: '14px' }}
                startIcon={<CloseIcon />}
                onClick={() => {
                  setCloseFilterOption(prev => !prev);
                }}
              >
                Filters
              </Button>
            </Stack>
            {/* )} */}
          </Box>
        </Box>
        {!closeFilterOption && (
          <Box sx={{ padding: '0px 20px 25px 20px', position: 'relative' }}>
            <Grid
              container
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                my: '20px',
              }}
            >
              <Grid
                item
                sm={9}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  columnGap={'10px'}
                >
                  <Typography
                    variant='subtitle2'
                    sx={{ my: 2, color: 'primary.main' }}
                  >
                    {schemeName}
                  </Typography>
                  <Button
                    sx={{ fontSize: '13px', color: 'text.clearText', fontWeight: 500 }}
                    onClick={handleClearClick}
                  >
                    Clear
                  </Button>
                </Stack>
              </Grid>
              {schemeName === 'AMC' && (
                <Grid
                  item
                  sm={3}
                >
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 2,
                        pointerEvents: 'none',
                        top: '6px',
                      }}
                    >
                      <SearchIcon />
                    </Box>

                    <TextField
                      sx={{
                        '& .MuiInputBase-root': {
                          'fontSize': '13px',
                          'color': 'text.labelColor',
                          'fontWeight': 500,
                          '& .MuiInputBase-input': {
                            border: 0,
                            padding: '6px 5px 6px 22px',
                          },
                        },
                      }}
                      value={query}
                      onChange={(e: any) => setQuery(e.target.value)}
                      placeholder='Search'
                      type='search'
                      variant='standard'
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            {!(allFundData?.length === 0 && !loading) ? (
              <Box
                sx={{
                  'maxHeight': '130px',
                  'overflow': 'hidden auto',
                  'pl': '10px',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                    height: '85px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    height: '6px',
                    border: '2px solid rgba(0, 0, 0, 0)',
                    backgroundClip: 'padding-box',
                    backgroundColor: 'rgba(133, 129, 129, 0.5)',
                    background: 'linear-gradient(180deg, #1D6CB0 0%, #10C1D0 100%)',
                    borderRadius: '84px',
                    boxShadow:
                      'inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05)',
                  },
                  '&::-webkit-scrollbar-button': {
                    display: 'none',
                    width: 0,
                    height: 0,
                  },
                  '&::-webkit-scrollbar-corner': {
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-track': {
                    borderRadius: '10px',
                    background: 'rgba(255, 255, 255, 0.8)',
                  },
                }}
              >
                <FormGroup row>
                  <Grid container>
                    {schemeName === 'AMC'
                      ? allFundData?.length
                        ? allFundData.map((item, index) => {
                            return (
                              <Grid
                                item
                                sm={4}
                                lg={3}
                                key={item.fund}
                              >
                                {loading && chips.length !== 0 ? (
                                  <Skeleton
                                    variant='text'
                                    width={'250px'}
                                  />
                                ) : (
                                  <FormControlLabel
                                    control={
                                      <CustomizedCheckbox
                                        checked={selectedScheme.includes(item?.fund)}
                                        onChange={async e =>
                                          handleCheckboxChange(index, e)
                                        }
                                      />
                                    }
                                    label={item.fundName}
                                    sx={{
                                      '& .MuiFormControlLabel-label': {
                                        fontSize: '13px',
                                        color: selectedScheme.includes(item.fund)
                                          ? 'primary.main'
                                          : 'text.labelColor',
                                        fontWeight: 400,
                                      },
                                    }}
                                  />
                                )}
                              </Grid>
                            );
                          })
                        : Array.from({ length: 12 }).map((item, index) => {
                            return (
                              <Grid
                                item
                                sm={4}
                                md={4}
                                key={index}
                              >
                                <Skeleton
                                  variant='text'
                                  width={'250px'}
                                />
                              </Grid>
                            );
                          })
                      : schemeCategoryData?.map((item: any, index: any) => {
                          return (
                            <>
                              <Grid
                                item
                                sm={3}
                                key={index}
                              >
                                <FormControlLabel
                                  control={
                                    <CustomizedCheckbox
                                      checked={selectedCategory.includes(item)}
                                      onChange={async e => handleCategoryChange(index, e)}
                                    />
                                  }
                                  label={item}
                                />
                              </Grid>
                            </>
                          );
                        })}
                  </Grid>
                </FormGroup>
              </Box>
            ) : (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontSize: { xs: '14px', sm: '16px', xl: '18px' } }}>
                    You currently have no Investments for this transaction
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Investorcard>
      <Investorcard
        sx={(theme: Theme) => ({
          mb: 4,
          [theme.breakpoints.down('sm')]: {
            boxShadow: 'unset',
            border: 'unset',
            p: 0,
          },
        })}
      >
        <Box
          sx={{
            padding: { xs: '0px', sm: '35px 25px 10px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography sx={{ color: 'text.valueColor', mb: { xs: '0px', sm: '20px' } }}>
              Search Results:
              <Typography
                component={'span'}
                sx={{ fontWeight: 500, ml: 0.5 }}
              >
                {schemesByFund?.length} Mutual Funds
              </Typography>
            </Typography>
            {/* {TODO: Need to integrate the functionality once received the data} */}
            {/* <Button
              sx={{
                color: 'text.clearText',
                fontSize: '12px',
                display: { xs: 'inline-flex', sm: 'none' },
              }}
              startIcon={<DeleteIcon />}
              onClick={() => {
                setChips([]);
                setSelectedCategory([]);
                setSelectedScheme([]);
                setSchemesByFund(tempAllFundData);
              }}
            >
              Clear All
            </Button> */}
            {/* <FormControl
              sx={{
                'display': { xs: 'none', sm: 'block' },
                '& .MuiInputBase-root ': {
                  '& .MuiTypography-root': {
                    fontSize: '12px',
                    fontWeight: 400,
                    color: 'text.inputLabelColor',
                  },

                  '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'text.borderColorLight',
                    },
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'primary.main',
                  },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'text.borderColorLight',
                  borderRadius: '5px',
                },
              }}
            >
              <Select
                sx={{
                  '& .MuiSelect-select': {
                    border: '0px',
                    padding: '8.5px 14px',
                    pl: '0px',
                    fontSize: '14px',
                    fontWeight: 500,
                    backgroundClip: 'text',
                    webkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundImage: 'linear-gradient(180deg, #215EAB 0%, #0FD0D6 100%)',
                  },
                }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                onChange={e => handleSortByChange(e)}
                value={sortBy !== '' ? sortBy : 'lowToHigh'}
                startAdornment={
                  <InputAdornment position='start'>Sort By:</InputAdornment>
                }
              >
                <MenuItem value='lowToHigh'>Risk - Low to High</MenuItem>
                <MenuItem value='highToLow'>Risk - High to Low</MenuItem>
              </Select>
            </FormControl> */}
          </Box>

          {chips.length !== 0 && (
            <Box
              sx={{
                borderRadius: '10px',
                border: '1px dashed',
                borderColor: 'text.borderColorLight',
                padding: '15px',
                my: 1,
                mb: { xs: 4, sm: '0' },
                display: 'flex',
                flexWrap: 'wrap',
                gap: '15px 10px',
              }}
            >
              {chips?.map((level: any, index: number) => (
                <Chip
                  key={index}
                  sx={{
                    '&.MuiButtonBase-root': {
                      'borderRadius': '7px',
                      'backgroundColor': 'text.dodgerBlue',
                      'display': 'inline-flex',
                      'padding': '4px 6px',
                      'justifyContent': 'center',
                      'alignItems': 'center',
                      'gap': { sm: '5px' },
                      'minWidth': 'max-content',
                      'transition': 'all 300ms',
                      '& .MuiChip-label': {
                        color: 'common.white',
                        fontSize: { xs: '12px', sm: '13px' },
                        fontWeight: 400,
                      },
                      '& .MuiSvgIcon-root ': {
                        color: 'common.white',
                        fontSize: { xs: '13px', sm: '16px' },
                      },
                      '&:hover': {
                        backgroundColor: 'text.dodgerBlue',
                      },
                    },
                  }}
                  label={level}
                  variant='outlined'
                  onDelete={async () => handleDelete(level)}
                />
              ))}
            </Box>
          )}
          {/* {TODO: Need to integrate the functionality once received the data} */}
          {/* {chips.length !== 0 && (
            <Button
              sx={{
                color: 'text.clearText',
                fontSize: '14px',
                display: { xs: 'none', sm: 'inline-flex' },
              }}
              startIcon={<DeleteIcon />}
              onClick={() => {
                setChips([]);
                setSelectedCategory([]);
                setSelectedScheme([]);
                setSchemesByFund(tempAllFundData);
              }}
            >
              Clear All
            </Button>
          )} */}
        </Box>
        <Box
          sx={{
            padding: '25px 15px',
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'text.borderColorLight',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <CustomSearch
            placeholder='Search'
            value={schemeSearchQuery}
            onChange={handleSearchTextChanged}
          />
        </Box>
        <RadioCollapsable
          loading={loading}
          schemeByFund={schemesByFund}
          setSchemesByFund={setSchemesByFund}
          tempSchemeData={tempSchemeData}
          setTempSchemeData={setTempSchemeData}
          allFundData={allFundData}
          page={page}
          rowsPerPage={rowsPerPage}
        />
        <TablePagination
          rowsPerPageOptions={[25, 50, 75]}
          component='div'
          count={schemesByFund?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Investorcard>
    </>
  );
}
