import { Box, Stack, Typography } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SwitchFromIcon from '../../../assets/switch-from.svg';
import SwitchToIcon from '../../../assets/switch-to.svg';
// import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import StyledRadioButtonGroup from '../../forms/FormStyledRadioButton';
import { CommonTable } from '../../transactionDashboard/investorTable';
import { createConvertedOutputArray } from '../common';
import Layout from '../Layout';
import { useListOfSchemesMutation } from '../slice';
import { investorSchemeDetailsColumns, TransactionAllow } from '../types';
import { inrCurrencyFormatter2 } from '../utils';
import NewSchemeTable from './NewSchemeTable';

const SwitchOut = ({
  handleSwitchOutContinue,
  type,
}: {
  handleSwitchOutContinue: (row: Record<string, string | number>) => void;
  type: 'SWITCH' | 'STP';
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [listOfSchemes] = useListOfSchemesMutation();
  const [schemeList, setSchemeList] = useState<any>([]);
  const { showToast } = useRootContext();
  const dispatch = useDispatch();
  const pan: string = useSelector((state: any) => state.transactions.pan);
  useEffect(() => {
    async function fetchData() {
      if (!pan) {
        return;
      }
      try {
        setLoading(true);
        const data = await listOfSchemes({ pan: pan.toUpperCase() }).unwrap();
        const filteredData = data
          ?.map((item: any) => ({
            ...item,
            key: nanoid(),
          }))
          ?.filter((scheme: any) => scheme.switchAllow);
        setSchemeList(filteredData);
      } catch (error: any) {
        showToast((error as { message: string }).message, 'error');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  return (
    <Box>
      <Stack
        direction={'row'}
        alignItems={'center'}
        columnGap={'15px'}
        marginBottom={'20px'}
      >
        <img
          src={SwitchFromIcon}
          alt='SwitchFromIcon'
        />

        <Typography
          variant='subtitle1'
          sx={{ color: 'primary.main' }}
        >
          Please select the scheme you want to {type === 'SWITCH' ? 'switch' : 'transfer'}{' '}
          from
        </Typography>
      </Stack>

      <CommonTable
        loading={loading}
        setLoading={setLoading}
        rows={createConvertedOutputArray(
          schemeList?.map((item: any) => ({
            fundName: item.amcName,
            schemeDesc: `${item.schemeName} - ${item.planMode} - ${item.optionDesc}`,
            folioNo: item.folio,
            availableAmount: (item.nav * item.units).toFixed(2),
            balanceUnits: item.units,
            exitLoad: item.exitLoad || '', //TODO: replace with exit load
            taxSaverFlag: item.taxsaverFlag,
            ...item,
          })),
          TransactionAllow[type],
        )} //TODO : optimise this line
        columns={['', 'AMC', 'Scheme Name', 'Folio Number', 'Available Amount']}
        start={0} // TODO: Starting page indexx
        limit={10} // TODO: Limit given
        searchPlaceholder={'Search by Name'}
        continueButtonHandler={row => handleSwitchOutContinue(row)}
        columnsToShow={Object.keys(investorSchemeDetailsColumns)}
      />
    </Box>
  );
};

const SwitchIn = ({
  switchOutData,
  type,
  onSubmit,
}: {
  switchOutData: Record<string, string | number>;
  type: 'SWITCH' | 'STP';
  onSubmit: (data: { fromData: any; toData: any; extraData?: any }) => void;
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [listOfSchemes] = useListOfSchemesMutation();
  const [isExistingScheme, setIsExistingScheme] = useState('E');
  const [schemeList, setSchemeList] = useState<any>([]);
  const { showToast } = useRootContext();
  const dispatch = useDispatch();

  const pan: string = useSelector((state: any) => state.transactions.pan);
  useEffect(() => {
    async function fetchData() {
      if (!pan) return;
      try {
        setLoading(true);
        const data = await listOfSchemes({ pan: pan?.toUpperCase() }).unwrap();
        const dataWithKey = data?.map((item: any) => ({
          ...item,
          key: nanoid(),
        }));
        setSchemeList(
          dataWithKey.filter(
            (scheme: any) =>
              scheme.fund === switchOutData.fund &&
              !(
                scheme.scheme === switchOutData.scheme &&
                scheme.plan === switchOutData.plan &&
                scheme.option === switchOutData.option
              ) &&
              (type === 'SWITCH' ? scheme.switchAllow : scheme.stpAllow),
          ),
        );
      } catch (error: any) {
        showToast((error as { message: string }).message, 'error');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <Box>
      <Stack
        direction={'row'}
        alignItems={'center'}
        columnGap={'15px'}
        marginBottom={'20px'}
      >
        <img
          src={SwitchToIcon}
          alt='SwitchToIcon'
        />
        <Typography
          sx={{
            color: 'primary.main',
            fontSize: { xs: '14px', sm: '16px', xl: '18px', fontWeight: 500 },
          }}
        >
          Please select the scheme you want to{' '}
          {type === 'SWITCH' ? 'switch-In' : 'transfer'} to
        </Typography>
      </Stack>
      <>
        <Box mb={2}>
          <StyledRadioButtonGroup
            options={[
              { label: 'Existing Scheme & Plan', value: 'E' },
              { label: 'New Scheme & Plan', value: 'N' },
            ]}
            value={isExistingScheme}
            handleChange={() => setIsExistingScheme(prev => (prev === 'N' ? 'E' : 'N'))}
          />
        </Box>
        <Typography sx={{ mb: 2, color: 'text.labelColor' }}>
          Fund can be switched to other {switchOutData.fundName} only
        </Typography>
      </>
      {isExistingScheme === 'E' ? (
        <CommonTable
          loading={loading}
          setLoading={setLoading}
          rows={createConvertedOutputArray(
            schemeList?.map((item: any) => ({
              fundName: item.amcName,
              schemeDesc: `${item.schemeName} - ${item?.planMode} - ${item.optionDesc}`,
              folioNo: item.folio,
              availableAmount: (item.nav * item.units).toFixed(2),
              balanceUnits: item.units,
              exitLoad: item.exitLoad || 'NA', //TODO: replace with exit load
              ...item,
            })),
            TransactionAllow[type],
          )} //TODO : optimise this line
          columns={['', 'AMC', 'Scheme Name', 'Folio Number', 'Available Amount']}
          start={0} // TODO: Starting page indexx
          limit={10} // TODO: Limit given
          searchPlaceholder={'Search by Name'}
          continueButtonHandler={row =>
            onSubmit({
              fromData: switchOutData,
              toData: row,
              extraData: { isExistingScheme },
            })
          }
          hideAmcDropdown
          columnsToShow={Object.keys(investorSchemeDetailsColumns)}
        />
      ) : (
        <NewSchemeTable
          loading={loading}
          setLoading={setLoading}
          onContinueButtonClick={(row, extraData) =>
            onSubmit({
              fromData: switchOutData,
              toData: row,
              extraData: { ...extraData, isExistingScheme },
            })
          }
          showFoliosDropdown={false}
          showAmcDropdown={false}
          selectedAmc={`${switchOutData.fund}`}
          filterByKey={TransactionAllow[type]}
        />
      )}
    </Box>
  );
};

export const SchemeFromAndTo = ({
  type,
  onSubmit,
}: {
  type: 'SWITCH' | 'STP';
  onSubmit: (data: { fromData: any; toData: any; extraData?: any }) => void;
}) => {
  const navigate = useNavigate();
  const [showSwitchIn, setShowSwitchIn] = useState(false);
  const [selectedSwitchOutData, setSelectedSwitchOutData] = useState<Record<
    string,
    string | number
  > | null>(null);

  function handleSwitchOutContinue(row: Record<string, string | number>) {
    setSelectedSwitchOutData(row);
    setShowSwitchIn(true);
  }

  let title = '';
  if (!showSwitchIn) {
    if (type === 'SWITCH') {
      title = 'Switch-Out';
    } else {
      title = 'Transfer From';
    }
  } else {
    if (type === 'SWITCH') {
      title = 'Switch-In';
    } else {
      title = 'Transfer To';
    }
  }

  const handleBackPress = () => {
    if (showSwitchIn) {
      setShowSwitchIn(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <Layout
      title={title}
      onBackPress={handleBackPress}
      rightBottomComponent={
        showSwitchIn && (
          <Box
            sx={{
              padding: '30px 20px',
              borderTop: '0.5px solid ',
              borderColor: 'text.borderColorLight',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                borderBottom: '1px dashed',
                borderColor: 'text.borderColorLight',
                pb: 2,
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    fontSize: { xs: '16px', xl: '18px' },
                  }}
                >
                  Switch From
                </Typography>
                <img
                  src={SwitchFromIcon}
                  alt='switch-from-icon'
                />
              </Stack>
              {Boolean(selectedSwitchOutData?.switchOutSchemeName) ||
                (Boolean(selectedSwitchOutData?.schemeDesc) && (
                  <Typography
                    sx={{
                      color: 'text.valueColor',
                      fontSize: { xs: '14px', xl: '16px' },
                      fontWeight: 500,
                    }}
                  >
                    {selectedSwitchOutData?.switchOutSchemeName ||
                      selectedSwitchOutData?.schemeDesc}
                  </Typography>
                ))}
              {Boolean(selectedSwitchOutData?.folio) && (
                <Stack gap='5px'>
                  <Typography sx={{ color: 'text.labelColor' }}>Folio Number</Typography>
                  <Typography
                    sx={{
                      color: 'text.valueColor',
                      fontSize: { xs: '14px', xl: '16px' },
                      fontWeight: 500,
                    }}
                  >
                    {selectedSwitchOutData?.folio}
                  </Typography>
                </Stack>
              )}
              {Boolean(selectedSwitchOutData?.balanceUnits) && (
                <Stack gap='5px'>
                  <Typography sx={{ color: 'text.labelColor' }}>
                    Total Balance Units
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.valueColor',
                      fontSize: { xs: '14px', xl: '16px' },
                      fontWeight: 500,
                    }}
                  >
                    {selectedSwitchOutData?.balanceUnits}
                  </Typography>
                </Stack>
              )}
              {Boolean(selectedSwitchOutData?.availableAmount) && (
                <Stack gap='5px'>
                  <Typography sx={{ color: 'text.labelColor' }}>
                    Total Available Amount
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.valueColor',
                      fontSize: { xs: '14px', xl: '16px' },
                      fontWeight: 500,
                    }}
                  >
                    {inrCurrencyFormatter2(selectedSwitchOutData?.availableAmount as any)}
                  </Typography>
                </Stack>
              )}
              {/* {
                <Stack gap='5px'>
                  <Typography sx={{ color: 'text.labelColor' }}>
                    Exit load by fund house
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.valueColor',
                      fontSize: { xs: '14px', xl: '16px' },
                      fontWeight: 500,
                    }}
                  >
                    {selectedSwitchOutData?.exitLoad || 'NA'}
                  </Typography>
                </Stack>
              } */}
            </Box>
          </Box>
        )
      }
    >
      {!showSwitchIn ? (
        <SwitchOut
          handleSwitchOutContinue={handleSwitchOutContinue}
          type={type}
        />
      ) : (
        <SwitchIn
          switchOutData={selectedSwitchOutData || {}}
          type={type}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};
