import { TabContext, TabList } from '@mui/lab';
import { Box, CardMedia, Grid, Stack, Tab, Theme, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ShimmerButton } from 'react-shimmer-effects';

import FRESH_SIP from '../../assets/SIP/fresh-sip.svg';
import LIVE_SIP from '../../assets/SIP/live-sip.svg';
import SIP_Book from '../../assets/SIP/sip-book.svg';
import SIP_Cease from '../../assets/SIP/sip-ceased.svg';
import SIP_Maturity from '../../assets/SIP/upcoming-sip-maturity.svg';
import SIP_Maturity_Amount from '../../assets/SIP/upcoming-sip-maturity-amount.svg';
import { Investorcard } from '../auth/login/styles/styledComponents';
import { PageNavigator } from '../common/BackToHomePage';
import SipDetailsTable from './SipDetailsTable';
import { useLazyGetSipSummaryQuery } from './slice';

export interface FundDetails {
  fundCode: string;
  fundName: string;
}
export interface BrokerPaymentDetails {
  amount: string;
  bankName: string;
  fromDate: string;
  paymode: string;
  refNo: number;
  toDate: string;
  trxnCount: number;
}
export interface AllData {
  value: number;
  totalAmount: number;
  tableData: BrokerPaymentDetails[];
  selectedFundObj: any; // TODO: change type
}

const SipDashboard = () => {
  const [tabValue, setTabValue] = React.useState('1');
  const [sipSummary, setSipSummary] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const items = [
    { name: 'SIP Book', rupees: sipSummary?.sipBook || 0, icon: SIP_Book },
    { name: `Live SIP's`, rupees: sipSummary?.liveSipCount || 0, icon: LIVE_SIP },
    { name: `Fresh SIP's`, rupees: sipSummary?.freshSip || 0, icon: FRESH_SIP },
    {
      name: 'Upcoming SIP Maturirty',
      rupees: sipSummary?.upcomingSipMaturity || 0,
      icon: SIP_Maturity,
    },
    {
      name: 'Upcoming SIP Maturity Amount',
      rupees: sipSummary?.upcomingSipMaturityAccount || 0,
      icon: SIP_Maturity_Amount,
    },
    { name: 'SIP Ceased', rupees: sipSummary?.sipCeased || 0, icon: SIP_Cease },
  ];
  const [getSipSummary, SipSummaryResponse] = useLazyGetSipSummaryQuery();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (
      SipSummaryResponse &&
      SipSummaryResponse.isSuccess &&
      !SipSummaryResponse.isFetching
    ) {
      setSipSummary(SipSummaryResponse.data);
    }

    if (
      SipSummaryResponse &&
      (SipSummaryResponse.isSuccess || SipSummaryResponse.isError)
    ) {
      setLoading(false);
    }
  }, [SipSummaryResponse]);

  React.useEffect(() => {
    getSipSummary('');
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <PageNavigator
        title='SIP Dashboard'
        backHandler={() => navigate(-1)}
        sx={(theme: Theme) => ({
          [theme.breakpoints.down('md')]: {
            mt: 0,
          },
        })}
      />
      <Grid
        container
        my={'20px'}
        spacing={2}
      >
        {items.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'position': 'relative',
                'borderRadius': '5px 10px 10px 5px',
                'border': '0.1px solid',
                'borderColor': 'text.borderColorLight',
                'bgcolor': 'common.white',
                'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                'padding': '25px',
                'display': 'flex',
                'alignItems': 'center',
                '&:before': {
                  content: `' '`,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '5px',
                  height: '100%',
                  background: theme.palette.background.primaryLinearGradient,
                  borderRadius: '15px 0px 0px 15px',
                },
              })}
            >
              <CardMedia
                component='img'
                image={item.icon}
                alt='image'
                sx={{
                  width: { xs: '55px', sm: '70px' },
                  height: { xs: '55px', sm: '70px' },
                  mr: 4,
                }}
              />
              <Stack>
                <Typography sx={{ color: 'text.labelColor', mb: 1 }}>
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: 700,
                    fontSize: { xs: '14px', lg: '18px', xl: '22px' },
                    display: 'flex',
                    columnGap: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: '14px', lg: '18px', xl: '22px' },
                      color: 'text.labelColor',
                      fontWeight: 400,
                    }}
                    component={'span'}
                  >
                    ₹
                  </Typography>{' '}
                  {loading ? <ShimmerButton size='sm' /> : item.rupees}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Investorcard
        sx={(theme: Theme) => ({
          position: 'relative',
          zIndex: '1',
          transition: 'width 0.2ms',
          cursor: 'pointer',
          height: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          mt: 5,
        })}
      >
        <Box sx={{ pt: 2, px: 2, width: '100%' }}>
          {' '}
          <TabContext value={tabValue}>
            <Box
              sx={{
                'borderBottom': '1px solid',
                'borderColor': 'text.borderColorLight',
                'mb': 4,
                '& .MuiTabs-root': {
                  '& .MuiTabs-flexContainer': {
                    'pl': '0px',
                    'columnGap': '25px',
                    '& .MuiButtonBase-root.MuiTab-root': {
                      'fontSize': { xl: '18px', lg: '16px', xs: '14px' },
                      'fontWeight': 500,
                      '&.Mui-disabled': {
                        color: 'rgba(108, 115, 127, 0.50)',
                      },
                    },
                  },
                  '& .MuiTabs-indicator': {
                    height: '5px',
                    borderRadius: '100px 100px 0px 0px',
                  },
                },
                '& .MuiTabPanel-root': {
                  padding: '50px 50px',
                },
              }}
            >
              <TabList
                onChange={handleTabChange}
                aria-label='lab API tabs example'
              >
                <Tab
                  label='Active'
                  value='1'
                />
                <Tab
                  label='Pending / Rejected'
                  value='2'
                />
              </TabList>
            </Box>
          </TabContext>
        </Box>

        {tabValue === '1' ? (
          <SipDetailsTable status='active' />
        ) : (
          <SipDetailsTable status='pending' />
        )}
      </Investorcard>
    </>
  );
};
export default SipDashboard;
