import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import { FieldSubHeader3, MainHeader } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { ReedemCartItem } from '../../transactions/cart/types';
import {
  useAddItemToCartMutation,
  useGetIndividualSchemeDetailsMutation,
  useListOfSchemesMutation,
  useModifyItemInCartMutation,
} from '../../transactions/slice';
import RenderSubmitCartButtons from '../common/SubmitCartButtons';
import { Investment, Reedem, SchemeDetails } from '../types';
interface InvestmentDetailsFormProps {
  data: any;
  isEditMode: boolean;
  handleCancelModifyingCart?: () => void;
  isFromCart?: boolean;
}
const InvestmentDetailsForm: React.FC<InvestmentDetailsFormProps> = ({
  data,
  isEditMode,
  handleCancelModifyingCart,
  isFromCart,
}) => {
  const pan: string = useSelector((state: any) => state.transactions.pan);
  // Add to cart reedemed item here.
  const formikRef = useRef<FormikProps<Reedem | ReedemCartItem>>(null);
  const navigate = useNavigate();
  const { showToast } = useRootContext();

  const [investorSchemes, setInvestorSchemes] = useState<Investment[]>([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [investorDetails, setInvestorDetails] = useState<SchemeDetails | null>(data);
  const [selectedOption, setSelectedOption] = useState<any>(data);

  const [addReedemedItemInCart] = useAddItemToCartMutation();
  const [investorDetailsList] = useListOfSchemesMutation();
  const [modifyItemInCart] = useModifyItemInCartMutation();
  const [individualSchemeDetails] = useGetIndividualSchemeDetailsMutation();
  const dispatch = useDispatch();

  const [schemeInformation, setSchemeInformation] = useState({
    availableUnits: data ? data.units : 0,
    availableAmount: data ? data.units * (data.nav || 0) : 0,
    formattedAmount: '',
    minAmt: data?.minTranAmount ?? 0,
    nav: data?.nav ?? 0,
    defaultBank: data?.defaultBank,
  });

  useEffect(() => {
    if (data) {
      const availableUnits = data.units || 0;
      const availableAmount = availableUnits * (data.nav || 0);
      const formattedAmount = availableAmount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      setSchemeInformation({
        availableUnits,
        availableAmount,
        formattedAmount,
        minAmt: data.minTranAmount ?? 0,
        nav: data.nav ?? 0,
        defaultBank: data.defaultBank,
      });
    }
  }, [data]);
  const firstButtonTitle = !isEditMode ? 'Add to Cart' : 'Cancel';
  const secondButtonTitle = !isEditMode ? 'Save & Proceed' : 'Update';

  const handleSubmit = async (values: Reedem | ReedemCartItem) => {
    if (isFromCart) {
      navigate('../../cart');
      return;
    }
    const {
      defaultBank,
      cartId,
      cartItemId,
      fund,
      folio,
      investorName,
      email,
      mobile,
      option,
      pan,
      scheme,
      schemeName,
      schemeDesc,
      taxSaver,
      folioNo,
      fundName,
      plan,
      planMode,
      optionDesc,
    } = data || {};
    const { bankAccNo, bankName, ifscCode, bankAcType } = isEditMode
      ? schemeInformation.defaultBank
      : defaultBank || {};
    const payload = isEditMode // Modify cart
      ? {
          cartId,
          cartItemId,
          ifscCode: ifscCode || '',
          bankName: bankName || '',
          bankAccountNumber: bankAccNo || '',
          bankAccountType: bankAcType?.toLowerCase() || 'SAVING',
          email: selectedOption ? selectedOption?.email : String(email),
          folio: selectedOption ? selectedOption?.folio : String(folio),
          fund:
            selectedOption && selectedOption.fund
              ? selectedOption.fund.trim()
              : fund
              ? fund.trim()
              : '',
          investorName: investorName,
          mobile: selectedOption ? selectedOption?.mobile : String(mobile),
          option: selectedOption ? selectedOption?.option : String(option),
          pan,
          partial: values.redeemType === 'Partial',
          plan: selectedOption ? selectedOption?.plan : String(plan),
          scheme: selectedOption ? selectedOption?.scheme : String(scheme),
          schemeName:
            selectedOption && selectedOption.schemeName !== ''
              ? `${selectedOption?.schemeName} - ${selectedOption?.planMode} - ${selectedOption?.optionDesc}`
              : schemeDesc,
          taxSaverFlag: taxSaver,
          amount:
            values?.redeemType === 'Partial' && values?.redeemMode === 'Amounts'
              ? String(values.reedemptionValue)
              : '0',

          units:
            (values?.redeemMode === 'Units' && values?.redeemType === 'Partial') ||
            values?.redeemType === 'Full'
              ? String(values.reedemptionValue)
              : '0',
          transactionType: 'RED',
          fundName,
          planMode: selectedOption ? selectedOption?.planMode : planMode,
          optionDesc: selectedOption ? selectedOption?.optionDesc : optionDesc,
          nav: selectedOption ? selectedOption?.nav : schemeInformation?.nav,
        }
      : {
          pan,
          email: email || '',
          mobile: mobile || '',
          investorName: investorName || '',
          folio: String(folioNo) || '',
          fund: fund.trim(),
          scheme,
          plan: data?.plan,
          option,
          amount:
            values?.redeemMode === 'Amounts' && values?.redeemType === 'Partial'
              ? String(values?.reedemptionValue || '')
              : '0',
          units:
            (values?.redeemType === 'Full' || values?.redeemType === 'Partial') &&
            values.redeemMode === 'Units'
              ? String(values?.reedemptionValue || '')
              : '0',
          partial: values?.redeemType === 'Partial',
          planMode: planMode,
          optionDesc: optionDesc,
          ifscCode: ifscCode || '',
          bankName: bankName || '',
          bankAccountNumber: bankAccNo || '',
          bankAccountType: bankAcType?.toLowerCase() || 'SAVING',
          schemeName: `${schemeDesc} - ${planMode} - ${optionDesc}` || '',
          taxSaverFlag: taxSaver || false,
          transactionType: 'RED',
          fundName,
        };
    //if edit mode hit update cart item and redirect user to cart page
    if (isEditMode) {
      try {
        dispatch(startLoading());
        await modifyItemInCart(payload).unwrap();
        showToast(`CartItem updated successfully`, 'success');
        if (handleCancelModifyingCart) handleCancelModifyingCart();
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    } else {
      navigate('/transaction-dashboard/confirmation', {
        state: { payload: payload, isEditMode: isEditMode },
      });
    }
  };

  const handleReedemTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedReedemType = event.target.value;
    formikRef.current?.setFieldValue('redeemType', selectedReedemType);
    if (selectedReedemType === 'Full') {
      formikRef.current?.setFieldValue('redeemMode', 'Units');
      formikRef.current?.setFieldValue(
        'reedemptionValue',
        schemeInformation.availableUnits,
      );
    } else formikRef.current?.setFieldValue('reedemptionValue', ''); // Clear the value if not "Full"
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        reedemptionValue: Yup.string().when('redeemMode', {
          is: 'Units',
          then: Yup.string()
            .required('Units are required')
            .matches(
              /^\d+(\.\d{1,3})?$/,
              'Units should be a number with up to three decimal places',
            )
            .test(
              'max-units',
              `Units cannot exceed ${schemeInformation.availableUnits}`,
              value => {
                const numericValue = parseFloat(value || '0');
                return numericValue <= schemeInformation.availableUnits;
              },
            ),
          otherwise: Yup.string()
            .required('Enter investment amount')
            .matches(/^[0-9]*\.?[0-9]+$/, 'Investment amount should be a number')
            .test(
              'max-amount',
              `Investment amount cannot exceed ${schemeInformation.formattedAmount}`,
              value => {
                const numericValue = parseFloat(value || '0');
                return numericValue <= schemeInformation.availableAmount;
              },
            )
            .test(
              'min-amount',
              `Minimum Amount required is ${investorDetails?.minTranAmount}`,
              value => {
                const numericValue = parseFloat(value || '0');
                return numericValue >= (investorDetails?.minTranAmount || 0);
              },
            ),
        }),
        bankName: Yup.string().required(
          'Please select Bank to proceed with the Transaction',
        ),
      }),
    [schemeInformation, investorDetails],
  );
  const handleAddToCartClick = async () => {
    const { bankAccNo, bankName, ifscCode, bankAcType } = data?.defaultBank || {};
    if ((formikRef.current?.values as Reedem).bankName === '') {
      showToast('Please select bank Name', 'error');
      return;
    }
    const payload = {
      pan: pan,
      mobile: data?.mobile || '',
      email: data?.email || '',
      fund: data?.fund,
      scheme: data?.scheme,
      plan: data?.plan,
      option: data?.option,
      investorName: data?.investorName || '',
      folio: data?.folioNo || '',
      fundName: data?.fundName,
      schemeName: `${data?.schemeDesc} - ${data?.planMode} - ${data?.optionDesc}`,
      amount:
        (formikRef.current?.values as Reedem).redeemType === 'Partial' &&
        (formikRef.current?.values as Reedem).redeemMode === 'Amounts'
          ? String((formikRef.current?.values as Reedem).reedemptionValue)
          : '0',
      units:
        (formikRef.current?.values as Reedem).redeemType === 'Full' ||
        ((formikRef.current?.values as Reedem).redeemType === 'Partial' &&
          (formikRef.current?.values as Reedem).redeemMode === 'Units')
          ? String((formikRef.current?.values as Reedem).reedemptionValue)
          : 0,
      partial:
        (formikRef.current?.values as Reedem).redeemType === 'Partial' ? true : false, // full or partial

      ifscCode: ifscCode || '',
      bankName: bankName || '',
      bankAccountNumber: bankAccNo || '',
      bankAccountType: bankAcType?.toLowerCase() || 'SAVING',
      transactionType: 'RED',
      planMode: data?.planMode || '', //optional
      optionDesc: data?.optionDesc || '', //optional
      taxSaverFlag: data?.taxSaver, // required always
      nav: data?.nav,
    };
    try {
      dispatch(startLoading());
      await addReedemedItemInCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleAddingItemInCart = () => {
    navigate('../../cart');
  };

  const handleAddAnotherTransactionBtn = () => {
    // Second button click logic
    setOpenDialogBox(false); // Close dialog after handling logic
    navigate('../');
  };

  // need to check this logic - todo
  const handleValidation = (investorDetails: any, values: Record<string, any>) => {
    const errors: Record<string, any> = {};
    const { redeemMode, redeemType, reedemptionValue } = values;
    let availableAmount = schemeInformation.availableAmount;
    let availableUnits = schemeInformation.availableUnits;
    let nav = schemeInformation.nav;
    let minAmt = schemeInformation.minAmt;
    if (isEditMode) {
      availableUnits = investorDetails?.units;
      availableAmount = availableUnits * (investorDetails?.nav || 0);
      minAmt = investorDetails?.minTranAmount;
    }

    if (
      values.reedemptionValue &&
      (isNaN(parseFloat(values.reedemptionValue)) ||
        Number(values.reedemptionValue) <= 0 ||
        /^0\d+/.test(values.reedemptionValue))
    ) {
      errors.reedemptionValue = `Enter valid value`;
    }

    const isPartial = redeemType === 'Partial';
    if (isPartial) {
      if (redeemMode === 'Units' && reedemptionValue * nav < minAmt) {
        errors.reedemptionValue = 'Not enough units to redeem.';
      }

      if (redeemMode === 'Amounts') {
        if (availableAmount * 1.005 < minAmt) {
          errors.reedemptionValue = `You do not have enough amount to redeem`;
        } else if (reedemptionValue < minAmt && minAmt < availableAmount) {
          errors.reedemptionValue = `Minimum Amount required is ${minAmt}`;
        } else if (reedemptionValue < minAmt)
          errors.reedemptionValue = `Minimum Amount required is ${minAmt}`;
      }
    }

    return errors;
  };

  function getInvestment(fund?: string, folio?: number): any[] {
    if (fund && folio) {
      return investorSchemes?.filter(inv => inv.fund == fund && inv.folio == folio);
    } else if (fund) {
      return investorSchemes?.filter(inv => inv.fund == fund);
    } else {
      return investorSchemes;
    }
  }

  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      if (!pan) return;
      try {
        dispatch(startLoading());
        const response = await investorDetailsList({ pan }).unwrap();
        const filteredSchemes = response
          ?.map((item: any) => ({
            ...item,
            key: nanoid(),
          }))
          ?.filter((item: { redemptionAllow: any }) => item.redemptionAllow);
        setInvestorSchemes(filteredSchemes);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    if (isEditMode) {
      getInvestorDetailsSchemes();
    }
  }, []);

  async function getSelectedInvestorDetailsSchemes(
    selectedObject: Investment | undefined,
    folio: string,
  ) {
    const payload = {
      pan: pan,
      folio: folio,
      fund: selectedObject?.fund,
      trtype: 'RED',
      scheme: selectedObject?.scheme,
      option: selectedObject?.schemeOption,
      plan: selectedObject?.schemePlan,
    };

    try {
      dispatch(startLoading());
      const response = await individualSchemeDetails(payload).unwrap();
      const availableUnits = response?.units || 0;
      const availableAmount = availableUnits * (response?.nav || 0);
      const formattedAmount = availableAmount?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      setSchemeInformation({
        availableUnits,
        availableAmount,
        formattedAmount,
        minAmt: response?.minTranAmount ?? 0,
        nav: response?.nav ?? 0,
        defaultBank: response.defaultBank,
      });
      setInvestorDetails(response);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }

  const handleSelectChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    values: any,
  ) => {
    const selectObject = Array.from(
      new Set(
        investorSchemes
          ?.filter((item: any) => {
            // Create a unique key from `schemeName` and `planDesc`
            const key = `${item.scheme} - ${item.plan} - ${item.option}`;
            return key === event.target.value;
          })
          .map((item: any) => JSON.stringify(item)),
      ),
    ).map(item => JSON.parse(item));
    formikRef.current?.setFieldValue('bankName', '');
    formikRef.current?.setFieldValue('reedemptionValue', '');
    formikRef.current?.setFieldValue('redeemType', 'Partial');

    await getSelectedInvestorDetailsSchemes(selectObject[0], values?.folioNumber);
    setSelectedOption(selectObject[0]);
  };

  return (
    <Formik
      initialValues={{
        redeemType: data?.partial || data?.partial === undefined ? 'Partial' : 'Full',
        redeemMode: data?.cartAmount > 0 ? 'Amounts' : 'Units',
        reedemptionValue:
          selectedOption?.folioNo !== data?.folioNo
            ? ''
            : data?.cartAmount > 0
            ? data?.cartAmount
            : data?.cartUnits || '',
        bankName: selectedOption?.folioNo !== data?.folioNo ? '' : data?.bankName || '',
        selectedAmc: data?.fundName || '',
        schemeName: `${data?.scheme} - ${data?.plan} - ${data?.option}`,
        folioNumber: data?.folioNo || data?.folio || '',
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formikRef}
      validate={values => handleValidation(investorDetails, values)}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Grid
            container
            spacing={2}
            sx={{
              'paddingBottom': '25px',
              '& .MuiButtonBase-root': {
                '&.MuiRadio-root': {
                  '&.Mui-checked': {
                    color: 'primary.main',
                  },
                },
              },
            }}
          >
            {isEditMode && (
              <>
                <Grid
                  item
                  sm={6}
                >
                  <Typography
                    sx={{
                      color: 'text.inputLabelText',
                      mb: 1.5,
                      fontWeight: 500,
                      fontSize: { xs: '14px', xl: '16px' },
                    }}
                  >
                    Selected AMC
                  </Typography>
                  <FormTextInput
                    name='selectedAmc'
                    label='selectedAmc'
                    disabled
                  />
                </Grid>

                <Grid
                  item
                  sm={6}
                >
                  <Typography
                    sx={{
                      color: 'text.inputLabelText',
                      mb: 1.5,
                      fontWeight: 500,
                      fontSize: { xs: '14px', xl: '16px' },
                    }}
                  >
                    Folio
                  </Typography>

                  <FormSelectInput
                    name='folioNumber'
                    label='folioNumber'
                    options={getInvestment(data?.fund).map(data => ({
                      value: data.folio,
                      label: data.folio,
                    }))}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <Typography
                    sx={{
                      color: 'text.inputLabelText',
                      mb: 1.5,
                      fontWeight: 500,
                      fontSize: { xs: '14px', xl: '16px' },
                    }}
                  >
                    Scheme
                  </Typography>

                  <FormSelectInput
                    name='schemeName'
                    label='schemeName'
                    options={getInvestment(data?.fund, (values as any)?.folioNumber).map(
                      data => ({
                        label: `${data.schemeName} - ${data.planMode} - ${data.optionDesc}`,
                        value: `${data.scheme} - ${data.plan} - ${data.option}`,
                      }),
                    )}
                    onChange={async (e: any) => handleSelectChange(e, values)}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                >
                  <Box sx={{ bgcolor: 'text.boxColor', padding: '20px 15px' }}>
                    <Grid
                      container
                      spacing={2}
                      alignItems={'center'}
                    >
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Balance Units:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {schemeInformation.availableUnits}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Current Value:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {schemeInformation.availableAmount.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            NAV as on Date:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {schemeInformation.nav}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Minimum Amount:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {schemeInformation.minAmt}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 2,
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                Redeem Type
              </Typography>
              <FormStyledRadioButton
                options={[
                  { label: 'Partial', value: 'Partial' },
                  { label: 'Full', value: 'Full' },
                ]}
                name='redeemType'
                handleChange={handleReedemTypeChange}
              />
            </Grid>

            {!(values.redeemType === 'Full') && (
              <Grid
                item
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 2,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Redeem Mode
                </Typography>
                <FormStyledRadioButton
                  options={[
                    { label: 'Units', value: 'Units' },
                    { label: 'Amounts', value: 'Amounts' },
                  ]}
                  name='redeemMode'
                  disabled={values.redeemType === 'Full'}
                  handleChange={() => {
                    setFieldValue('reedemptionValue', '');
                  }}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <Typography
                sx={{
                  color: 'text.labelColor',
                  my: 2,
                }}
              >
                Available Balance {values.redeemMode === 'Units' ? 'units' : 'amount'}:
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: 'text.valueColor',
                    ml: 1,
                  }}
                  component='span'
                >
                  {values.redeemMode === 'Units'
                    ? schemeInformation.availableUnits
                    : schemeInformation.formattedAmount}
                </Typography>
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 2,
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                Redemption Value (INR/UNITS)
              </Typography>

              <FormTextInput
                name='reedemptionValue'
                label={values.redeemMode === 'Units' ? 'Units' : 'Amount'}
                placeholder='Enter Amount'
                disabled={values.redeemType === 'Full' ? true : false}
                typeOfInput='number'
                onInput={(e: any) => {
                  if (e.target.value.length > 12) {
                    e.target.value = e.target.value.slice(0, 12);
                  }
                  setFieldValue('value', e.target.value);
                }}
              />
            </Grid>

            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 2,
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                Select Bank
              </Typography>

              <FormSelectInput
                name='bankName'
                label='Select Bank'
                options={
                  schemeInformation?.defaultBank
                    ? [
                        {
                          label:
                            schemeInformation?.defaultBank?.bankName +
                            ' - ' +
                            schemeInformation?.defaultBank?.bankAccNo,
                          value: schemeInformation?.defaultBank?.bankName,
                        },
                      ]
                    : []
                }
              />
            </Grid>
          </Grid>
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorDark',
              mt: 3,
              mb: 2,
            }}
          />
          <RenderSubmitCartButtons
            onAddToCartClick={
              !isEditMode ? handleAddToCartClick : handleCancelModifyingCart
            }
            firstButtonTitle={firstButtonTitle}
            secondButtonTitle={isFromCart ? 'Go to Cart' : secondButtonTitle}
            disableCondition={data ? false : true}
          />
          <CustomDialog
            isVisible={openDialogBox}
            firstButtonHandler={handleAddingItemInCart}
            secondButtonHandler={handleAddAnotherTransactionBtn}
            firstButtonTitle='Go to cart'
            secondButtonTitle='Add another Transaction'
            handleClose={() => {
              setOpenDialogBox(false);
            }}
            showSecondButton={true}
          >
            <Box sx={{ textAlign: 'center', pt: 2 }}>
              <img
                src={VerifiedIcon}
                alt='verified-icon'
              />
              <MainHeader sx={{ fontWeight: 500, mb: 2 }}>Added To Cart</MainHeader>
              <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                Scheme is added to investor cart
              </FieldSubHeader3>
            </Box>
          </CustomDialog>
        </Form>
      )}
    </Formik>
  );
};

export default InvestmentDetailsForm;
