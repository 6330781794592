import { Grid } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';

import PaymentModesForm from '../cart/paymentModesForm';
import { FormData } from '../switch/Confirmation';
import { AplTrtType, ModeTypeToTrtType, TRANSACTION_TYPE_TO_TITLE } from '../types';
import { inrCurrencyFormatter2 } from '../utils';
import { Mandate } from './types';

const Confirmation = ({ data, formikRef = null }: { data: any; formikRef?: any }) => {
  const [selectedMandate, setSelectedMandate] = useState<Mandate>({
    label: '',
    value: '',
    mandateLimit: 0,
    startDate: '',
    endDate: '',
    bankAccountNumber: '',
    bankName: '',
  });

  const aplPayload = {
    fund: data?.fund,
    pan: data?.pan,
    folio: data?.folio,
    trtype: ModeTypeToTrtType[data.modeType as keyof typeof ModeTypeToTrtType],
    modeOfRegistration: data?.modeOfRegistration,
    amount: data?.amount, // For lumpsumAmount
    installmentAmount: data?.installmentAmount, // For Sip
    isNriInvestor: data?.isNriInvestor || data?.invCategory,
    isPanBasedAotm: data?.isPanBasedAotm || false,
  };

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='Scheme'
            value={data?.schemeName}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='Folio'
            value={data?.folio}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='Mode of Transaction'
            value={
              aplPayload && aplPayload?.trtype
                ? AplTrtType[aplPayload?.trtype as keyof typeof AplTrtType]
                : TRANSACTION_TYPE_TO_TITLE[
                    data?.transactionType as keyof typeof TRANSACTION_TYPE_TO_TITLE
                  ]
            }
          />
        </Grid>

        {data?.installmentAmount > 0 && (
          <Grid
            item
            xs={12}
            sx={{ mb: 1.5 }}
          >
            <FormData
              label='SIP Installment Amount'
              value={inrCurrencyFormatter2(data.installmentAmount)}
            />
          </Grid>
        )}
        {Boolean(data?.sipStartDate) && (
          <Grid
            item
            xs={12}
            sx={{ mb: 1.5 }}
          >
            <FormData
              label='SIP Start Date'
              value={format(new Date(data?.sipStartDate as Date), 'dd/MM/yyyy')}
            />
          </Grid>
        )}
        {Boolean(data?.sipEndDate) && (
          <Grid
            item
            xs={12}
            sx={{ mb: 1.5 }}
          >
            <FormData
              label='SIP End Date'
              value={format(new Date(data?.sipEndDate as Date), 'dd/MM/yyyy')}
            />
          </Grid>
        )}
        {data?.amount > 0 && (
          <Grid
            item
            xs={12}
            sx={{ mb: 1.5 }}
          >
            <FormData
              label='Investment Amount'
              value={inrCurrencyFormatter2(data.amount)}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <PaymentModesForm
            aplPaymentsModePayload={aplPayload}
            selectedMandate={selectedMandate}
            setSelectedMandate={setSelectedMandate}
            formikRef={formikRef}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Confirmation;
