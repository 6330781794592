import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Theme,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import {
  DetailsHeader,
  Detailslabel,
  DetailsValue,
  Investorcard,
} from '../../../auth/login/styles/styledComponents';
import { Row, StyledTableCell, StyledTableRow } from '../../../common/DataTable';
import { StepperNextButton } from '../../../onboarding/styles/styledComponents';
function UpdateBank({
  folios,
  setFolios,
  type,
  handleUpdate,
}: {
  folios: any;
  setFolios: React.Dispatch<any>;
  type: string;
  handleUpdate: (selectedBanks: string[]) => void;
}) {
  const [selectedBanks, setSelectedBanks] = useState<string[]>(
    Array(folios.length).fill(''),
  );

  return (
    <>
      <Investorcard sx={{ padding: '20px 25px' }}>
        <Typography sx={{ fontSize: { xs: '14px', md: '16px', xl: '18px' }, mb: 2 }}>
          Selected Folio(s)
        </Typography>
        {folios.map((folio: any, index: number) => {
          const defaultBank = folio.banks?.find((bank: any) => bank.default === 'Y');
          return (
            <Box key={folio.folio}>
              <Paper
                elevation={0}
                sx={(theme: Theme) => ({
                  width: '100%',
                  mb: 2,
                  border: `0.3px solid ${theme.palette.text.borderColorDark}`,
                  bgcolor: 'common.white',
                  borderRadius: '15px',
                  boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
                  display: { xs: 'none', md: 'block' },
                  padding: '20px 20px',
                })}
              >
                <Box
                  sx={(theme: Theme) => ({
                    borderBottom: `1px solid ${theme.palette.text.borderColorLight}`,
                    mb: 2,
                  })}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <DetailsHeader sx={{ fontSize: '14px' }}>
                      {folio.amcName}
                    </DetailsHeader>
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                  <Grid
                    container
                    spacing={2}
                    mb={'20px'}
                  >
                    <Grid
                      item
                      sm={3}
                    >
                      <Detailslabel sx={{ mb: 0.5 }}>Primary Holder:</Detailslabel>
                      <DetailsValue>{folio.investorName}</DetailsValue>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                    >
                      <Detailslabel sx={{ mb: 0.5 }}>IFSC:</Detailslabel>
                      <DetailsValue>
                        {defaultBank?.ifsc?.toUpperCase() || 'N/A'}
                      </DetailsValue>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                    >
                      <Detailslabel sx={{ mb: 0.5 }}>Bank name:</Detailslabel>
                      <DetailsValue>{defaultBank?.name || 'N/A'}</DetailsValue>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                    >
                      <Detailslabel sx={{ mb: 0.5 }}>Folio No:</Detailslabel>
                      <DetailsValue>{folio.folio}</DetailsValue>
                    </Grid>
                  </Grid>
                </Box>
                <DetailsHeader sx={{ fontSize: '14px', mb: 2 }}>
                  {type === 'delete'
                    ? 'Choose bank account details to delete'
                    : 'Choose New Default Bank account details'}
                </DetailsHeader>
                <TableContainer>
                  <Table
                    aria-label='collapsible table'
                    sx={{
                      '& .MuiTableCell-root ': {
                        padding: '16px 12px',
                      },
                    }}
                  >
                    <TableHead
                      sx={{
                        '& .MuiTableCell-root.MuiTableCell-head': {
                          fontSize: '14px',
                          color: 'text.labelColor',
                          fontWeight: '500',
                          textAlign: 'left',
                        },
                      }}
                    >
                      <StyledTableRow>
                        {['BANK NAME', 'ACCOUNT NUMBER', 'IFSC', 'PRIMARY HOLDER'].map(
                          column => (
                            <StyledTableCell key={column}>{column}</StyledTableCell>
                          ),
                        )}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        '&.MuiTableBody-root': {
                          '& .MuiTableRow-root': {
                            'backgroundColor': 'common.white',
                            'cursor': 'pointer',
                            '&:hover': {
                              'backgroundColor': 'text.lightBlue',
                              '& .MuiTableCell-root ': {
                                'backgroundClip': 'text',
                                'webkitBackgroundClip': 'text',
                                'WebkitTextFillColor': 'transparent',
                                'backgroundImage':
                                  'linear-gradient(180deg, #215EAB 0%, #0FD0D6 100%)',
                                '& .MuiIconButton-root ': {
                                  '& path': {
                                    stroke: 'primary.main',
                                  },
                                },
                              },
                            },
                            '& .MuiTableCell-root ': {
                              color: 'text.primary',
                              fontSize: '14px',
                              fontWeight: '400',
                              textAlign: 'left',
                            },
                          },
                        },
                      }}
                    >
                      {(folio.banks || [])
                        .filter((bank: any) => bank.default !== 'Y')
                        .map((bank: any) => {
                          const isItemSelected = selectedBanks[index] === bank.accountNo;
                          const row = {
                            id: bank.accountNo,
                            bankName: bank.name,
                            ifsc: bank.ifsc,
                            primaryHolder: folio.investorName,
                          };
                          return (
                            <Row
                              key={bank.accountNo}
                              row={row}
                              selected={isItemSelected}
                              handleSelectClick={accountNo =>
                                setSelectedBanks(prev =>
                                  prev.map((bank, i) =>
                                    i === index ? (accountNo as string) : bank,
                                  ),
                                )
                              }
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          );
        })}
      </Investorcard>

      {/* <StepperNextButton onClick={() => setFolios([])}>Cancel</StepperNextButton> */}
      <StepperNextButton
        onClick={() => handleUpdate(selectedBanks)}
        sx={{ minWidth: '200px', mt: 3 }}
      >
        Update
      </StepperNextButton>
    </>
  );
}

export default UpdateBank;
