import { styled, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ShimmerTable } from 'react-shimmer-effects';

import { ReactComponent as NoDataIcon } from '../../assets/no-data-table.svg';
import { useRootContext } from '../data/root.context';
import { dateToDDMMYYYY } from '../transactions/mfs/newInvestorOnboarding/utils';
import { useGetSipDetailsMutation } from './slice';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  'backgroundColor': theme.palette.common.white,
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
interface Data {
  inv_name: string;
  scheme: string;
  folio_number: string;
  number_of_installments: string;
  start_date_of_sip: Date;
  end_date_of_sip: Date;
  amount: number;
  mode_of_registration: string;
  status_of_registration: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const activeHeadCells: readonly HeadCell[] = [
  {
    id: 'inv_name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'scheme',
    numeric: false,
    disablePadding: false,
    label: 'Scheme',
  },
  {
    id: 'folio_number',
    numeric: true,
    disablePadding: false,
    label: 'Folio Number',
  },
  {
    id: 'number_of_installments',
    numeric: true,
    disablePadding: false,
    label: 'No of Installments',
  },
  {
    id: 'start_date_of_sip',
    numeric: false,
    disablePadding: false,
    label: 'Start Date',
  },
  {
    id: 'end_date_of_sip',
    numeric: false,
    disablePadding: false,
    label: 'End Date',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'mode_of_registration',
    numeric: false,
    disablePadding: false,
    label: 'Mode of Registration',
  },
];

const pendingHeadCells: readonly HeadCell[] = [
  {
    id: 'inv_name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'scheme',
    numeric: false,
    disablePadding: false,
    label: 'Scheme',
  },
  {
    id: 'folio_number',
    numeric: true,
    disablePadding: false,
    label: 'Folio Number',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'mode_of_registration',
    numeric: false,
    disablePadding: false,
    label: 'Mode of Registration',
  },
  {
    id: 'status_of_registration',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  status: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, status } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={(theme: Theme) => ({
        // '&,& .MuiTableCell-root.MuiTableCell-head': {
        //   background: '#F4F6F8',
        // },
        '& .MuiTableCell-root.MuiTableCell-head': {
          fontSize: '14px',
          color: theme.palette.text.labelColor,
          fontWeight: '500',
          padding: '16px',
          [theme.breakpoints.between(768, 1024)]: {
            fontSize: '13px',
          },
        },
      })}
    >
      <StyledTableRow
        sx={(theme: Theme) => ({
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
          // hide last border
          'backgroundColor': theme.palette.common.white,
          '&:last-child td, &:last-child th': {
            border: 0,
          },
        })}
      >
        {(status === 'active' ? activeHeadCells : pendingHeadCells).map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component='span'
                  sx={visuallyHidden}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

export default function SipDetailsTable({ status }: { status: 'active' | 'pending' }) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('folio_number');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sipDetailsData, setSipDetailsData] = React.useState<any>([]);
  const [getSipDetails] = useGetSipDetailsMutation();
  const { showToast } = useRootContext();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(true);
  React.useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const sipData = await getSipDetails({ status }).unwrap();
        setSipDetailsData(sipData);
      } catch (error: any) {
        showToast(error?.data?.message || 'Something went wrong', 'error');
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [status]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(sipDetailsData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, sipDetailsData],
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = rowsPerPage - visibleRows.length;

  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <ShimmerTable
          row={5}
          col={5}
        />
      ) : (
        <>
          <TableContainer>
            <Table aria-labelledby='tableTitle'>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={sipDetailsData.length}
                status={status}
              />

              <TableBody
                sx={{
                  '&.MuiTableBody-root': {
                    '& .MuiTableRow-root': {
                      'backgroundColor': 'common.white',
                      'cursor': 'pointer',
                      '& .MuiTableCell-root ': {
                        '& .MuiTypography-root': {
                          color: 'common.black',
                          fontSize: '14px',
                          fontWeight: '400',
                          textAlign: 'left',
                        },
                      },
                    },
                  },
                }}
              >
                {visibleRows.length > 0 ? (
                  <>
                    {visibleRows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow
                          sx={(theme: Theme) => ({
                            borderBottom: `1px solid ${theme.palette.text.borderColorDark}`,
                            bgcolor: 'common.white',
                          })}
                          tabIndex={-1}
                          key={labelId}
                        >
                          <TableCell>
                            <Typography>{row?.inv_name || 'NA'}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography> {row?.scheme || 'NA'}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography> {row?.folio_number || 'NA'}</Typography>
                          </TableCell>
                          {status === 'active' && (
                            <>
                              <TableCell>
                                <Typography>
                                  {' '}
                                  {row?.number_of_installments || 'NA'}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {' '}
                                  {dateToDDMMYYYY(row?.start_date_of_sip, '-') || 'NA'}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {' '}
                                  {dateToDDMMYYYY(row?.end_date_of_sip, '-') || 'NA'}
                                </Typography>
                              </TableCell>
                            </>
                          )}
                          <TableCell>
                            <Typography> {row?.amount || 'NA'}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography> {row?.mode_of_registration || 'NA'}</Typography>
                          </TableCell>
                          {status === 'pending' && (
                            <TableCell>
                              <Typography>
                                {' '}
                                {row?.status_of_registration || 'NA'}
                              </Typography>
                            </TableCell>
                          )}
                        </StyledTableRow>
                      );
                    })}
                    {/* {emptyRows > 0 && (
                    <StyledTableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={status === 'active' ? 8 : 6} />
                    </StyledTableRow>
                  )} */}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={status === 'active' ? 8 : 6}>
                      <Box
                        sx={{
                          display: sipDetailsData.length ? 'none' : 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: '5% auto',
                        }}
                      >
                        <NoDataIcon />
                        <Typography
                          sx={{
                            fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                            fontWeight: 500,
                            color: 'text.labelColor',
                            my: 4,
                          }}
                        >
                          You currently have no Investments for this transaction
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={sipDetailsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
}
