import { Box, Grid, IconButton, Stack, SxProps, Typography } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as AddIcon } from '../../assets/rounded-add-icon.svg';
import {
  StepperAddButton,
  StepperNextButton,
} from '../auth/login/styles/styledComponents';
import { PopupDialogBox } from '../common/AllDialogs/PopupDialog';
import { HomePage } from '../common/BackToHomePage';
import { useRootContext } from '../data/root.context';
import { LoginResponse } from '../features/login/loginSlice';
import FormCheckbox from '../forms/FormCheckbox';
import SubmitButton from '../forms/FormSubmitButton';
import AddBankAccount from './bankDetails/AddBank';
import UploadChequeCopy from './bankDetails/UploadCheque';
import {
  useDeleteBankAccountMutation,
  useLazyGetAllBankAccountsQuery,
  useSaveBankDetailMutation,
  useUpdateKydBankDetailMutation,
  useUploadBlankChequeIndividualMutation,
} from './slices';

const COMPONENT = {
  SHOW_ALL: 'SHOW_ALL',
  MAIN_PAGE: 'MAIN_PAGE',
};

interface BankCountBoxProps {
  handleDeleteAccounts: any;
  id?: string;
  index: number;
  totalAccountsLength?: number;
  isEdit?: boolean;
  name?: string;
  showIcon: boolean;
  sx?: SxProps;
}

export const BankCountBox: React.FC<BankCountBoxProps> = ({
  handleDeleteAccounts,
  index,
  id,
  totalAccountsLength = 0,
  isEdit,
  name,
  showIcon,
  sx,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px',
        bgcolor: 'text.boxColorLight',
        padding: '18px 20px',
        height: '52px',
        ...sx,
      }}
    >
      <Typography sx={{ fontWeight: 500 }}>{`${name} ${index}`}</Typography>
      {showIcon &&
        (!isEdit ? (
          (totalAccountsLength > 1 && id) || !id ? (
            <IconButton>
              <DeleteIcon onClick={() => handleDeleteAccounts(id)} />
            </IconButton>
          ) : null
        ) : (
          <IconButton onClick={handleDeleteAccounts}>
            <EditIcon />
          </IconButton>
        ))}
    </Box>
  );
};

interface ShowAllBanksProps {
  totalAccounts: any[];
  // eslint-disable-next-line no-unused-vars
  handleDeleteAccounts: any;
}
interface Account {
  banktoken: string;
  accountType: string;
  accountNumber: string;
  verifiedAccountNumber: string;
  isDefault: boolean;
  ifsc: string;
  bankName: string;
  ifscCodeEntered: boolean;
  id: string;
}

// To be used for fetched bank accounts.
interface FetchedBankAccountKyd {
  accountType: string;
  accountNumber: string;
  ifsc: string;
  bankName: string;
  isDefault: boolean;
  id: string;
}
const ShowAllBanks: React.FC<ShowAllBanksProps> = ({
  totalAccounts,
  handleDeleteAccounts,
}) => {
  return (
    <Box>
      {totalAccounts &&
        totalAccounts.length > 0 &&
        totalAccounts.map((account, index) => (
          <Box
            key={index}
            sx={{
              marginBottom: '20px',
              boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
              borderRadius: '15px',
            }}
          >
            <Box
              sx={{
                boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
                borderRadius: '15px',
                border: '0.3px solid',
                borderColor: 'text.borderColorDark',
              }}
            >
              <BankCountBox
                handleDeleteAccounts={handleDeleteAccounts}
                index={index + 1}
                id={account.id}
                totalAccountsLength={totalAccounts.length}
                isEdit={false}
                name={'Bank'}
                showIcon={true}
                sx={{
                  borderRadius: '15px 15px 0px 0px',
                }}
              />
              <Box sx={{ padding: '5px 20px 0px' }}>
                <Grid
                  container
                  my='5px'
                  spacing={2}
                  alignItems='center'
                  sx={{
                    '&>.MuiGrid-item ': {
                      pb: 2,
                    },
                    '& .MuiTypography-root': {
                      fontSize: '14px',
                    },
                    '& .MuiTypography-h5 ': {
                      fontWeight: '400',
                      color: 'text.labelColor',
                    },
                    '& .MuiTypography-body1 ': {
                      fontWeight: '500',
                      color: 'text.valueColor',
                    },
                  }}
                >
                  {/* <Grid
                    item
                    xs={2.5}
                    md={1.5}
                  >
                    <Box
                      sx={{
                        borderRadius: '5px',
                        border: '0.5px solid',
                        borderColor: 'text.borderColorLight',
                        boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: { xs: '6px 10px', lg: '15px 10px' },
                        height: '55px',
                        width: '55px',
                      }}
                    >
                      <img
                        src={AxisBank}
                        alt='axis-bank'
                        width='36px'
                      />
                    </Box>
                  </Grid> */}

                  <Grid
                    item
                    xs={9.5}
                    md={4}
                  >
                    <Stack gap='10px'>
                      <Typography variant='h5'>Bank Name</Typography>
                      <Typography component='span'>{account?.bankName}</Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={4}
                  >
                    <Stack gap='10px'>
                      <Typography variant='h5'>Account Number</Typography>
                      <Typography component='span'>{account?.accountNumber}</Typography>
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    md={2.5}
                  >
                    <Stack gap='10px'>
                      <Typography variant='h5'>IFSC Code</Typography>
                      <Typography component='span'>{account?.ifsc}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        ))}
    </Box>
  );
};
interface BankDetailsProps {
  fromdistrubutorProfile?: boolean;
  individual?: boolean;
}
export interface BankDetailsFormValues {
  bankList: BankValues[];
}
export interface BankValues {
  accountType: string;
  accountNumber: string;
  verifiedAccountNumber: string;
  isDefault: boolean;
  ifsc: string;
  bankName: string;
  ifscCodeEntered: boolean;
  id?: string;
}
const validationSchema = Yup.object().shape({
  bankList: Yup.array().of(
    Yup.object().shape({
      accountNumber: Yup.string()
        .required('Account number is required')
        .min(10, 'Account number should be at least 10 digits')
        .max(16, 'Account number should not be greater than 16 digits'),
      // .test('accountNumberMatch', 'Account numbers do not match', function (value) {
      //   return value === this.parent.verifiedAccountNumber;
      // }),
      verifiedAccountNumber: Yup.string()
        .required('Please verify Account number')
        .test('accountNumberMatch', 'Account numbers do not match', function (value) {
          return value === this.parent.accountNumber;
        }),
      ifsc: Yup.string().required('IFSC code is required'),
      accountType: Yup.string().required('You must choose one of the account types.'),
    }),
  ),
});
const kydValidationSchema = Yup.object().shape({
  bankList: Yup.array().of(
    Yup.object().shape({
      accountType: Yup.string()
        .required('Account type is required.')
        .oneOf(['Savings', 'Current'], 'Invalid account type.'),
    }),
  ),
});

export const BankDetails: React.FC<BankDetailsProps> = props => {
  const { fromdistrubutorProfile } = props;
  const [totalAccounts, setTotalAccounts] = useState<any[]>([]);
  const [enteredAccounts, setEnteredAccounts] = useState<Account[]>([]);
  const [component, setComponent] = useState<string>('');
  const [isAddedData, setIsAddedData] = useState<boolean>(false);
  const [hasAccountValidationFailed, setHasValidationFailed] = useState<boolean>(false);

  const [uploadBlankCheque] = useUploadBlankChequeIndividualMutation();
  const [saveBankDetail] = useSaveBankDetailMutation();
  const [deleteAccountById] = useDeleteBankAccountMutation();
  const [getAllBanks, allAccounts] = useLazyGetAllBankAccountsQuery();
  const [updateKydBankDetail] = useUpdateKydBankDetailMutation();
  const [areKydUpdated, setAreKydUpdated] = useState(false);
  const [initialValues, setInitialValues] = useState<BankDetailsFormValues>({
    bankList: [
      {
        accountType: '',
        accountNumber: '',
        verifiedAccountNumber: '',
        isDefault: false,
        ifsc: '',
        bankName: '',
        ifscCodeEntered: false,
      },
    ],
  });

  const navigate = useNavigate();

  const [files, setFiles] = useState<File[]>([]);
  const [isFromKyd, setIsFromKyd] = useState<boolean>(false);
  const [currentBankAccountToken, setCurrentBankAccountToken] = useState<string>('');
  const { showToast } = useRootContext();
  const isFirstRender = useRef(true);
  const [idCounter, setIdCounter] = useState<number>(1);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const { isIndividual } = useSelector((state: { login: LoginResponse }) => state.login);
  const makeFormData = (values: any, file: any) => {
    const formData = new FormData();
    const accountType = values.accountType === 'Savings' ? 'saving' : 'current';
    formData.append('accountNumber', values.accountNumber);
    formData.append('type', 'cancelled-cheque');
    formData.append('ifsc', values.ifsc);
    formData.append('accountType', accountType);
    formData.append('isDefault', values.isDefault);
    formData.append('bankname', values.bankName);
    formData.append('file', file);

    return formData;
  };

  {
  }
  useEffect(() => {
    const { isSuccess, isFetching, data } = allAccounts;

    const filterByAccountType = (
      accountType: string,
    ): FetchedBankAccountKyd[] | undefined =>
      data?.bankAccounts?.filter(
        ({ accountType: accType }: { accountType: string }) =>
          accType.toLowerCase() === accountType.toLowerCase(),
      );

    const mapToInitialValues = (accounts: FetchedBankAccountKyd[]) =>
      accounts.map(
        ({ accountNumber, ifsc, bankName, isDefault, id }: FetchedBankAccountKyd) => ({
          accountType: '',
          accountNumber,
          verifiedAccountNumber: accountNumber,
          isDefault,
          ifsc,
          bankName,
          ifscCodeEntered: false,
          id,
        }),
      );

    const updateAccountsAndComponent = () => {
      if (data?.bankAccounts?.length && data?.bankAccounts?.length > 0) {
        const kydAccounts = filterByAccountType('KYD');
        const mappedInitialValues = {
          bankList: kydAccounts ? mapToInitialValues(kydAccounts) : [],
        };

        if (mappedInitialValues.bankList.length > 0) {
          setInitialValues(mappedInitialValues);
          setIsFromKyd(true);
          setComponent(COMPONENT.MAIN_PAGE);
        } else {
          setTotalAccounts(data?.bankAccounts);
          setComponent(COMPONENT.SHOW_ALL);
        }
      } else {
        setComponent(COMPONENT.MAIN_PAGE);
      }
    };

    if (isSuccess && !isFetching && isFirstRender.current) {
      updateAccountsAndComponent(); // check if kyd exists
      isFirstRender.current = false;
    } else if (isSuccess && !isFetching && !isFirstRender.current) {
      setTotalAccounts(data.bankAccounts);
    }

    if (isFirstRender.current) {
      getAllBanks('');
    }
  }, [allAccounts]);

  const addBankAccount = async (values: any, file: File) => {
    if (hasAccountValidationFailed && currentBankAccountToken === '') {
      const formData = makeFormData(values.bankList[0], file);
      const uploadResponse: any = await uploadBlankCheque(formData);

      if (uploadResponse.data) {
        const newEnteredAccount = {
          id: idCounter.toString(),
          ...values.bankList[0],
          banktoken: uploadResponse.data.banktoken,
        };
        setEnteredAccounts((prevAccounts: any[]) => [...prevAccounts, newEnteredAccount]);
        setIdCounter(prevCounter => prevCounter + 1);
      } else {
        throw new Error(uploadResponse.error.data.message || 'Unknown error occurred');
      }
    } else {
      // when account is verified successfully
      setEnteredAccounts((prevAccounts: any[]) => [
        ...prevAccounts,
        {
          id: idCounter.toString(),
          ...values.bankList[0],
          banktoken: currentBankAccountToken,
        },
      ]);
      setCurrentBankAccountToken('');
      setIdCounter(prevCounter => prevCounter + 1);
    }
  };

  const saveAllBankDetails = async () => {
    try {
      // Get all the tokens from enteredAccounts array
      // if (enteredAccounts && enteredAccounts?.length > 0) {
      const bankTokens = enteredAccounts.map(account => account.banktoken);
      await saveBankDetail({ tokens: bankTokens }).unwrap();
      // getAllBanks after saving the bank details
      // getAllBanks('');
      // }

      showToast('Bank details saved successfully.', 'success');
    } catch (error) {
      showToast('Failed to save bank details', 'error');
    }
  };

  const handleSubmit = async (
    values: any,
    { resetForm, setSubmitting, setErrors }: any,
    action: string,
    files: File[],
  ) => {
    if (enteredAccounts.length + totalAccounts.length >= 5) {
      showToast('You cannot add more than 5 bank accounts.', 'error');
      setSubmitting(false);
      setErrors({});

      return;
    }
    // if (!files || !files[0]) {
    //   showToast('Please select a file.', 'error');
    //   setSubmitting(false);
    //   setErrors({});
    //   return;
    // }
    try {
      await addBankAccount(values, files[0]);

      if (component === COMPONENT.MAIN_PAGE) {
        setComponent(COMPONENT.SHOW_ALL);
        setHasValidationFailed(false);
      } else {
        setIsAddedData(false);
      }
      setFiles([]);
    } catch (error: any) {
      showToast((error as { message: string }).message, 'error');
    }
    setHasValidationFailed(false);
    resetForm();
  };

  const handleKydDetails = async (values: any, { resetForm }: any) => {
    try {
      const payload = {
        banks: values.bankList.map(({ accountType, isDefault, id }: Account) => ({
          accountType: accountType === 'Savings' ? 'saving' : 'current',
          isDefault: String(isDefault), // Convert boolean to string
          id: id,
        })),
      };
      await updateKydBankDetail(payload).unwrap();
      setComponent(COMPONENT.SHOW_ALL);
      setAreKydUpdated(true);
      setInitialValues({
        bankList: [
          {
            accountType: '',
            accountNumber: '',
            verifiedAccountNumber: '',
            isDefault: false,
            ifsc: '',
            bankName: '',
            ifscCodeEntered: false,
          },
        ],
      });
      getAllBanks('');
    } catch (error: any) {
      showToast(
        (error.data as { message: string }).message || 'Unknown error occured.',
        'error',
      );
    }
    resetForm();
  };

  const navigationHandler = () => {
    if (!fromdistrubutorProfile) {
      !isIndividual
        ? navigate('../auth-sign')
        : navigate('/transaction-dashboard/dashboard');
    }
  };

  const handleDeleteAccounts = async (id: string) => {
    const isTotalAccount = totalAccounts.some(account => account.id === id);

    if (isTotalAccount) {
      try {
        await deleteAccountById({ id }).unwrap();
        setTotalAccounts(prevAccounts =>
          prevAccounts.filter(account => account.id !== id),
        );
        // TODO: Need to get the response changed
        showToast('Account deleted successfully.', 'success');
      } catch (err) {
        showToast('Failed to delete bank account', 'error');
      }
    } else {
      setEnteredAccounts(prevAccounts =>
        prevAccounts.filter(account => account.id !== id),
      );
      showToast('Removed Account successfully.', 'success');
    }
  };

  const boxStyling = {
    mt: 5,
    pt: 5,
    borderTop: '0.5px dashed',
    borderColor: 'text.borderColorLight',
    width: '100%',
  };
  return (
    <>
      <Box sx={fromdistrubutorProfile ? {} : boxStyling}>
        {!fromdistrubutorProfile && (
          <>
            <Stack
              direction='row'
              alignItems='center'
            >
              <HomePage />

              <Typography
                sx={{
                  fontSize: { xs: '18px', sm: '20px', xl: '24px' },
                  fontWeight: 600,
                  mb: '10px',
                }}
              >
                Bank Details
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontSize: { xs: '14px', xl: '16px' },
                mb: '40px',
                color: 'text.labelColor',
              }}
            >
              Please provide your bank details for secure financial transactions.
            </Typography>
          </>
        )}
        <Box
          sx={{
            mt: 5,
          }}
        >
          {component === COMPONENT.MAIN_PAGE && (
            <>
              <Formik
                initialValues={initialValues}
                onSubmit={(values, formikHelpers) => {
                  if (!areKydUpdated && isFromKyd) {
                    handleKydDetails(values, formikHelpers);
                  } else {
                    handleSubmit(values, formikHelpers, 'SHOW_ALL', files);
                  }
                }}
                validationSchema={
                  isFromKyd && !areKydUpdated ? kydValidationSchema : validationSchema
                }
                enableReinitialize
              >
                {({ values, setFieldValue, setFieldError, setValues, errors }) => {
                  return (
                    <Form>
                      <FieldArray name='bankList'>
                        {({ push, remove }) => (
                          <>
                            {values.bankList.length > 0 &&
                              values.bankList.map((bank: any, index: number) => (
                                <Box key={index}>
                                  <BankCountBox
                                    handleDeleteAccounts={() => {}}
                                    index={index + 1}
                                    isEdit={false}
                                    name={'Bank'}
                                    showIcon={false}
                                  />
                                  <AddBankAccount
                                    index={index}
                                    setFieldError={setFieldError}
                                    isFromKyd={isFromKyd}
                                    setHasValidationFailed={setHasValidationFailed}
                                    setCurrentBankAccountToken={
                                      setCurrentBankAccountToken
                                    }
                                  />
                                  {hasAccountValidationFailed && (
                                    <UploadChequeCopy
                                      files={files}
                                      setFiles={setFiles}
                                      hasAccountValidationFailed={
                                        hasAccountValidationFailed
                                      }
                                      index={index}
                                    />
                                  )}
                                  <Box sx={{ my: 2 }}>
                                    <FormCheckbox
                                      name={`bankList.${index}.isDefault`}
                                      label='Set as default'
                                      margin='dense'
                                    />
                                  </Box>
                                </Box>
                              ))}
                          </>
                        )}
                      </FieldArray>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                        <SubmitButton
                          sx={{ minWidth: { xs: '100%', md: '350px' } }}
                          label={'Save'}
                        />
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </>
          )}
          {component === COMPONENT.SHOW_ALL && (
            <Box>
              {allAccounts.isSuccess && (
                <ShowAllBanks
                  totalAccounts={[...totalAccounts, ...enteredAccounts]}
                  handleDeleteAccounts={handleDeleteAccounts}
                />
              )}

              {!isAddedData ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 5,
                    columnGap: '20px',
                  }}
                >
                  <StepperAddButton
                    sx={{ flex: 1, padding: '9px 20px' }}
                    startIcon={<AddIcon />}
                    disabled={enteredAccounts.length + totalAccounts.length >= 5}
                    onClick={() => {
                      setIsAddedData(true);
                    }}
                  >
                    Add Another Bank
                  </StepperAddButton>

                  <StepperNextButton
                    onClick={async () => {
                      await saveAllBankDetails();
                      navigationHandler();
                    }}
                    disabled={
                      fromdistrubutorProfile &&
                      !(
                        allAccounts?.data?.bankAccounts?.length !==
                          totalAccounts?.length || enteredAccounts.length !== 0
                      )
                    }
                    sx={{ flex: 1, padding: '9px 20px' }}
                  >
                    Save & Proceed
                  </StepperNextButton>
                </Box>
              ) : (
                <Box>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values, formikHelpers) => {
                      // const submitFunction = isFromKyd ? handleSubmit2 : handleSubmit;

                      // submitFunction(values, formikHelpers, 'SHOW_ALL', files);
                      if (!areKydUpdated && isFromKyd) {
                        handleKydDetails(values, formikHelpers);
                      } else {
                        handleSubmit(values, formikHelpers, 'SHOW_ALL', files);
                      }
                    }}
                    validationSchema={
                      isFromKyd && !areKydUpdated ? null : validationSchema
                    }
                    enableReinitialize
                    // validate={async values => handleValidation(values)}
                  >
                    {({ values, setFieldValue, setFieldError }) => (
                      <Form>
                        <FieldArray name='bankList'>
                          {({ push, remove }) => (
                            <>
                              {values.bankList.length > 0 &&
                                values.bankList.map((bank: any, index: number) => (
                                  <Box key={index}>
                                    <Box>
                                      <BankCountBox
                                        handleDeleteAccounts={() => {
                                          setIsAddedData(false);
                                        }}
                                        index={
                                          ([...totalAccounts, ...enteredAccounts]
                                            .length || 0) +
                                          index +
                                          1
                                        }
                                        isEdit={false}
                                        name={'Bank'}
                                        showIcon={true}
                                      />
                                      <AddBankAccount
                                        index={index}
                                        setFieldError={setFieldError}
                                        setCurrentBankAccountToken={
                                          setCurrentBankAccountToken
                                        }
                                        setHasValidationFailed={setHasValidationFailed}
                                      />
                                      {hasAccountValidationFailed && (
                                        <UploadChequeCopy
                                          index={index}
                                          files={files}
                                          setFiles={setFiles}
                                          hasAccountValidationFailed={
                                            hasAccountValidationFailed
                                          }
                                        />
                                      )}
                                      <Grid
                                        item
                                        sm={6}
                                      >
                                        <FormCheckbox
                                          name='isDefault'
                                          label='Set as default'
                                          margin='dense'
                                        />
                                      </Grid>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        mt: 5,
                                      }}
                                    >
                                      <SubmitButton
                                        label={'Save'}
                                        sx={{ minWidth: '350px' }}
                                        disabled={!currentBankAccountToken}
                                      />
                                    </Box>
                                  </Box>
                                ))}
                            </>
                          )}
                        </FieldArray>
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}
            </Box>
          )}

          <PopupDialogBox
            open={showPopUp}
            onClose={() => {
              setComponent(COMPONENT.MAIN_PAGE);
              setShowPopUp(false);
              if (enteredAccounts.length > 0) {
                setEnteredAccounts([]);
              }
            }}
            title={'Changes'}
            description={'You have unsaved changes remaining do you want to go back?'}
          />
        </Box>
      </Box>
    </>
  );
};
